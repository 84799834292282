import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';

import './index.css';

const Select = React.lazy(() => import('react-select'));

const SelectWrapper = ({ disabled, options, handleOnchange, value, className, placeholder }) => (
  <div role="button" onKeyDown={() => {}} tabIndex={-1} onClick={(e) => e.stopPropagation()}>
    <Suspense fallback={<Loading centered active />}>
      <Select
        className={`select-wrapper ${className}`}
        classNamePrefix="select"
        options={options}
        value={value}
        placeholder={placeholder}
        isDisabled={disabled}
        onChange={(e) => handleOnchange(e)}
      />
    </Suspense>
  </div>
);

SelectWrapper.propTypes = {
  options: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  handleOnchange: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
};

SelectWrapper.defaultProps = {
  className: '',
  placeholder: '',
  disabled: false,
};

export default SelectWrapper;
