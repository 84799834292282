import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, Button, Menu, MenuItem } from 'react-aria-menubutton';
import Icon from 'components/Icon';
import classNames from 'classnames';

import './menubutton.css';

const MenuButton = (props) => {
  const { items, children } = props;

  const menuItems = items.map((item, idx) => {
    const { label, color, icon, size = 's', onClick } = item;
    const _className = classNames(
      size,
      {
        [`color-${color}`]: color,
      },
      !icon && 'no-icon'
    );
    return (
      <MenuItem
        className={`mb-item ${idx !== items.length - 1 ? 'separator' : ''}`}
        key={label}
        onClick={onClick}
      >
        {icon && <Icon name={icon.name} color={color} />}
        <span className={_className}>{label}</span>
      </MenuItem>
    );
  });

  return (
    <Wrapper className="mb-wrapper">
      <Button className="mb-button">{children}</Button>
      <Menu className="mb-menu">{menuItems}</Menu>
    </Wrapper>
  );
};

MenuButton.propTypes = {
  items: PropTypes.array,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
};

MenuButton.defaultProps = {
  items: [],
};

export default MenuButton;
