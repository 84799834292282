import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import FullWidth from 'layouts/FullWidth';
import { CalendarList } from 'components/Calendar';
import Button from 'components/Button';
import CmeInformation from 'components/CmeInformation';
import Segment from 'components/Segment';
import Footer from 'components/Footer';
import CTA from 'components/Snippets/CTA';
import { Analytics } from 'facades/Analytics';

import { fetchPublications as fetchPublicationsAction } from 'actions/publications';

import './calendar.css';

const Calendar = ({ fetchPublications, publications }) => {
  const sendGA4Event = () => {
    Analytics.sendEvent('search_page', {
      previous_page: '/calendar',
    });
  };

  useEffect(() => {
    const currentDate = moment();
    fetchPublications(1, 100, null, {
      'filter[visibility]': 'announced',
      sort: 'publicationDate',
      'filter[publicationDate__gte]': currentDate.format('YYYY-MM-DD'),
      'filter[publicationDate__lte]': currentDate.add(1, 'year').format('YYYY-MM-DD'),
    });
  }, [fetchPublications]);

  return (
    <FullWidth id="calendar" topbar>
      <FullWidth.Content className="bg-color-white">
        <Segment group>
          <Grid className="container-max-width calendar-list" fluid>
            <Row center="md">
              <CTA
                title="Get exclusive psychopharm updates. Earn CME and SA credits"
                subtitle="Become a member today."
                buttonTitle="Get Started"
                to="/cme-program?section=pricing"
              />
              <Col xs={12} md={12}>
                {!publications.isFetching && publications.data.length === 0 ? (
                  <>
                    <h1>No publications found</h1>
                    <Button onClick={sendGA4Event} to="/search" fill>
                      Go to Library
                    </Button>
                  </>
                ) : (
                  <>
                    <h1>
                      Upcoming updates <br />
                      for {moment().year()}-{moment().add(1, 'year').year()}
                    </h1>
                    <CalendarList publications={publications} />
                    {publications.data && publications.data.length > 0 && !publications.isFetching && (
                      <div className="button-wrapper">
                        <Button
                          to="/cme-program?section=pricing"
                          fill
                          color="violet"
                          className="center"
                        >
                          Get Instant Access Now
                        </Button>
                      </div>
                    )}
                  </>
                )}
              </Col>
            </Row>
          </Grid>
          <CmeInformation />
        </Segment>
        <Footer />
      </FullWidth.Content>
    </FullWidth>
  );
};

Calendar.propTypes = {
  fetchPublications: PropTypes.func.isRequired,
  publications: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      year: PropTypes.string,
    }),
  }).isRequired,
};

export default connect(
  (store) => ({
    publications: store.publications,
  }),
  (dispatch) => ({
    fetchPublications: bindActionCreators(fetchPublicationsAction, dispatch),
  })
)(Calendar);
