import _ from 'lodash';

import { PUBLICATIONS_FETCHING, PUBLICATIONS_FETCHED } from 'reducers/publications';

export const SECTION_FETCHED = 'SECTION_FETCHED';
export const SECTION_FETCHING = 'SECTION_FETCHING';
export const SECTIONS_FETCHED = 'SECTIONS_FETCHED';
export const SECTIONS_FETCHING = 'SECTIONS_FETCHING';

const sectionsDefault = {
  isFetching: false,
  data: [],
};

export const sections = (sectionsInfo = sectionsDefault, action) => {
  switch (action.type) {
    case PUBLICATIONS_FETCHED:
      return {
        data: _.reduce(
          action.publications,
          (red, publication) => {
            const response = [...red, ...(publication.sections ? publication.sections : [])];
            return response;
          },
          []
        ),
        isFetching: false,
      };

    case SECTIONS_FETCHED:
      return {
        data: action.sections,
        isFetching: false,
      };
    case SECTIONS_FETCHING:
    case PUBLICATIONS_FETCHING:
      return {
        data: [],
        isFetching: true,
      };
    default:
      return sectionsInfo;
  }
};

const sectionDefault = {
  isFetching: false,
  data: [],
};

export const section = (sectionInfo = sectionDefault, action) => {
  switch (action.type) {
    case SECTION_FETCHED:
      return {
        data: action.section,
        isFetching: false,
      };
    case SECTION_FETCHING:
      return {
        data: {},
        isFetching: true,
      };
    default:
      return sectionInfo;
  }
};
