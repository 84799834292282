import { combineReducers } from 'redux';
import { combineForms } from 'react-redux-form';

import { auth } from './auth';
import { author, authors } from './authors';
import { homeBlocks } from './homeblocks';

import { certificates } from './certificates';
import { config } from './config';
import { featuredCategories, categories, category } from './categories';
import { giftCards } from './giftcards';
import { i18n } from './i18n';
import { interviewers } from './interviewers';
import { memberships } from './memberships';
import { modals } from './modals';
import { newsletter } from './newsletter';
import { publication, publications } from './publications';
import { sections, section } from './sections';
import { receipts } from './receipts';
import { referrals } from './referrals';
import { sidebar } from './sidebar';
import { testResults } from './testResults';
import { testResultsLocal } from './testResultsLocal';
import { topbar } from './topbar';

const formSignin = {
  username: '',
  password: '',
  error: false,
};

const formRegister = {
  username: '',
  password: '',
  firstName: '',
  lastName: '',
  repeatPassword: '',
  error: false,
};

const formNewsletter = {
  email: '',
  firstName: '',
  lastName: '',
  error: false,
};

const formRegisterCheckout = {
  ...formRegister,
  userId: null,
  membershipId: null,
  discountId: null,
  upgrade: false,
  isAccountCreated: false,
  error: false,
};

const formLibraryFilters = {
  types: [],
  categories: [],
  testType: '',
  search: '',
  error: false,
};

export default combineReducers({
  forms: combineForms({
    signin: formSignin,
    register: formRegister,
    registerCheckout: formRegisterCheckout,
    libraryFilters: formLibraryFilters,
    newsletter: formNewsletter,
  }),
  author,
  authors,
  auth,
  featuredCategories,
  categories,
  category,
  certificates,
  config,
  interviewers,
  memberships,
  modals,
  publication,
  publications,
  testResults,
  testResultsLocal,
  sections,
  section,
  sidebar,
  topbar,
  referrals,
  giftCards,
  receipts,
  newsletter,
  i18n,
  homeBlocks,
});
