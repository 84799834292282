import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FullWidth from 'layouts/FullWidth';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { isMobileOnly } from 'react-device-detect';

import Avatar from 'components/Avatar';
import Card from 'components/Card';
import Loading from 'components/Loading';
import Segment from 'components/Segment';
import Footer from 'components/Footer';
import { fetchAuthor } from 'actions/authors';
import { fetchPublications } from 'actions/publications';

import _ from 'lodash';

import './author.css';

class Author extends Component {
  componentDidMount() {
    const authorId = _.chain(this.props).get('match.params.slug', '').split('-').last().value();

    if (authorId === '') {
      this.props.history.push('/');
    }
    this.props.fetchAuthor(authorId);
    this.props.fetchPublications(1, 6, null, { 'filter[authorId__in]': authorId });
    return null;
  }

  render() {
    const cards =
      !this.props.publications.isFetching &&
      _.map(this.props.publications.data, (publication) => (
        <Card
          size={isMobileOnly ? 'small' : 'default'}
          key={`publication_${publication.id}`}
          publication={publication}
          author={publication.author}
          to={`/publication/${publication.slug}`}
        />
      ));
    return (
      <FullWidth id="author" topbar loading={this.props.author.isFetching}>
        <FullWidth.Content className="bg-color-white bg-lighten1">
          <Grid className="container-max-width" fluid>
            <Row>
              <Col xs md={8} mdOffset={2} id="header">
                <Segment>
                  <div className="title">Our Faculty</div>
                  <div className="avatar">
                    <Avatar
                      size="b"
                      showName={false}
                      labelJobTitle={false}
                      labelInstitute={false}
                      author={this.props.author.data}
                    />
                  </div>
                  <h1>{this.props.author.data.fullNameAffix}</h1>
                  <div
                    className="complete-affiliation"
                    dangerouslySetInnerHTML={{ __html: this.props.author.data.completeAffiliation }}
                  />
                </Segment>
              </Col>
            </Row>
            <Row>
              <Col xs md={8} mdOffset={2}>
                <Segment group>
                  <h2>Bio</h2>
                  <div dangerouslySetInnerHTML={{ __html: this.props.author.data.bio }} />
                </Segment>
                <Segment group>
                  <h2>Conflict of Interest</h2>
                  <div
                    dangerouslySetInnerHTML={{ __html: this.props.author.data.conflictsOfInterest }}
                  />
                </Segment>
              </Col>
            </Row>
          </Grid>
        </FullWidth.Content>
        <FullWidth.Content className="bg-color-white">
          <Grid className="container-max-width" fluid>
            <Row>
              <Col xs={12} md={8} mdOffset={2}>
                <Segment>
                  <h2>Publications</h2>
                  <Loading active={this.props.publications.isFetching} />
                  {isMobileOnly ? <Card.Group>{cards}</Card.Group> : cards}
                </Segment>
              </Col>
            </Row>
          </Grid>
          <Footer />
        </FullWidth.Content>
      </FullWidth>
    );
  }
}

Author.propTypes = {
  fetchAuthor: PropTypes.func.isRequired,
  fetchPublications: PropTypes.func.isRequired,
  author: PropTypes.shape({
    data: PropTypes.shape({
      fullNameAffix: PropTypes.string,
      completeAffiliation: PropTypes.string,
      bio: PropTypes.string,
      conflictsOfInterest: PropTypes.string,
    }),
    isFetching: PropTypes.bool,
  }).isRequired,
  publications: PropTypes.shape({
    data: PropTypes.array,
    isFetching: PropTypes.bool,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default connect(
  (store) => ({
    author: store.author,
    publications: store.publications,
  }),
  (dispatch) => ({
    fetchAuthor: bindActionCreators(fetchAuthor, dispatch),
    fetchPublications: bindActionCreators(fetchPublications, dispatch),
  })
)(Author);
