export const NEWSLETTER_FETCHING = 'NEWSLETTER_FETCHING';
export const NEWSLETTER_REGISTERED = 'NEWSLETTER_REGISTERED';
export const NEWSLETTER_ERROR = 'NEWSLETTER_ERROR';

const newsletterInit = {
  isSaving: false,
  isRegistered: null,
  error: null,
};

export const newsletter = (state = newsletterInit, action) => {
  switch (action.type) {
    case NEWSLETTER_FETCHING:
      return {
        isRegistered: false,
        isSaving: true,
      };

    case NEWSLETTER_REGISTERED:
      return {
        isSaving: false,
        isRegistered: true,
        error: false,
      };

    case NEWSLETTER_ERROR:
      return {
        isSaving: false,
        isRegistered: false,
        error: true,
      };

    default:
      return state;
  }
};
