import _ from 'lodash';
import qs from 'qs';
import { useHistory } from 'react-router-dom';

export const cleanErrorPathname = (pathname) => _.replace(_.replace(pathname, '/', ''), '=', '');

export const getAbsoluteRedirect = (search) => {
  if (!search || search.indexOf('redirect=') < 0) return '';
  return _.replace(search.substr(search.indexOf('redirect=') - 1, search.length), '?redirect=', '');
};

export const removeTryParam = (path) => {
  if (!path) return '';

  const index = path.indexOf('&try');
  return index >= 0 ? path.substr(0, index) : path;
};

export const isValidPageParam = (page) => {
  if (page === undefined) return true;
  return (
    !_.isNaN(parseFloat(page)) && parseFloat(page).toString().length === page.toString().length
  );
};

export const getUrlParams = () => {
  const history = useHistory();
  return qs.parse(_.get(history, 'location.search', '').replace('?', ''));
};

export const getPathname = () => {
  const history = useHistory();
  return _.get(history, 'location.pathname', '');
};
