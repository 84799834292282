import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import { isIOS } from 'react-device-detect';

import Link from 'components/Link';
import SearchIcon from 'resources/img/search.svg';

import { getFormatedResultTitle } from 'helpers/search';

import './index.css';

const AutocompleteResultsMobile = ({ autocompletedResults }) => {
  return (
    <div className={`sui-search-box__autocomplete-container ${isIOS && 'isIOS'}`}>
      {map(autocompletedResults, (result, index) => {
        const formatedTitle = getFormatedResultTitle(result);
        const url = get(result, 'url.raw', '/');
        return (
          <div className="sui-search-box__autocomplete-result" key={index}>
            <img src={SearchIcon} className="result-img" alt="search-icon" />
            <Link to={url} className="sui-search-box__autocomplete-result-item">
              <div dangerouslySetInnerHTML={{ __html: formatedTitle }} />
            </Link>
          </div>
        );
      })}
    </div>
  );
};

AutocompleteResultsMobile.propTypes = {
  autocompletedResults: PropTypes.array.isRequired,
};

export default AutocompleteResultsMobile;
