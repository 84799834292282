import React from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import store from 'store';
import { SPECIAL_MEMBERSHIP_ID } from '../config';

import Button from '../components/Button';

const filterExtendedMembership = (memberships) => {
  return memberships.filter((i) => i.id !== SPECIAL_MEMBERSHIP_ID);
};

export const membershipIsValid = () => {
  const { auth } = store.getState();

  if (!auth) return false;

  return (
    auth.isLogged &&
    _.get(auth, 'user.hasMembership', false) &&
    _.get(auth, 'user.isMembershipValid', false)
  );
};

export const membershipHasPermission = (membership, permission) => {
  return _.includes(membership.permissions, permission);
};

export const userHasPermission = (permission) => {
  const { auth } = store.getState();
  return (
    auth.isLogged &&
    auth.user.hasMembership &&
    membershipHasPermission(auth.user.membership, permission)
  );
};

export const userCanViewPublicationType = (publicationType) => {
  const { auth } = store.getState();

  return (
    userHasPermission(publicationType) &&
    auth.user.isMembershipValid &&
    _.includes(auth.user.can.view.publicationsByType, publicationType)
  );
};

export const userCanViewSection = (publicationType, isPrivate) => {
  const { auth } = store.getState();
  return (
    !isPrivate ||
    (isPrivate &&
      userHasPermission(publicationType) &&
      _.includes(auth.user.can.view.publicationsByType, publicationType))
  );
};

export const membershipsToPublicationType = (publicationType) => {
  const { memberships } = store.getState();

  return _.filter(memberships.data, (item) => {
    return _.includes(item.permissions, publicationType);
  });
};

export const membershipsToPublicationTypeString = (publicationType, connector = 'or') => {
  return _.chain(membershipsToPublicationType(publicationType))
    .map((item) => item.name)
    .join(', ')
    .value()
    .replace(/, ([^,]*)$/, ` ${connector} $1`);
};

export const membershipsToPublicationTypeStringInCalendar = (publicationType) => {
  if (!publicationType) return '';

  const membership = membershipsToPublicationType(publicationType);
  const filterMembership = filterExtendedMembership(membership);
  const membershipLabel =
    filterMembership && filterMembership.length > 1 ? 'Memberships' : 'Membership';

  return `Included in ${_.chain(filterMembership)
    .map((item) => item.name)
    .join(', ')
    .value()
    .replace(/, ([^,]*)$/, ` and $1`)} ${membershipLabel}`;
};

export const getInfoMembership = ({ auth, memberships, history, isButton = false }) => {
  let selectedMembership = {
    label: 'UPGRADE',
    color: 'green',
    to: '/checkout',
  };

  if (auth.isLogged && auth.user.hasMembership) {
    if (auth.user.isMembershipValid) {
      const currentMembershipIndex = _.findIndex(memberships.data, { id: auth.user.membership.id });
      const nextMembership = _.get(memberships, `data[${currentMembershipIndex + 1}]`, false);

      if (nextMembership) {
        selectedMembership = {
          label: 'UPGRADE',
          color: 'blue',
          to: `/checkout?membership=${nextMembership.id}`,
        };
      } else {
        return null;
      }
    } else {
      selectedMembership = {
        label: 'RENEW MEMBERSHIP',
        color: 'red',
        to: `/checkout?membership=${auth.user.membership.id}`,
      };
    }
  }

  const button = isButton ? (
    <Button onClick={() => history.push(selectedMembership.to)} fill color="violet">
      {selectedMembership.label}
    </Button>
  ) : (
    <span
      role="button"
      tabIndex={-1}
      onKeyDown={() => {}}
      onClick={() => history.push(selectedMembership.to)}
      className={classNames('label-membership', {
        [`bg-color-${selectedMembership.color}`]: selectedMembership.color,
      })}
    >
      {selectedMembership.label}
    </span>
  );

  return button;
};

export const isFreeMembership = () => {
  const { auth } = store.getState();
  if (!auth) return false;

  return auth.isLogged && !_.get(auth, 'user.isMembershipValid', false);
};

export const isResident = () => {
  const { auth } = store.getState();
  const isMembershipValid = membershipIsValid();
  if (!auth) return false;

  return auth.isLogged && isMembershipValid && !_.get(auth, 'user.membership.canGiveCredits');
};
