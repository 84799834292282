import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import classNames from 'classnames';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Segment from 'components/Segment';
import FullWidth from 'layouts/FullWidth';

import './simple_page.css';

const SimplePage = (props) => {
  window.scrollTo(0, 0);

  return (
    <FullWidth
      id={props.id || 'simple_page'}
      className={classNames('simple_page', props.className)}
    >
      <FullWidth.Content className="bg-color-white bg-lighten1">
        <Grid className={props.gridClassname}>
          <Row center="xs">
            <Col xs={12} md={6}>
              <Segment group>
                <Button navItem link to="/" id="logo">
                  <Icon name="logo" color="aqua" />
                  <Icon name="logo_text" />
                </Button>
                <h1>{props.title}</h1>
              </Segment>
              {props.children}
              <Segment group>
                <h6 className="xs color-white darken2">Need more information</h6>
                <Button link main color="violet" to="/contact-us">
                  Contact Us
                </Button>
              </Segment>
            </Col>
          </Row>
        </Grid>
      </FullWidth.Content>
    </FullWidth>
  );
};

SimplePage.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  gridClassname: PropTypes.string,
};

SimplePage.defaultProps = {
  id: 'simple_page',
  className: '',
  title: '',
  children: null,
  gridClassname: '',
};

SimplePage.defaultProps = {};

export default SimplePage;
