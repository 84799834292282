import findIndex from 'lodash/findIndex';

export const errorMessages = {
  validPasswordLength: 'Password must contain at least 8 characters',
  validRepeatPassword: 'Passwords do not match',
  validEmail: 'Use a valid email address (name@site.com)',
};

export const validPasswordLength = (password) => {
  if (!password) {
    return false;
  }

  return password.length >= 8;
};

export const validRepeatPassword = (password, repeatPassword) => {
  return password === repeatPassword && password.length > 0 && repeatPassword.length > 0;
};

export const validEmail = (email) => {
  if (!email) {
    return false;
  }

  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const validMembershipId = (membershipId = null, memberships = []) => {
  if (!membershipId || memberships.length === 0) return false;

  return findIndex(memberships, (membership) => `${membership.id}` === `${membershipId}`) >= 0;
};
