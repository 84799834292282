import React from 'react';
import * as Sentry from '@sentry/browser';

export const AUTH_USER_FETCHING = 'AUTH_USER_FETCHING';
export const AUTH_USER_FETCHED = 'AUTH_USER_FETCHED';
export const AUTH_LOGGED = 'AUTH_LOGGED';
export const AUTH_LOGIN = 'AUTH_LOGIN';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_UPDATING = 'AUTH_UPDATING';
export const AUTH_REGISTERING = 'AUTH_REGISTERING';
export const AUTH_REGISTERED = 'AUTH_REGISTERED';

const authInit = {
  token: null,
  user: {},
  auth: {},
  isFetching: null,
  isLogged: null,
  isUpdating: false,
};

export const AuthContext = React.createContext(authInit);

export const auth = (authInfo = authInit, action) => {
  switch (action.type) {
    case AUTH_USER_FETCHING:
      return {
        ...authInfo,
        isFetching: true,
      };
    case AUTH_UPDATING:
      return {
        ...authInfo,
        isUpdating: true,
      };
    case AUTH_USER_FETCHED:
      return {
        ...authInfo,
        isFetching: false,
        isUpdating: false,
      };
    case AUTH_LOGGED:
      Sentry.configureScope((scope) => {
        scope.setUser({
          email: action.user.email,
          userId: action.user.id,
          membershipId: action.user.hasMembership ? action.user.membership.id : null,
          membership: action.user.hasMembership ? action.user.membership.name : null,
        });
      });
      return {
        ...authInfo,
        user: action.user,
        token: action.token,
        isFetching: false,
        isLogged: true,
        isUpdating: false,
      };
    case AUTH_LOGIN:
      return {
        ...authInfo,
        token: null,
        user: {},
        isFetching: true,
        isLogged: null,
      };
    case AUTH_LOGOUT:
      return {
        ...authInit,
        isFetching: null,
        isLogged: false,
      };
    default:
      return authInfo;
  }
};
