import _ from 'lodash';

export const loadDinamicScripts = (scripts, seconds = null) => {
  if (!_.isArray(scripts)) {
    scripts = new Array(scripts);
  }
  setTimeout(
    () => {
      _.forEach(scripts, function (value) {
        const { id = value.src, src = null, callback = null } = value;

        if (src) {
          const existingScript = document.getElementById(id);

          if (!existingScript) {
            const script = document.createElement('script');
            script.async = true;
            script.src = src;
            script.id = id;
            document.head.appendChild(script);

            script.onload = () => {
              if (callback) callback();
            };
          }

          if (existingScript && callback) callback();
        }
      });
    },
    _.isNumber(seconds) ? seconds * 1000 : 0
  );
};

export const loadCustomScript = ({ id, content }) => {
  if (!content) return;

  const script = document.createElement('script');
  script.async = true;
  script.id = id || 'newScript';
  script.type = 'text/javascript';
  script.text = content;
  document.head.appendChild(script);
};
