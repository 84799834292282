import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import classNames from 'classnames';
import map from 'lodash/map';
import get from 'lodash/get';
import filter from 'lodash/filter';

import Footer from 'components/Footer';
import FullWidth from 'layouts/FullWidth';
import Icon from 'components/Icon';
import Segment from 'components/Segment';
import MembershipCard from 'components/MembershipCard';
import Button from 'components/Button';

import { fetchMemberships } from 'actions/memberships';

import { SPECIAL_MEMBERSHIP_ID } from 'config';

import membershipsInfo from './membershipsInfo';

import './pricing.css';

export const Testimonials = () => (
  <Segment size="s" className="white bg-lighten-1 testimonials">
    <Grid className="container-max-width" fluid>
      <Row className="first-row">
        <Col xs={12}>
          <h3 className="balance-text">What clinicians are saying</h3>
        </Col>
      </Row>
      <Row center="xs" className="second-row">
        <Col xs={12} md={5} mdOffset={1}>
          <span className="big-quote color-violet lighten1">“</span>
          <p className="color-black lighten1">
            it is nice to have updated information regarding pharmacologic treatments available in
            one place. the tutorials are concise, yet full of necessary information.
          </p>
          <h6 className="color-black lighten1">Claudia Cuervo, M.D.</h6>
        </Col>
        <Col xs={12} md={5}>
          <span className="big-quote color-aqua lighten1">“</span>
          <p className="color-black lighten1">
            Your algorithms are a great resource for teaching and consulting
          </p>
          <h6 className="color-black lighten1">Matthew Goodwin, M.D.</h6>
        </Col>
      </Row>
    </Grid>
  </Segment>
);

const _PricingHero = ({ memberships: membershipsData, title }) => {
  const colors = ['green', 'blue', 'violet'];
  const memberships = filter(
    get(membershipsData, 'data', []),
    (membership) => membership.id !== SPECIAL_MEMBERSHIP_ID
  );
  const colWidth = memberships.length < 3 ? 5 : 4;

  const finalMemberships = membershipsInfo.map((e, i) => ({
    ...e,
    id: get(memberships, [i, 'id']),
    rebillDaysLabel: get(memberships, [i, 'rebillDaysLabel']),
    price: get(memberships, [i, 'prices', [0], 'price'], 0),
  }));
  const [rebillDaysSelected, setRebillDaysSelected] = useState(365);

  return (
    <div className="pricing-hero" id="pricing-section">
      <Row className="pricing-header">
        <Col className="color-black darken1">
          <h2>{title || 'Get Instant Access Now'}</h2>
          <p>Pick a membership, cancel any time.</p>
        </Col>
        <Col>
          <div id="membership-switch">
            <Button
              basic={rebillDaysSelected !== 365}
              fill
              color="violet"
              onClick={() => setRebillDaysSelected(365)}
            >
              Yearly Subscription
            </Button>
            <Button
              basic={rebillDaysSelected === 365}
              fill
              color="violet"
              onClick={() => setRebillDaysSelected(1825)}
            >
              5 Year Subscription - Save 20%
            </Button>
          </div>
        </Col>
      </Row>
      <div className="memberships">
        <Row center="xs">
          {map(finalMemberships, (membership, i) => {
            const { id, rebillDays } = membership;
            if (rebillDaysSelected !== rebillDays) return null;

            return (
              <Col
                className={classNames('card-wrapper')}
                key={`membership_${id}_${i}`}
                xs={12}
                sm={5}
                md={colWidth}
              >
                <MembershipCard membership={membership} color={colors[i]} />
              </Col>
            );
          })}
        </Row>
      </div>
      <div className="pricing-guarantee">
        <Icon name="certification" color="violet" />
        <div>
          <p>
            <b>30 Day Satisfaction Guarantee</b>
          </p>
          <p>
            If you are not completely satisfied with your membership, you can contact us within 30
            days and we will issue a full refund. No questions asked.
          </p>
        </div>
      </div>
    </div>
  );
};

_PricingHero.propTypes = {
  memberships: PropTypes.shape({
    data: PropTypes.array,
    length: PropTypes.func,
  }).isRequired,
  title: PropTypes.string,
};
_PricingHero.defaultProps = {
  title: 'Get Instant Access Now',
};

export const PricingHero = connect((store) => ({
  memberships: store.memberships,
}))(_PricingHero);

class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.fetchMemberships();
  }

  render() {
    return (
      <FullWidth className="pricing" topbar loading={this.props.memberships.isFetching}>
        <FullWidth.Content className="gradient gradient-darken bg-color-green">
          <Grid className="container-max-width hero" fluid>
            <Row>
              <Col xs={12}>
                <PricingHero />
              </Col>
            </Row>
          </Grid>
          <Testimonials />
          <Footer />
        </FullWidth.Content>
      </FullWidth>
    );
  }
}

Pricing.propTypes = {
  fetchMemberships: PropTypes.func.isRequired,
  memberships: PropTypes.shape({
    isFetching: PropTypes.bool,
  }).isRequired,
};

export default connect(
  (store) => ({
    memberships: store.memberships,
  }),
  (dispatch) => ({
    fetchMemberships: bindActionCreators(fetchMemberships, dispatch),
  })
)(Pricing);
