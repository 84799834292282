import React from 'react';
import Skeleton from 'components/Skeleton/SkeletonElement';
import './index.css';

const SkeletonTopBar = () => {
  return (
    <div className="skeleton-topbar">
      <Skeleton height={50} width={415} />
      <div className="divider" />
      <div className="user-info-skeletons">
        <Skeleton height={20} width={116} />
        <Skeleton height={20} width={136} />
      </div>
      <Skeleton className="avatar-skeleton" height={56} width={56} />
    </div>
  );
};

export default SkeletonTopBar;
