import React from 'react';
import { PropTypes } from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Input from 'components/Input';
import Icon from 'components/Icon';
import Button from 'components/Button';
import Link from 'components/Link';
import Loading from 'components/Loading';
import { validEmail } from 'helpers/forms';
import { referUser } from 'actions/referrals';
import { FRONT_ROOT } from '../config';

import { ReferralProgramDescription } from '../scenes/MyAccount/Refer';

class ReferModalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      success: false,
      errorMessage: null,
      loading: false,
      disabled: false,
      loadingReferral: false,
    };
  }

  handleOnChange = (e) => {
    e.preventDefault();

    const { disabled, errorMessage } = this.state;

    const newState = {
      email: e.target.value,
      disabled,
      errorMessage,
    };

    if (this.state.disabled) {
      newState.disabled = false;
    }

    if (this.state.errorMessage) {
      newState.errorMessage = '';
    }

    this.setState(newState);
  };

  onSubmit = (e) => {
    e.preventDefault();
    const { email } = this.state;

    if (!validEmail(email)) {
      return this.setState({ disabled: true });
    }

    this.setState({ loadingReferral: true });

    this.props
      .referUser(email, this.props.updateReferralsList)
      .then(() => {
        // console.log('SAVE INVITE: ', this.props.type);
        this.setState({ success: true, errorMessage: '', loadingReferral: false });
      })
      .catch((error) => {
        this.setState({
          errorMessage: error.response.data.errors.message,
          disabled: true,
          loadingReferral: false,
        });
      });
    return null;
  };

  render() {
    const { email, loading, success, disabled, loadingReferral, errorMessage } = this.state;
    let content = (
      <div className="refer-form-wrapper">
        <div className="refer-form">
          <h4>Receive $50 for every new colleague you refer</h4>
          <ReferralProgramDescription />
          <form>
            <label className="refer-label">Enter your colleague&#39;s e-mail address</label>
            <Input
              disabled={loading}
              className="refer-email"
              type="text"
              onChange={this.handleOnChange}
              value={email}
              placeholder="Type your colleague's e-mail address"
              name="firstName"
            />
            <div className="input-legend error">
              {disabled && !errorMessage ? (
                'Enter a valid email address to invite'
              ) : loadingReferral ? (
                <Loading active={loadingReferral} />
              ) : (
                errorMessage
              )}
            </div>
            <div className="buttons">
              <Button
                onClick={this.onSubmit}
                type="submit"
                fill
                color="violet"
                className="button default loading level-2 pointer"
                disabled={disabled}
              >
                Invite
              </Button>
              <Link
                color="violet"
                className="no-thank-you"
                onClick={() => {
                  // console.log('NO, THANK YOU: ', this.props.type);
                  this.props.onClose();
                }}
              >
                No, thank you
              </Link>
            </div>
          </form>
          {loading && <Loading centered active />}
        </div>
        <div className="rectangle-2">
          <div className="check-invite-and-win">
            Read the
            <div onClick={() => console.log('READ TERMS AND CONDITIONS: ', this.props.type)}>
              <Button href={`${FRONT_ROOT}/referral-program-terms-and-conditions`} target="_blank">
                Terms and Conditions
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
    if (success) {
      content = (
        <div className="refer-success success-container">
          <Icon
            border
            size="30"
            color="white"
            name="check"
            style={{
              borderColor: '#01BA92',
              backgroundColor: '#01BA92',
              marginBottom: '0.5em',
            }}
          />
          <h4>Invitation sent!</h4>
          <p>
            <Button onClick={() => this.props.onClose()} to="/myaccount/refer" color="violet">
              Invite more colleagues
            </Button>
          </p>
          <p>There is no limit to the number of colleagues that you can refer.</p>
          <Button
            className="button default violet link main level-2"
            onClick={() => this.props.onClose()}
          >
            Continue in the website
          </Button>
        </div>
      );
    }
    return content;
  }
}

ReferModalForm.propTypes = {
  typeData: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }),
  onClose: PropTypes.func.isRequired,
  referUser: PropTypes.func.isRequired,
  updateReferralsList: PropTypes.bool,
  type: PropTypes.string,
};

ReferModalForm.defaultProps = {
  updateReferralsList: false,
  typeData: null,
  type: '',
};

export default connect(
  (store) => ({
    auth: store.auth,
  }),
  (dispatch) => ({
    referUser: bindActionCreators(referUser, dispatch),
  })
)(ReferModalForm);
