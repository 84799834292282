import React from 'react';

import Button from 'components/Button';
import Link from 'components/Link';

import { signinUrl } from 'helpers/history';

import './free-section-block-modal.css';

const FreeSectionBlockModal = () => {
  return (
    <div className="free-section-block-modal">
      <div className="title"> There&apos;s more! Register now or sign in</div>
      <div className="text">
        Not registered? Create your free account now and get exclusive benefits. Or, sign in!{' '}
      </div>
      <div className="action-buttons">
        <Button fill color="violet" to="/register">
          Free Sign up!
        </Button>
        <Link to={signinUrl(true)} className="signin-free-section-modal">
          Sign in
        </Link>
      </div>
    </div>
  );
};

export default FreeSectionBlockModal;
