import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import classNames from 'classnames';
import { JsonLd } from 'react-schemaorg';
import Image from 'components/Image';

import Anchor from 'components/Anchor';
import Icon from 'components/Icon';

const Avatar = (props) => {
  const {
    author,
    authors,
    className,
    inverted,
    labelInstitute,
    labelJobTitle,
    legend,
    link,
    onClick,
    showName,
    size,
    type,
    id,
  } = props;

  const _onClick = (e) => {
    e.preventDefault();
    if (!link) {
      return false;
    }

    if (_.isFunction(onClick)) {
      onClick(e);
      return;
    }

    props.history.push(`/author/${author.slug}`);
  };

  if ((!author || _.isEmpty(author)) && (!authors || _.isEmpty(authors))) {
    return null;
  }

  let _className = classNames('avatar', size, className, type);

  if (!_.isEmpty(authors)) {
    const _authors = _.chain(authors).concat(author).compact().value();

    return (
      <div className={_className}>
        <div className="info">
          <div className="name">Authors:</div>
          <div className="data">
            <ul className="no-bullet">
              {_.map(_authors, (_author) => (
                <li>
                  <>
                    <JsonLd
                      item={{
                        '@context': 'https://schema.org',
                        '@type': 'Person',
                        name: _author.fullName,
                        email: _author.email,
                        ...(_author.jobTitle
                          ? {
                              jobTitle: {
                                '@type': 'DefinedTerm',
                                name: _author.jobTitle,
                              },
                            }
                          : {}),
                        description: _author.bio,
                        ...(_author.institute
                          ? {
                              memberOf: {
                                '@type': 'Organization',
                                identifier: _author.institute,
                              },
                            }
                          : {}),
                        ...(_author.image && _author.image.file
                          ? { image: _author.image.file }
                          : {}),
                        // eslint-disable-next-line no-nested-ternary
                        ...(_author.affixType === 'suffix'
                          ? { honorificSuffix: _author.affix }
                          : _author.affixType === 'preffix'
                          ? { honorificPrefix: _author.affix }
                          : {}),
                      }}
                    />
                    <Anchor url={`/author/${_author.slug}`} className="title color-black lighten2">
                      {_author.fullName}
                    </Anchor>
                  </>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  _className = classNames(_className, {
    inverted,
    linked: _.isFunction(onClick) || link,
  });

  const content = (
    <div className="info">
      <JsonLd
        item={{
          '@context': 'https://schema.org',
          '@type': 'Person',
          name: author.fullName,
          email: author.email,
          ...(author.jobTitle
            ? {
                jobTitle: {
                  '@type': 'DefinedTerm',
                  name: author.jobTitle,
                },
              }
            : {}),
          description: author.bio,
          ...(author.institute
            ? {
                memberOf: {
                  '@type': 'Organization',
                  identifier: author.institute,
                },
              }
            : {}),
          ...(author.image && author.image.file ? { image: author.image.file } : {}),
          ...(author.affixType === 'suffix'
            ? { honorificSuffix: author.affix }
            : author.affixType === 'preffix'
            ? { honorificPrefix: author.affix }
            : {}),
        }}
      />
      {legend && <div className="legend">{legend}</div>}
      {showName && <div className="name">{author.fullNameAffix}</div>}
      {labelJobTitle && <div className="data position">{author.jobTitle}</div>}
      {labelInstitute && <div className="data institute">{author.institute}</div>}
    </div>
  );

  return (
    <div className={_className} onClick={_onClick}>
      {inverted && content}
      {author.image ? (
        <Image
          id={id}
          className="avatar_image"
          src={author.image.file}
          alt={author.fullNameAffix}
          onError={(e) => {
            e.target.style.display = 'none';
          }}
        />
      ) : (
        <div id={id} className="avatar_image">
          <Icon circular name="account" />
        </div>
      )}
      {!inverted && content}
    </div>
  );
};

Avatar.propTypes = {
  author: PropTypes.object.isRequired,
  authors: PropTypes.array,
  className: PropTypes.string,
  inverted: PropTypes.bool,
  labelInstitute: PropTypes.bool,
  labelJobTitle: PropTypes.bool,
  legend: PropTypes.string,
  link: PropTypes.bool,
  onClick: PropTypes.func,
  showName: PropTypes.bool,
  size: PropTypes.string,
  type: PropTypes.string,
  history: PropTypes.object.isRequired,
  id: PropTypes.string,
};

Avatar.defaultProps = {
  authors: [],
  className: '',
  inverted: null,
  labelInstitute: true,
  labelJobTitle: true,
  legend: null,
  link: true,
  onClick: null,
  showName: true,
  size: 'medium',
  type: 'block',
  id: '',
};

export default withRouter(Avatar);
