import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import Button from 'components/Button';
import Input from 'components/Input';
import Segment from 'components/Segment';
import Loading from 'components/Loading';
import Icon from 'components/Icon';
import {
  fetchReferrals as fetchReferralsAction,
  referUser as referUserAction,
  resendReferUser as resendReferUserAction,
} from 'actions/referrals';
import { fetchGiftCards as fetchGiftCardsAction } from 'actions/giftcards';
import { validEmail } from 'helpers/forms';
import { FRONT_ROOT } from '../../../config';

import './refer.css';

const initialState = {
  value: '',
  myreferals: 0,
  sent: true,
  emails: [],
  invited: false,
  errorMessage: null,
  email: '',
  disabled: false,
  referrals: [],
  giftCards: [],
  showSent: {},
  resendError: {},
  loading: false,
  loadingResend: {},
  referralsPage: null,
  referralsCount: null,
  gitfCardsPage: null,
  giftCardsCount: null,
};

export const ReferralProgramDescription = () => (
  <p>
    For each new referral who subscribes to a paid membership, you get a <b>$50 gift card</b>. The
    colleague you refer will also benefit from an exclusive <b>$50 discount</b> on their membership.
    They&#39;ll thank you for this! *
  </p>
);

const Refer = ({
  auth,
  history,
  fetchReferrals,
  fetchGiftCards,
  referUser,
  resendReferUser,
  giftCards: {
    isFetching: isFetchingGiftCards,
    count: giftCardsCount,
    data: giftCards,
    page: giftCardsPage,
  },
  referrals: {
    isFetching: isFetchingReferrals,
    count: referralsCount,
    data: referrals,
    page: referralsPage,
  },
}) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (referrals.length === 0) {
      fetchReferrals();
    }
    if (giftCards.length === 0) {
      fetchGiftCards();
    }
  }, []);

  useEffect(() => {
    if (auth.isLogged && !auth.user.isMembershipValid) {
      history.push('/myaccount');
    }
  }, [auth]);

  const onSubmit = (e) => {
    e.preventDefault();

    const { email } = state;

    if (!validEmail(email)) {
      setState((prevState) => ({ ...prevState, disabled: true }));
      return null;
    }

    setState((prevState) => ({ ...prevState, loading: true }));

    referUser(email, true)
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          invited: true,
          errorMessage: '',
          loading: false,
        }));

        setTimeout(
          () => setState((prevState) => ({ ...prevState, invited: false, email: '' })),
          5000
        );
      })
      .catch((error) => {
        setState((prevState) => ({
          ...prevState,
          errorMessage: error.response.data.errors.message,
          disabled: true,
          loading: false,
        }));
      });
  };

  const handleOnChange = (e) => {
    const email = e.target.value;
    setState((prevState) => ({
      ...prevState,
      email,
      disabled: null,
      errorMessage: '',
    }));
  };

  const resend = (id, i) => {
    setState((prevState) => ({
      ...prevState,
      loadingResend: { ...prevState.loadingResend, [i]: true },
    }));
    resendReferUser(id)
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          showSent: { ...prevState.showSent, [i]: true },
          loadingResend: { ...prevState.loadingResend, [i]: false },
        }));
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            showSent: { ...prevState.showSent, [i]: false },
          }));
        }, 10000);
      })
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          showSent: { ...prevState.showSent, [i]: true },
          resendError: { ...prevState.resendError, [i]: true },
          loadingResend: { ...prevState.loadingResend, [i]: false },
        }));
        setTimeout(() => {
          setState((prevState) => ({
            showSent: { ...prevState.showSent, [i]: false },
            resendError: { ...prevState.resendError, [i]: false },
          }));
        }, 10000);
      });
  };

  const loadMore = (option) => {
    if (option === 'referrals') {
      fetchReferrals(20, referralsPage + 1);
    }
    if (option === 'giftCards') {
      fetchGiftCards(20, giftCardsPage + 1);
    }
  };

  const { email, invited, errorMessage, disabled, showSent, resendError, loading, loadingResend } =
    state;
  return (
    <div id="referral-program">
      <h1>Referral Program</h1>
      {invited ? (
        <Segment
          size="s"
          className="padded-horizontal container-green-success gradient gradient-darken bg-color-green bg-darken1"
        >
          <Icon
            border
            size="50"
            color="white"
            name="check"
            style={{
              borderColor: '#01BA92',
              backgroundColor: '#01BA92',
            }}
          />
          <div>
            Invitation sent! You will receive a $50 Amazon.com gift card if your colleague buys a
            membership.
          </div>
        </Segment>
      ) : (
        <Segment
          size="s"
          className="padded-horizontal container-green gradient gradient-darken bg-color-green bg-darken referral-input-container"
        >
          <div className="input-container">
            <div>
              <Input
                disabled={false}
                type="text"
                onChange={handleOnChange}
                name="email"
                value={email}
                placeholder="Enter your colleague's e-mail address"
                className="refer-input"
              />
              {disabled &&
                (!errorMessage ? (
                  <div className="message">Enter a valid email address to invite</div>
                ) : (
                  <div className="message">{errorMessage}</div>
                ))}
            </div>
            <Button
              fill
              color="green"
              disabled={disabled === true && email.length > 0}
              onClick={onSubmit}
            >
              Invite <Loading active={loading} />
            </Button>
          </div>
        </Segment>
      )}
      <Segment size="s" className="list-container padded-horizontal" group color="white">
        <h6>Receive $50 for every new colleague you refer</h6>
        <div className="for-each-new-referral">
          <ReferralProgramDescription />
        </div>
        <h6>My Giftcards ({giftCards.length})</h6>
        {giftCards.length > 0 &&
          _.map(giftCards, (data) => {
            return (
              <React.Fragment key={data.attributes.title}>
                <div className="line" />
                <div className="row">
                  <span
                    className={
                      data.attributes.status === 'sent'
                        ? 'refered-email-accepted'
                        : 'refered-email-pending'
                    }
                  >
                    {data.attributes.title}
                  </span>
                  {data.attributes.status === 'sent' ? (
                    <span className="sent">
                      SENT
                      <Icon name="check" className="right-icon" />
                    </span>
                  ) : data.attributes.status === 'pending_to_send' ? (
                    <span className=" pending-to-send-gitfcard">
                      SCHEDULED: WILLS BE DELIVERED ON {data.attributes.sendDate}
                    </span>
                  ) : (
                    <span className="pending-to-send-gitfcard">PENDING</span>
                  )}
                </div>
              </React.Fragment>
            );
          })}
        <div className="line" />
        {isFetchingGiftCards && <Loading active={isFetchingGiftCards} />}
        {giftCards.length < giftCardsCount && !isFetchingGiftCards && (
          <div className="load-more-wrapper">
            <Button className="color-black lighten2" onClick={() => loadMore('giftCards')}>
              more
            </Button>
          </div>
        )}
      </Segment>
      <Segment size="s" className="list-container padded-horizontal" group color="white">
        <h6>My Referrals ({referrals.length})</h6>
        {referrals.length > 0 &&
          _.map(referrals, (data, i) => {
            return (
              <React.Fragment key={data.id}>
                <div className="line" />
                <div className="row">
                  <span
                    className={
                      data.attributes.applied ? 'refered-email-accepted' : 'refered-email-pending'
                    }
                  >
                    {data.attributes.referredEmail}
                  </span>
                  {data.attributes.applied ? (
                    <span className="sent">
                      ACCEPTED
                      <Icon name="check" className="right-icon" />
                    </span>
                  ) : (
                    <div className="pending-resend">
                      <span className="pending">PENDING</span>
                      {loadingResend[i] ? (
                        <Loading active={loadingResend[i]} />
                      ) : showSent[i] ? (
                        resendError[i] ? (
                          <span className="sent">Error updating referred </span>
                        ) : (
                          <span className="sent">REMINDER SENT</span>
                        )
                      ) : (
                        <button onClick={() => resend(data.id, i)} className="resend">
                          RESEND
                        </button>
                      )}
                    </div>
                  )}
                </div>
              </React.Fragment>
            );
          })}
        <div className="line" />
        {isFetchingReferrals && <Loading active={isFetchingReferrals} />}
        {referrals.length < referralsCount && !isFetchingReferrals && (
          <div className="load-more-wrapper">
            <Button className="color-black lighten2" onClick={() => loadMore('referrals')}>
              more
            </Button>
          </div>
        )}
      </Segment>
      <div className="check-invite-and-win">
        * Read the
        <Button href={`${FRONT_ROOT}/referral-program-terms-and-conditions`} target="_blank">
          Terms and Conditions
        </Button>
      </div>
    </div>
  );
};

Refer.propTypes = {
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  fetchReferrals: PropTypes.func.isRequired,
  fetchGiftCards: PropTypes.func.isRequired,
  referUser: PropTypes.func.isRequired,
  resendReferUser: PropTypes.func.isRequired,
  giftCards: PropTypes.shape({
    isFetching: PropTypes.bool,
    count: PropTypes.number,
    data: PropTypes.array,
    page: PropTypes.number,
  }).isRequired,
  referrals: PropTypes.shape({
    isFetching: PropTypes.bool,
    count: PropTypes.number,
    data: PropTypes.array,
    page: PropTypes.number,
  }).isRequired,
};
export default connect(
  (store) => ({
    auth: store.auth,
    referrals: store.referrals,
    giftCards: store.giftCards,
  }),
  (dispatch) => ({
    fetchReferrals: bindActionCreators(fetchReferralsAction, dispatch),
    fetchGiftCards: bindActionCreators(fetchGiftCardsAction, dispatch),
    referUser: bindActionCreators(referUserAction, dispatch),
    resendReferUser: bindActionCreators(resendReferUserAction, dispatch),
  })
)(Refer);
