import React from 'react';

import FullWidth from 'layouts/FullWidth';
import Icon from 'components/Icon';
import Link from 'components/Link';
import Segment from 'components/Segment';

const Unsubscribe = () => {
  return (
    <FullWidth id="unsubscribe" className="full-height">
      <FullWidth.Content className="bg-color-white">
        <div id="left" className="bg-color-white bg-lighten1">
          <Link to="/" className="psycho_logo">
            <Icon name="logo" color="aqua" />
            <Icon name="logo_text" />
          </Link>
          <Segment id="unsubscribe">
            <p>
              You have been unsubscribed <b>to the newsletter</b>.
            </p>
            <p>Your membership is still active. </p>
            <p>
              If you need to cancel your membership, please <Link to="/contact-us">contact us</Link>
              .
            </p>
          </Segment>
        </div>
      </FullWidth.Content>
    </FullWidth>
  );
};

export default Unsubscribe;
