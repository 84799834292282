import React from 'react';
import _ from 'lodash';
import qs from 'qs';

import Button from 'components/Button';
import { _closeModal, _showModal, _updateModal } from 'actions/modals';

import {
  userHasPermission,
  userCanViewPublicationType,
  membershipsToPublicationType,
  membershipsToPublicationTypeString,
} from 'helpers/membership';
import history, { RGAEvent } from 'helpers/history';
import { TEST_CLASS_MAP } from 'reducers/testResults';
import { getCurrentAndNextYear } from 'helpers/date';

import { Analytics } from 'facades/Analytics';

import store from 'store';
import ReferModalForm from './ReferModalForm';
import UpdateUserForm from './UpdateUserForm';
import FreeMemberForm from './FreeMemberForm';

export const openModals = (e, type, title, free, publicationType = false, typeData) => {
  const { auth, memberships, publication, config } = store.getState();
  if (userCanViewPublicationType(publicationType)) {
    return true;
  }

  const currentMembership = auth.isLogged ? auth.user.membership : null;
  let GACategory = '';
  let GALabel = '';
  let actionContent;
  let actionTitle;
  let bgColor = free ? 'bg-color-white bg-lighten-1' : 'bg-color-green';
  let buttonsContainerClassName = null;
  let content = null;
  let data = null;
  let hideBottomButtons = false;
  let modalClassName = null;
  let primaryBtnAction = free ? '/register' : '/pricing';
  let primaryBtnText = 'Create a free account';
  let restMemberships = _.reject(memberships.data, {
    id: _.get(currentMembership, 'id', 0),
  });
  let secondaryBtnText = 'Continue in the website';
  let showFooter = !auth.isLogged;
  let textColor = `color-${free ? 'black' : 'white'}`;

  if (publicationType) {
    restMemberships = membershipsToPublicationType(publicationType);
  }
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }
  if ((type === 'download' || type === 'becomeAFreeMember') && free) {
    GACategory = 'Newsletter';
    GALabel = type === 'becomeAFreeMember' ? 'Popup' : 'Download Materials';

    content = (
      <FreeMemberForm
        title={title}
        onClose={() => store.dispatch(_closeModal(`${type}-modals`))}
        onSuccess={() => store.dispatch(_updateModal(`${type}-modals`, { linkToLogin: false }))}
        typeData={typeData}
        label={GALabel}
      />
    );

    buttonsContainerClassName = 'free-buttons';
    hideBottomButtons = true;
    modalClassName = 'free-member-download-modal';
  } else if (type === 'buyAMembership' || (type === 'download' && !free)) {
    GACategory = 'CME Program';
    GALabel = 'Popup';
    const CMEcredits = _.get(config, 'data.CME_SA_TOTAL', null);
    const SAcredits = _.get(config, 'data.SA_TOTAL', null);
    content = (
      <div className="buy-a-membership">
        <img
          className="lazyload"
          data-src="http://cdn.psychopharmacologyinstitute.com/sales-modal-image.png"
          alt=""
        />
        <span>Become a {membershipsToPublicationTypeString(publicationType)} Member.</span>
        <div>{getCurrentAndNextYear()} Psychopharmacology CME Program</div>
        {CMEcredits && SAcredits && (
          <span>
            Unlock up to
            <strong> {CMEcredits} CME Credits, </strong>
            including <strong>{SAcredits} SA Credits.</strong>
          </span>
        )}
      </div>
    );

    bgColor = 'bg-color-white bg-lighten-1';
    buttonsContainerClassName = 'center-buttons';
    modalClassName = 'buy-a-membership-modal';
    primaryBtnAction = '/cme-program';
    primaryBtnText = 'Get Instant Access';
    secondaryBtnText = <span className="buy-a-membership-secondary-button">No, thank you</span>;
    textColor = 'black';
  } else if (type === 'takeTest') {
    actionTitle = `Earn ${TEST_CLASS_MAP[publication.data.testClass]} Credits`;
    actionContent = `To earn ${TEST_CLASS_MAP[publication.data.testClass]} Credits from`;
  } else if (type === 'updateUser') {
    content = (
      <div className="update-user-modal-content">
        <UpdateUserForm
          onClose={() => store.dispatch(_closeModal(`${type}-modals`))}
          onSuccess={() => store.dispatch(_updateModal(`${type}-modals`, { linkToLogin: false }))}
          type={`${type}-modals`}
        />
      </div>
    );

    bgColor = 'bg-color-white';
    hideBottomButtons = true;
    modalClassName = 'update-user-modal';
    showFooter = false;
  } else if (type === 'read') {
    actionTitle = `Access to publication`;
    actionContent = `To continue reading more about`;
  } else if (type === 'referModal') {
    content = (
      <div className="refer-modal-content">
        <ReferModalForm
          onClose={() => store.dispatch(_closeModal(`${type}-modals`))}
          onSuccess={() => store.dispatch(_updateModal(`${type}-modals`, { linkToLogin: false }))}
          updateReferralsList={typeData.updateReferralsList}
          type={`${type}-modals`}
        />
      </div>
    );

    bgColor = 'bg-color-white';
    hideBottomButtons = true;
    modalClassName = 'refer-modal';
    showFooter = false;
  }

  if (content === null && !free) {
    if (auth.isLogged && auth.user.hasMembership && !auth.user.isMembershipValid) {
      content = (
        <>
          Your membership is expired!
          <br />
          {actionContent}:<br />
          {title}
          <br />
          {userHasPermission(publicationType) ? (
            <>you need to renew your {currentMembership.name} Membership.</>
          ) : (
            <>you need a {membershipsToPublicationTypeString(publicationType)} membership.</>
          )}
        </>
      );

      primaryBtnAction = `/checkout?membership=${currentMembership.id}`;
      primaryBtnText = `Renew ${currentMembership.name}`;
    } else {
      content = (
        <>
          {actionContent}:<br />
          {title}
          <br />
          you need a {membershipsToPublicationTypeString(publicationType)} membership.
        </>
      );

      if (restMemberships.length > 1) {
        primaryBtnAction = '/cme-program';
        primaryBtnText = 'Check our CME program';
      } else {
        primaryBtnAction = '/cme-program';
        primaryBtnText = 'Learn more';
      }
    }
  }

  data = {
    id: `${type}-modals`,
    content: (
      <div>
        <h6 size="s" className={textColor}>
          {actionTitle}
        </h6>
        <h4 className={textColor}>{content}</h4>
      </div>
    ),
    actions: !hideBottomButtons && (
      <div className={buttonsContainerClassName}>
        <Button
          key="first_action"
          fill
          to={primaryBtnAction}
          color="violet"
          invert={!free}
          onClick={() => {
            RGAEvent(GACategory, primaryBtnText, GALabel);
            Analytics.sendEvent(GACategory, {
              action: primaryBtnText,
              label: GALabel,
            });
            store.dispatch(_closeModal(`${type}-modals`));
          }}
        >
          {primaryBtnText}
        </Button>
        <Button
          key="second_action"
          onClick={() => {
            const action = _.replace(
              _.isString(secondaryBtnText) ? secondaryBtnText : secondaryBtnText.props.children,
              ',',
              ' '
            );

            RGAEvent(GACategory, action, GALabel);
            Analytics.sendEvent(GACategory, {
              action,
              label: GALabel,
            });
            store.dispatch(_closeModal(`${type}-modals`));
          }}
          color={free ? 'violet' : 'white'}
          link
          main
        >
          {secondaryBtnText}
        </Button>
      </div>
    ),
    GACategory,
    GALabel,
    active: true,
    className: modalClassName,
    color: bgColor,
    linkToLogin: showFooter,
  };

  store.dispatch(_showModal(data));

  return false;
};

export const showModal = (props, sectionId = null) => {
  const params = qs.parse(window.location.search.replace('?', ''));
  const { utm_source = '', utm_medium = '' } = params;
  if (
    _.includes(['email', 'Email'], utm_source) ||
    _.includes(['emailCkit', 'emailARPreach', 'email', 'Email'], utm_medium)
  ) {
    openModals(null, 'buyAMembership', null, false, false);
  } else {
    let title =
      props.publication && props.publication.data && props.publication.data.title
        ? props.publication.data.title
        : '';
    let data = {
      type: 'publication',
      id:
        props.publication && props.publication.data && props.publication.data.id
          ? props.publication.data.id
          : 0,
    };
    if (sectionId) {
      data = {
        type: 'section',
        id: sectionId,
      };
      title = (
        _.find(
          (props.publication && props.publication.data && props.publication.data.sections) || [],
          { id: sectionId }
        ) || {}
      ).title;
    }

    const location = _.get(props, 'location.pathname', '');
    if (location !== '' && !_.includes(location, 'publication')) {
      const isPrivateSection = _.get(props, 'section.data.private', false);
      const showToModal = isPrivateSection ? 'buyAMembership' : 'becomeAFreeMember';

      openModals(null, showToModal, title, true, false, data);
    }
  }
};

export const checkUpdateUserModalValidation = (user) => {
  const routesToExcludeUpdateUserModal = ['/cme-program', '/checkout'];
  const profession = _.get(user, 'profession', null);
  if (!profession && routesToExcludeUpdateUserModal.indexOf(history.location.pathname) === -1) {
    setTimeout(() => openModals(null, 'updateUser', null, null, null), 5000);
  }
};
