import moment from 'moment';

export const getCurrentAndNextYear = () =>
  `${moment().format('YYYY')}–${moment().add(1, 'years').format('YY')}`;

export const getCurrentYear = () => `${moment().format('YYYY')}`;

export const getTime = (time) => {
  if (!time) return '00:00';

  const minutes = Math.floor(time / 60);
  const seconds = time - minutes * 60;

  function str_pad_left(string, pad, length) {
    return (new Array(length + 1).join(pad) + string).slice(-length);
  }

  return `${str_pad_left(minutes, '0', 2)}:${str_pad_left(seconds, '0', 2)}`;
};
