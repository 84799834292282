import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/browser';

import { GA4_KEY } from 'config';

class GA {
  initialize = () => {
    if (GA4_KEY) {
      ReactGA.initialize(GA4_KEY);
    }
  };

  sendEvent = (name = 'event', params = {}) => {
    try {
      ReactGA.event(name, params);
    } catch (err) {
      console.log('Error sending GA4 event: ', err);
      Sentry.captureException(err);
    }
  };
}

export const Analytics = new GA();
