import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Input = (props) => {
  const {
    active,
    basic,
    className,
    color,
    disabled,
    fill,
    fluid,
    group,
    id,
    level,
    link,
    loading,
    negative,
    onChange,
    preventDefault,
    size,
    style,
    value,
    placeholder,
    type,
    name,
    checked,
    ariaLabel,
  } = props;

  const _validation = (currentValue) => {
    const regexp = new RegExp(props.validation, 'g');
    if (
      (props.validation &&
        regexp.match(value.substr(currentValue.length - 1, currentValue.length))) ||
      !props.validation
    )
      return true;
    return false;
  };

  const _onChange = (e) => {
    if (disabled === true) {
      e.preventDefault();
      return;
    }

    if (_.isFunction(onChange)) {
      if (preventDefault) {
        e.preventDefault();
      }

      if (_validation(e.target.value)) {
        if (onChange(e, { name, value: e.target.value }) === false) {
          e.preventDefault();
        }
      }
    }
  };

  const _className = classNames(
    'input',
    size,
    color,
    className,
    type,
    {
      secondary: basic !== false,
      group: group !== false,
      fill: fill !== false,
      negative: negative !== false,
      fluid: fluid !== false,
      active: active !== false,
      link: link !== false,
      disabled: disabled !== false,
      loading: loading !== false,
    },
    `level-${level}`
  );

  return (
    <input
      placeholder={placeholder}
      style={style}
      id={id}
      disabled={disabled}
      checked={checked}
      type={type}
      onChange={_onChange}
      value={value}
      className={_className}
      aria-label={ariaLabel}
    />
  );
};

Input.propTypes = {
  placeholder: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.oneOf(['xsmall', 'small', 'default', 'big', 'xbig']),
  group: PropTypes.bool,
  color: PropTypes.oneOf(['default', 'violet', 'red', 'blue', 'white', 'black', 'aqua', 'green']),
  basic: PropTypes.bool,
  onChange: PropTypes.func,
  id: PropTypes.string,
  className: PropTypes.string,
  type: PropTypes.string,
  negative: PropTypes.bool,
  fill: PropTypes.bool,
  fluid: PropTypes.bool,
  active: PropTypes.bool,
  level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  link: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  checked: PropTypes.bool,
  preventDefault: PropTypes.bool,
  validation: PropTypes.string,
  ariaLabel: PropTypes.string,
};

Input.defaultProps = {
  placeholder: '',
  name: '',
  value: '',
  size: 'default',
  style: {},
  group: false,
  color: 'default',
  basic: false,
  onChange: null,
  id: '',
  className: '',
  type: '',
  negative: false,
  fill: false,
  fluid: false,
  active: false,
  level: '2',
  link: false,
  disabled: false,
  loading: false,
  checked: false,
  preventDefault: true,
  validation: '',
  ariaLabel: '',
};

export default Input;
