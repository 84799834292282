import { deserializeRelationship } from 'actions/relationships';
import { deserializeFile } from 'actions/files';

const deserializeSlide = (slide, included = null) => {
  return {
    id: slide.id,
    ...slide.attributes,
    image: deserializeRelationship(slide.relationships.image, included, deserializeFile),
    references: deserializeRelationship(slide.relationships.references, included),
  };
};

export const deserializeTranscript = (transcript, included = null) => {
  return {
    id: transcript.id,
    ...transcript.attributes,
    slides: deserializeRelationship(transcript.relationships.slides, included, deserializeSlide),
  };
};
