import { PpAPI } from 'api';

import { REFERRALS_FETCHING, REFERRALS_FETCHED } from 'reducers/referrals';

export const _setReferrals = (data) => ({
  type: REFERRALS_FETCHED,
  data,
});

const fetchedReferrals = ({ referrals, count, page, isFromReferUser = false }) => ({
  type: REFERRALS_FETCHED,
  referrals,
  count,
  page,
  isFromReferUser,
});

const fetchingReferrals = () => ({
  type: REFERRALS_FETCHING,
});

export const fetchReferrals =
  (size = 20, page = 1) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(fetchingReferrals());

      const params = {
        'page[size]': size,
        'page[number]': page,
        include: 'createdBy',
      };

      PpAPI.get(`/api/referrals/`, {
        params,
      })
        .then((res) => {
          const result = {
            referrals: res.data.data,
            count: res.data.meta.pagination.count,
            page,
          };
          resolve(result);
          dispatch(fetchedReferrals(result));
        })
        .catch((err) => {
          reject(err);
        });
    });

export const referUser =
  (email, updateList = false) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const data = {
        data: {
          attributes: {
            referred_email: email,
          },
          type: 'Referred',
        },
      };
      PpAPI.post('/api/referrals/', data)
        .then((res) => {
          const referred = {
            type: res.data.data.type,
            id: res.data.data.id,
            attributes: res.data.data.attributes,
          };
          resolve(referred);
          if (updateList) {
            dispatch(fetchedReferrals({ referrals: [referred], isFromReferUser: true }));
          }
        })
        .catch((err) => {
          reject(err);
        });
    });

export const resendReferUser = (id) => () =>
  new Promise((resolve, reject) => {
    PpAPI.patch(`api/referrals/${id}/`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const validateReferred = (email) => () =>
  new Promise((resolve, reject) => {
    PpAPI.get(`api/referrals/validate/?referred_email=${encodeURIComponent(email)}`)
      .then((res) => {
        resolve(res.data.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
