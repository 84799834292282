export const deserializeFile = (file) => {
  if (!file) {
    return {};
  }

  return {
    id: file.id,
    ...file.attributes,
  };
};
