import React from 'react';
import _ from 'lodash';
import * as Sentry from '@sentry/browser';

import history from 'helpers/history';

export const lazyLoad = ({ importFn, module = null, component = '' }) =>
  React.lazy(() =>
    importFn()
      .then((res) => {
        return { default: module && component ? res[component] : res.default };
      })
      .catch((err) => {
        const pathname = _.get(window, 'location.pathname', '');
        const search = _.get(window, 'location.search', '');
        const redirect = pathname ? `?redirect=${pathname}${search}` : '';
        console.log(`Catch loading component ${component}: `, {
          redirect,
          component,
          error: JSON.stringify(err),
        });

        Sentry.withScope((scope) => {
          scope.setExtra('redirect', redirect);
          scope.setExtra('component', component);
          scope.setExtra('error', err);
          Sentry.captureException(err);
        });

        const tryLocalStorage = localStorage.getItem('try-error');
        if (!tryLocalStorage) localStorage.setItem('try-error', 1);
        const tryOnError = `&try=${tryLocalStorage || 1}`;

        history.push(`/error-network${redirect}${tryOnError}`);
      })
  );

export function retry({ fn, module = null, component = '' }, retriesLeft = 10, interval = 800) {
  return new Promise((resolve, reject) => {
    fn()
      .then((res) => {
        resolve({ default: module && component ? res[component] : res.default });
      })
      .catch((err) => {
        const pathname = _.get(window, 'location.pathname', '');

        console.log(`Catch loading component ${component}: `, {
          pathname,
          component,
          retriesLeft,
          error: JSON.stringify(err),
        });
        Sentry.withScope((scope) => {
          scope.setExtra('pathname', pathname);
          scope.setExtra('component', component);
          scope.setExtra('retriesLeft', retriesLeft);
          scope.setExtra('error', err);
          Sentry.captureException(err);
        });

        setTimeout(() => {
          if (retriesLeft === 1) {
            history.push('/load-error');
            window.location.reload();
            return;
          }

          retry({ fn, module, component }, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
}

export const scrollToTopbar = () => {
  const topbar = document.getElementById('topbar');
  if (topbar) {
    topbar.scrollIntoView();
  }
};
