import React from 'react';
import { PropTypes } from 'prop-types';
import _ from 'lodash';
import { Link, useHistory } from 'react-router-dom';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import classNames from 'classnames';

import Avatar from 'components/Avatar';
import Button from 'components/Button';
import Icon from 'components/Icon';
import PublicationIcon from 'components/PublicationIcon';

import { PUBLICATION_INTERVIEW, PUBLICATION_VIDEO, PODCAST } from 'reducers/publications';

const Card = (props) => {
  const { publication, author, actions, to, size, isLibrary } = props;

  const history = useHistory();

  const { title, excerpt = null, type = '', timeLabel = null, metadataLabel = null } = publication;

  const _className = classNames('card', type, {
    [`size-${size}`]: size,
    linked: to,
  });
  let _actions = null;
  let cardContent = null;

  if (actions) {
    _actions = (
      <div className="actions">
        {_.map(actions, (action, i) => (
          <Button key={`card_action_${i}`} {...action}>
            {action.text}
          </Button>
        ))}
      </div>
    );
  }

  let contentType;
  let cardInfo;

  const isHomePage = _.get(history, 'location.pathname', '') === '/';

  switch (size) {
    case 'small':
      cardContent = (
        <>
          <div className="card-metadata">
            {isLibrary ? (
              <div className="metadata library-metadata">
                <div className="head">
                  <div className="title">{metadataLabel}</div>
                  {publication.earnLabelSmall && (
                    <div className={`earn-label ${publication.expired ? 'expired' : ''}`}>
                      <Icon name="certification" />
                      <span className="value">{publication.earnLabelSmall}</span>
                    </div>
                  )}
                </div>
                <div className="credits">
                  {publication.expired ? <p>Expired on {publication.expirationDate}</p> : null}
                  {publication.completed ? <Icon name="completed" className="color-green" /> : null}
                </div>
              </div>
            ) : (
              <div className="metadata">
                {type ? (
                  <div className={`type ${publication.expired ? 'disabled' : ''}`}>
                    <PublicationIcon publicationType={type} />
                    {metadataLabel}
                  </div>
                ) : null}
                <div className="credits">
                  {publication.expired ? <p>Expired on {publication.expirationDate}</p> : null}
                  {publication.completed ? <Icon name="completed" className="color-green" /> : null}
                </div>
              </div>
            )}
          </div>
          <div className="card-info">
            {type === PUBLICATION_INTERVIEW ? (
              <h4>
                {title} {author && <span className="color-aqua">with {author.fullNameAffix}</span>}
              </h4>
            ) : (
              <>
                <div className="dimmer" />
                <div className="title">{title}</div>
                <div className="description" dangerouslySetInnerHTML={{ __html: excerpt }} />
              </>
            )}
          </div>
          {author && <Avatar link={!to} type="inline" size="s" author={author} />}
        </>
      );
      break;
    case 'big':
    default:
      contentType = (
        <div className="type">
          <PublicationIcon publicationType={type} /> {metadataLabel}
        </div>
      );
      cardInfo = null;

      if (type === PUBLICATION_INTERVIEW) {
        cardInfo = (
          <h3>
            {title} {author && <span className="color-aqua">with {author.fullNameAffix}</span>}
          </h3>
        );
      } else {
        cardInfo = (
          <>
            {isHomePage ? <h3 className="title">{title}</h3> : <div className="title">{title}</div>}
            <div className="description" dangerouslySetInnerHTML={{ __html: excerpt }} />
          </>
        );
      }

      cardContent = (
        <Grid className="container container-max-width" fluid>
          <Row>
            <Col xs={12} md={7} className="card-info">
              {cardInfo}
            </Col>
            <Col xs className="card-metadata">
              <div className="metadata">
                {contentType}
                {type !== PUBLICATION_VIDEO && type !== PODCAST && timeLabel && (
                  <div className="time">
                    <Icon name="time" /> {timeLabel}
                  </div>
                )}
              </div>
              {author && <Avatar link={!to} type="inline" size="s" author={author} />}
            </Col>
          </Row>
        </Grid>
      );
      break;
  }

  return (
    <div className={_className}>
      {to && (
        <Link to={to} className="linked">
          <div className="transition link-info">
            <div className="info">
              {timeLabel ? (
                <>
                  <Icon name="time" /> {timeLabel}
                </>
              ) : (
                <>
                  <Icon name="info" /> View more info
                </>
              )}
            </div>
            <div className="arrow">
              <Icon name="arrow_right" color="violet" />
            </div>
          </div>
        </Link>
      )}
      {cardContent}
      {_actions}
    </div>
  );
};

Card.propTypes = {
  publication: PropTypes.object.isRequired,
  author: PropTypes.object,
  actions: PropTypes.arrayOf(PropTypes.object),
  to: PropTypes.string,
  size: PropTypes.oneOf(['small', 'default', 'big']),
  className: PropTypes.string,
  isLibrary: PropTypes.bool,
};

Card.defaultProps = {
  author: null,
  actions: null,
  to: false,
  size: 'default',
  className: '',
  isLibrary: false,
};

Card.Group = (props) => (
  <div {...{ ...props, className: `${props.className} cards` }}>{props.children}</div>
);

Card.Group.propTypes = {
  className: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Card;
