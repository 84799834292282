import _ from 'lodash';
import moment from 'moment';
import { CancelToken, isCancel } from 'axios';

import { PpAPI } from 'api';
import {
  PUBLICATION_COMPLETE,
  PUBLICATION_FETCHED,
  PUBLICATION_FETCHING,
  PUBLICATION_RESET,
  PUBLICATION_RETAKE_TEST,
  PUBLICATIONS_FETCHED,
  PUBLICATIONS_FETCHING,
} from 'reducers/publications';
import {
  RESET_TEST_RESULTS,
  TEST_RESULTS_FETCHED,
  TEST_RESULTS_STATS_FETCHED,
} from 'reducers/testResults';
import { deserializeAuthor } from 'actions/authors';
import { deserializeFile } from 'actions/files';
import { deserializeQuestion } from 'actions/questions';
import { deserializeTranscript } from 'actions/transcripts';
import { deserializeRelationship, deserializeGeneric } from 'actions/relationships';

const publicationIncludes = [
  'materials',
  'keyPoints',
  'author.image',
  'interviewer.image',
  'sections.materials',
  'sections.transcript.slides.image',
  'questions.answers',
  'categories',
];

const includes = ['author.image', 'categories'];

export const completePublication = (pId, results) => ({
  type: PUBLICATION_COMPLETE,
  pId,
  results,
});

export const retakeTestPublication = (pId) => ({
  type: PUBLICATION_RETAKE_TEST,
  pId,
});

export const fetchedPublication = (publication) => ({
  type: PUBLICATION_FETCHED,
  publication,
});

export const resetPublication = () => ({
  type: PUBLICATION_RESET,
});

export const deserializePublication = (publication) => {
  return {
    id: publication.id,
    ...publication.attributes,
    modified: moment(publication.attributes.modified).format('MM/DD/YYYY'),
    publicationDate: moment(publication.attributes.publicationDate, 'YYYY-MM-DD').format(
      'MM/DD/YYYY'
    ),
    expirationDate: moment(publication.attributes.expirationDate, 'YYYY-MM-DD').format(
      'MM/DD/YYYY'
    ),
    completedTestResult: _.find(publication.attributes.testResults, { status: 'APPROVED' }),
  };
};

const deserializeSection = (section, included = null, includePublication = false) => {
  return {
    id: section.id,
    ...section.attributes,
    publicationId: section.relationships.publication.data.id,
    transcript: deserializeRelationship(
      section.relationships.transcript,
      included,
      deserializeTranscript
    ),
    materials: deserializeRelationship(section.relationships.materials, included),
    ...(includePublication ? { publication: section.publication } : {}),
  };
};

const getFilters = (filters) => {
  if (filters) {
    let _filters = {};

    if (_.has(filters, 'testType')) {
      const testType = filters.testType.toString();
      if (!_.isEmpty(testType)) {
        _filters = {
          ..._filters,
          'filter[test_class]': testType,
        };
      }
    }

    if (_.has(filters, 'types')) {
      if (!_.isEmpty(filters.types)) {
        _filters = {
          ..._filters,
          'filter[type__in]': filters.types.join(','),
        };
      }
    }

    if (_.has(filters, 'search')) {
      if (!_.isEmpty(filters.search)) {
        _filters = {
          ..._filters,
          'filter[search]': filters.search,
        };
      }
    }

    if (_.has(filters, 'categories')) {
      if (!_.isEmpty(filters.categories)) {
        _filters = {
          ..._filters,
          'filter[categories__id__in]': filters.categories.join(','),
        };
      }
    }

    if (_.has(filters, 'completed')) {
      if (!_.isEmpty(filters.completed)) {
        _filters = {
          ..._filters,
          'filter[completed]': filters.completed,
        };
      }
    }

    if (_.has(filters, 'userCanTakeTest')) {
      if (!_.isEmpty(filters.userCanTakeTest)) {
        _filters = {
          ..._filters,
          'filter[can_user_take_test]': filters.userCanTakeTest,
        };
      }
    }

    filters = _filters;
  }

  return filters;
};

export const processPublications = (res) =>
  _.map(res.data.data, (publication) => {
    const p = deserializePublication(publication, res.data.included);
    _.each(publication.relationships, (relationship, name) => {
      let f;
      let ret_default = null;

      switch (name) {
        case 'author':
          f = deserializeAuthor;
          break;
        case 'authors':
          f = deserializeAuthor;
          break;
        case 'interviewer':
          f = deserializeAuthor;
          break;
        case 'sections':
          f = deserializeSection;
          ret_default = [];
          break;
        case 'questions':
          f = deserializeQuestion;
          ret_default = [];
          break;
        case 'materials':
          f = deserializeFile;
          ret_default = [];
          break;
        case 'keyPoints':
          f = deserializeFile;
          break;
        case 'categories':
          f = deserializeGeneric;
          ret_default = [];
          break;
        default:
          break;
      }

      p[name] = deserializeRelationship(relationship, res.data.included, f, ret_default);
    });
    return p;
  });

let cancelFetchPublications = null;

export const fetchPublications =
  (page = 1, size = 6, filters = null, customFilters = null) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      if (cancelFetchPublications !== null) {
        cancelFetchPublications();
        cancelFetchPublications = null;
      }

      filters = {
        'filter[visibility]': 'published',
        ...getFilters(filters),
        include: includes.join(','),
        sort: '-modified',
        'page[size]': size,
        'page[number]': page,
        ...customFilters,
      };

      dispatch({ type: PUBLICATIONS_FETCHING });
      PpAPI.get('/api/publications/', {
        cancelToken: new CancelToken(function executor(cancel) {
          cancelFetchPublications = cancel;
        }),
        params: filters,
      })
        .then((res) => {
          const publications = processPublications(res);
          dispatch({
            type: PUBLICATIONS_FETCHED,
            publications,
            pagination: res.data.meta.pagination,
          });
          resolve(publications);
        })
        .catch((err) => {
          if (!isCancel(err)) {
            reject(err);
          }
        });
    });

export const fetchPodcastsPlayer =
  (page = 1, size = 6, filters = null, customFilters = null) =>
  () =>
    new Promise((resolve, reject) => {
      if (cancelFetchPublications !== null) {
        cancelFetchPublications();
        cancelFetchPublications = null;
      }

      filters = {
        'filter[visibility]': 'published',
        ...getFilters(filters),
        include: includes.join(','),
        sort: '-modified',
        'page[size]': size,
        'page[number]': page,
        ...customFilters,
      };
      PpAPI.get('/api/publications/', {
        cancelToken: new CancelToken(function executor(cancel) {
          cancelFetchPublications = cancel;
        }),
        params: filters,
      })
        .then((res) => {
          const publications = processPublications(res);
          resolve(publications);
        })
        .catch((err) => {
          if (!isCancel(err)) {
            reject(err);
          }
        });
    });

export const fetchPublicationsOrderByPkIn = (page = 1, size = 10, ids = []) =>
  new Promise((resolve, reject) => {
    const filters = {
      'filter[visibility]': 'published',
      'filter[id__in]': ids.join(','),
      include: includes.join(','),
      'page[size]': size,
      'page[number]': page,
    };

    PpAPI.get('/api/publications/order_by_pk_in/', {
      params: filters,
    })
      .then((res) => {
        const publications = processPublications(res);
        resolve(publications);
      })
      .catch((err) => {
        if (!isCancel(err)) {
          reject(err);
        }
      });
  });

export const sendSurvey = (publicationId, answers) =>
  new Promise((resolve, reject) => {
    const requestBody = {
      data: {
        type: 'Survey',
        attributes: {
          answers,
        },
      },
    };

    PpAPI.post(`/api/publications/${publicationId}/surveys/`, requestBody)
      .then((res) => {
        if (res.status === 201) {
          resolve(res);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });

export const fetchPublicationsWithoutReducer = (
  page = 1,
  size = 6,
  filters = null,
  customFilters = null
) =>
  new Promise((resolve, reject) => {
    filters = {
      'filter[visibility]': 'published',
      ...getFilters(filters),
      include: includes.join(','),
      sort: '-modified',
      'page[size]': size,
      'page[number]': page,
      ...customFilters,
    };

    PpAPI.get('/api/publications/', {
      params: filters,
    })
      .then((res) => {
        resolve({
          data: processPublications(res),
          pagination: _.get(res, 'data.meta.pagination', {}),
        });
      })
      .catch((err) => {
        reject(err);
      });
  });

let cancelFetchPublication = null;

export const fetchPublication =
  (pId, toShow = 'all', extraAction = null, history = null) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      if (cancelFetchPublication !== null) {
        cancelFetchPublication();
        cancelFetchPublication = null;
      }

      let filterIncludes;
      let fields = {};
      switch (toShow) {
        case 'test':
          filterIncludes = ['questions.answers', 'sections'];
          break;
        case 'section':
          filterIncludes = ['author.image', 'sections'];

          fields = {
            ...fields,
            'fields[Publication]':
              'title,sections,publication_date,expiration_date,type,user_can_take_test,questions,author,completed,has_test_results,has_mcq,earn_label,slug,earn_label_small,private,description,expired,testStatus,test_class',
            'fields[Section]':
              'lock,private,slug,title,meta_description,publication,attachment_url,description,featured_image',
          };
          break;
        case 'cover':
          filterIncludes = [
            'materials',
            'keyPoints',
            'author.image',
            'authors',
            'authors.image',
            'interviewer.image',
            'sections',
            'categories',
            'jointSponsor',
          ];
          break;
        case 'all':
        default:
          filterIncludes = publicationIncludes;
          break;
      }

      dispatch({ type: PUBLICATION_FETCHING });
      PpAPI.get(`/api/publications/${pId}/`, {
        cancelToken: new CancelToken(function executor(cancel) {
          cancelFetchPublication = cancel;
        }),
        params: {
          ...fields,
          include: filterIncludes.join(','),
        },
      })
        .then((res) => {
          const { data } = res.data;
          const publication = deserializePublication(data, res.data);
          _.each(data.relationships, (relationship, name) => {
            let f;

            switch (name) {
              case 'author':
                f = deserializeAuthor;
                break;
              case 'authors':
                f = deserializeAuthor;
                break;
              case 'interviewer':
                f = deserializeAuthor;
                break;
              case 'sections':
                f = deserializeSection;
                break;
              case 'questions':
                f = deserializeQuestion;
                break;
              case 'materials':
                f = deserializeFile;
                break;
              case 'keyPoints':
                f = deserializeFile;
                break;
              case 'categories':
                f = deserializeGeneric;
                break;
              default:
                break;
            }

            publication[name] = deserializeRelationship(relationship, res.data.included, f);
          });

          if (extraAction === 'resetTestResults') {
            dispatch({
              type: RESET_TEST_RESULTS,
            });
          }

          dispatch(fetchedPublication(publication));
          resolve(publication);
        })
        .catch((err) => {
          if (!isCancel(err)) {
            const status = _.get(err, 'response.status', null);
            if (history && status === 404) history.push('/error-404');
            reject(err);
          }
        });
    });

let cancelFetchPublicationTestResults = null;

export const fetchPublicationTestResults = (pId) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (cancelFetchPublicationTestResults !== null) {
      cancelFetchPublicationTestResults();
      cancelFetchPublicationTestResults = null;
    }

    PpAPI.get(
      `/api/publications/${pId}/tests/?include=publication.questions.answers,publication.sections`,
      {
        cancelToken: new CancelToken(function executor(cancel) {
          cancelFetchPublicationTestResults = cancel;
        }),
      }
    )
      .then((res) => {
        const results = _.map(res.data.data, (item) => deserializeGeneric(item));

        const answers = _.chain(res.data.included)
          .filter((item) => item.type === 'Answer')
          .map((item) => {
            const answer = deserializeGeneric(item);
            return {
              ...answer,
              questionId: answer.relationships.question.data.id,
            };
          })
          .value();

        const questionsFeedBack = _.chain(res.data.included)
          .filter((item) => item.type === 'Question')
          .map(({ id, attributes: { feedback } }) => {
            return {
              id,
              feedback,
            };
          })
          .value();

        const publication = deserializeGeneric(_.find(res.data.included, { type: 'Publication' }));
        const data = {
          results,
          answers,
          publication,
          questionsFeedBack,
        };
        dispatch({
          type: TEST_RESULTS_FETCHED,
          data,
        });
        resolve(data);
      })
      .catch((err) => {
        if (!isCancel(err)) {
          reject(err);
        }
      });
  });

let cancelFetchPublicationTestResultsStats = null;

export const fetchPublicationTestResultsStats = (pId) => (dispatch) =>
  new Promise((resolve, reject) => {
    if (cancelFetchPublicationTestResultsStats !== null) {
      cancelFetchPublicationTestResultsStats();
      cancelFetchPublicationTestResultsStats = null;
    }

    PpAPI.get(`/api/publications/${pId}/test/stats/`, {
      cancelToken: new CancelToken(function executor(cancel) {
        cancelFetchPublicationTestResultsStats = cancel;
      }),
    })
      .then((res) => {
        const data = _.get(res, 'data.data', []);
        dispatch({
          type: TEST_RESULTS_STATS_FETCHED,
          data,
        });
        resolve(data);
      })
      .catch((err) => {
        if (!isCancel(err)) {
          reject(err);
        }
      });
  });

export const sendPublicationTest = (pId, results) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(completePublication(pId, results));

    PpAPI.post(`/api/publications/${pId}/tests/`, {
      data: {
        type: 'PublicationTestResult',
        attributes: {
          results,
        },
        relationships: {
          publication: {
            data: {
              id: pId,
              type: 'Publication',
            },
          },
        },
      },
    })
      .then(resolve)
      .catch((err) => {
        dispatch(retakeTestPublication(pId));
        reject(err);
      });
  });
