import _ from 'lodash';
import { RGAEvent } from 'helpers/history';

import { Analytics } from 'facades/Analytics';

export const MODALS_SHOW = 'MODALS_SHOW';
export const MODALS_CLOSE = 'MODALS_CLOSE';
export const MODALS_UPDATE = 'MODALS_UPDATE';
export const MODALS_RESTORE = 'MODALS_RESTORE';

const modalsInit = [];

const getDocumentBodyClassNames = () => document.getElementsByTagName('body')[0].classList;

export const modals = (modalsInfo = modalsInit, action) => {
  switch (action.type) {
    case MODALS_SHOW:
      if (_.get(action, 'modal.GACategory', null) && _.get(action, 'modal.GALabel', null)) {
        RGAEvent(action.modal.GACategory, 'Shown', action.modal.GALabel);
        Analytics.sendEvent(action.modal.GACategory, {
          action: 'Shown',
          label: action.modal.GALabel,
        });
      }
      getDocumentBodyClassNames().add('modals', 'modal');
      return [...modalsInfo, action.modal];

    case MODALS_CLOSE:
      if (_.filter(modalsInfo, { active: true })) {
        getDocumentBodyClassNames().remove('modals', 'modal');
      }
      return _.reject(modalsInfo, { id: action.modalId });

    case MODALS_UPDATE:
      return _.map(modalsInfo, (modal) => {
        if (modal.id === action.id) {
          return {
            ...modal,
            ...action.valuesToUpdate,
          };
        }
        return modal;
      });
    case MODALS_RESTORE:
      getDocumentBodyClassNames().remove('modals', 'modal');
      return modalsInit;

    default:
      return modalsInfo;
  }
};
