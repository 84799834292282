import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';
import store from 'store';

import Button from 'components/Button';
import Icon from 'components/Icon';
import DockModal from 'components/Modal/Layout/DockModal';
import FullModal from 'components/Modal/Layout/FullModal';
import FreeSectionBlockModal from 'components/Modal/Components/FreeSectionBlockModal';
import SearchModal from 'components/Modal/Components/SearchModal';

import history, { signinUrl, RGAEvent } from 'helpers/history';

import { Analytics } from 'facades/Analytics';

import { _closeModal } from 'actions/modals';

import './modals.css';

const coachmarkElementsIds = ['react-joyride-portal', 'coachmark-intro'];

const Modal = (props) => {
  const { modals } = props;
  const modalsToShow = _.filter(modals, { active: true });

  if (coachmarkElementsIds.some((id) => document.getElementById(id))) {
    return null;
  }

  const onClick = () => {
    store.dispatch(_closeModal(_.last(modalsToShow).id));
    const { GALabel, GACategory } = _.last(modalsToShow);
    if (GALabel && GALabel !== '' && GACategory && GACategory !== '') {
      RGAEvent(GACategory, 'Log In', GALabel);
      Analytics.sendEvent(GACategory, {
        action: 'Log In',
        label: GALabel,
      });
    }
    history.push(signinUrl(true));
  };

  if (modalsToShow.length > 0) {
    const modalToShow = _.last(modalsToShow);

    switch (modalToShow.id) {
      case 'searchMobile-modals':
        return (
          <FullModal>
            <SearchModal />
          </FullModal>
        );
      case 'freeSectionBlock-modals':
        return (
          <DockModal>
            <FreeSectionBlockModal />
          </DockModal>
        );
      default:
        return (
          <div
            role="button"
            tabIndex={-1}
            onKeyDown={null}
            className="modals-container"
            onClick={() => store.dispatch(_closeModal(modalToShow.id))}
          >
            <Button
              color="white"
              onClick={(event) => {
                store.dispatch(_closeModal(modalToShow.id));
                event.stopPropagation();
              }}
              className="close"
            >
              <div>
                <span className="close-button"> Close</span>
                <Icon name="delete" />
              </div>
            </Button>
            <div className="modals-windows">
              <div
                role="button"
                tabIndex={-1}
                onKeyDown={null}
                className={`modal-container ${modalToShow.className}`}
                onClick={(e) => e.stopPropagation()}
              >
                <div className={classNames('modal-wrap', modalToShow.color)}>
                  <div className="modal-content">{modalToShow.content}</div>
                  {modalToShow.actions && (
                    <div className="modal-actions">{modalToShow.actions}</div>
                  )}
                  {modalToShow.linkToLogin && (
                    <div className="modal-footer">
                      <Button color="violet" main link onClick={onClick}>
                        Or login if you have an account
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
    }
  }

  return null;
};

Modal.propTypes = {
  modals: PropTypes.array.isRequired,
};
export default Modal;
