import React from 'react';

import Link from 'components/Link';
import Icon from 'components/Icon';
import Segment from 'components/Segment';
import FullWidth from 'layouts/FullWidth';

import '../Register/register.css';

const NewsletterSuccess = () => {
  return (
    <FullWidth id="register" className="full-height">
      <FullWidth.Content className="bg-color-white">
        <div id="left" className="bg-color-white bg-lighten1">
          <Link to="/" className="psycho_logo">
            <Icon name="logo" color="aqua" />
            <Icon name="logo_text" />
          </Link>
          <Segment id="form">
            <h4>Success!</h4>
            <p>
              You are now registered to our newsletter. Please check your inbox, we just sent you a
              welcome message.
            </p>
            <Link to="/cme-program">Check our CME program</Link>
          </Segment>
        </div>
        <div id="right" />
      </FullWidth.Content>
    </FullWidth>
  );
};

export default NewsletterSuccess;
