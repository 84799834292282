export const TEST_RESULTS_SET_ANSWER = 'TEST_RESULTS_SET_ANSWER';
export const TEST_RESULTS_FETCHED = 'TEST_RESULTS_FETCHED';
export const TEST_RESULTS_STATS_FETCHED = 'TEST_RESULTS_STATS_FETCHED';
export const RESET_TEST_RESULTS = 'RESET_TEST_RESULTS';

export const TEST_CLASS_CME = 1;
export const TEST_CLASS_SA = 2;

export const TEST_CLASS_MAP = {
  [TEST_CLASS_CME]: 'CME',
  [TEST_CLASS_SA]: 'SA',
};

const testResultsInit = {
  data: {},
  stats: [],
};

export const testResults = (testResultsInfo = testResultsInit, action) => {
  switch (action.type) {
    case TEST_RESULTS_FETCHED:
      return {
        ...testResultsInfo,
        data: action.data,
      };

    case TEST_RESULTS_STATS_FETCHED:
      return {
        ...testResultsInfo,
        stats: action.data,
      };

    case RESET_TEST_RESULTS:
      return testResultsInit;

    default:
      return testResultsInfo;
  }
};
