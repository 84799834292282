export const AUTHORS_FETCHING = 'AUTHORS_FETCHING';
export const AUTHORS_FETCHED = 'AUTHORS_FETCHED';
export const AUTHOR_FETCHING = 'AUTHOR_FETCHING';
export const AUTHOR_FETCHED = 'AUTHOR_FETCHED';

const authorsDefault = {
  data: [],
  isFetching: false,
};

export const authors = (authorsInfo = authorsDefault, action) => {
  switch (action.type) {
    case AUTHORS_FETCHING:
      return {
        ...authorsInfo,
        isFetching: true,
      };
    case AUTHORS_FETCHED:
      return {
        data: action.authors,
        isFetching: false,
      };
    default:
      return authorsInfo;
  }
};

const authorDefault = {
  data: {},
  isFetching: false,
};

export const author = (authorInfo = authorDefault, action) => {
  switch (action.type) {
    case AUTHOR_FETCHING:
      return {
        ...authorInfo,
        isFetching: true,
      };
    case AUTHOR_FETCHED:
      return {
        data: action.author,
        isFetching: false,
      };
    default:
      return authorInfo;
  }
};
