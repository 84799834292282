import Cookies from 'universal-cookie';
import * as Sentry from '@sentry/browser';
import { version } from '../package.json';

const cookies = new Cookies();

let api_root;
let domain_front;
let front_root;
let googleAnalyticsKey;
const googleOptimizeKey = 'GTM-MHVHFP8';
let googleTagManagerKey;
let specialMembershipId = 37;
const specialMembershipBId = 44;
let stripe_public_key;
const timeoutErrorNetwork = 15;
const timeoutErrorNetworkFinal = 1800;
let elasticSearchConfig = {
  engineName: 'psychcampus',
  endpointBase: 'https://psychopharmacology-institute.ent.us-east-1.aws.found.io',
  searchKey: 'search-z6c7gfh6txeqomt4wzqe1oq8',
};
let hotjarConfig = { hjid: 2983950, hjsv: 6 };
let ga4Key = 'G-02NHLVQ0LC';

if (process.env.REACT_APP_ENV === 'production') {
  ga4Key = 'G-NCH828M4Z1';
  domain_front = 'psychopharmacologyinstitute.com';
  api_root = 'https://api.psychopharmacologyinstitute.com';
  stripe_public_key = 'pk_live_vpyxEJSTSGNLstW3mgvdvClZ00VMzqAoed';
  googleAnalyticsKey = 'UA-30127038-1';
  front_root = 'https://psychopharmacologyinstitute.com';
  googleTagManagerKey = 'GTM-M5SQ2K9';
  // googleTagManagerKey = 'GTM-MHVHFP8'
  hotjarConfig = { hjid: 919085, hjsv: 6 };

  // SENTRY configuration
  const SENTRY_ENV = process.env.REACT_APP_SENTRY_ENV || null;
  const SENTRY_DSN = 'https://6735bac96c58412884dec58acfed5a81@sentry.io/1835714';
  const SENTRY_RELEASE = process.env.REACT_APP_SENTRY_RELEASE || version;

  if (SENTRY_ENV && SENTRY_ENV !== 'dev-prod') {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: SENTRY_ENV,
      release: SENTRY_RELEASE,
    });
  }
} else if (process.env.REACT_APP_ENV === 'develop' || process.env.REACT_APP_ENV === 'test') {
  domain_front = 'devpsycho.com';
  api_root = 'https://api.devpsycho.com';
  front_root = 'https://devpsycho.com';
  stripe_public_key =
    'pk_test_51GuH7sBPGpC0XBDqFHydelKv3mphlg2ZIktCeVbGF2JXLXRhzs8DmSDSb9gshYLQrozWSvmBnqJhZIHmosALkxs300m16zLzy5';
  googleAnalyticsKey = 'UA-30127038-3';
  googleTagManagerKey = 'GTM-NNBBF3P';
  elasticSearchConfig = {
    engineName: 'psychcampus',
    endpointBase: 'https://devpsycho.ent.us-east-1.aws.found.io/',
    searchKey: 'search-tveevi2ofu7r5uqkypx3yr27',
  };
  hotjarConfig = { hjid: 2983950, hjsv: 6 };
} else if (process.env.REACT_APP_ENV === 'qa') {
  domain_front = 'qa.devpsycho.com';
  api_root = 'https://qa-api.devpsycho.com';
  front_root = 'https://qa.devpsycho.com';
  stripe_public_key =
    'pk_test_51GuH7sBPGpC0XBDqFHydelKv3mphlg2ZIktCeVbGF2JXLXRhzs8DmSDSb9gshYLQrozWSvmBnqJhZIHmosALkxs300m16zLzy5';
  googleAnalyticsKey = 'UA-30127038-3';
  googleTagManagerKey = 'GTM-NNBBF3P';
  specialMembershipId = 25;
  elasticSearchConfig = {
    engineName: 'qa-psychcampus',
    endpointBase: 'https://devpsycho.ent.us-east-1.aws.found.io/',
    searchKey: 'search-tveevi2ofu7r5uqkypx3yr27',
  };
  hotjarConfig = { hjid: 2983949, hjsv: 6 };
} else if (process.env.REACT_APP_ENV === 'localhost') {
  domain_front = 'localhost';
  api_root = 'http://localhost:8000';
  front_root = `http://localhost:${process.env.PORT || 3000}`;
  stripe_public_key =
    'pk_test_51GuH7sBPGpC0XBDqFHydelKv3mphlg2ZIktCeVbGF2JXLXRhzs8DmSDSb9gshYLQrozWSvmBnqJhZIHmosALkxs300m16zLzy5';
}

export const API_ROOT = api_root;
export const FRONT_ROOT = front_root;
export const KEY_JWT_TOKEN = 'ppToken';
export const CONVERT_KIT_API_KEY = '8GVDMzjf0jq3cNQqEGVOTg';
export const ZENDESK_API_URL = 'https://psychcampushelp.zendesk.com/api/v2';

export const getJwtToken = () => {
  if (!localStorage.getItem(KEY_JWT_TOKEN) && cookies.get('jwt')) {
    localStorage.setItem(KEY_JWT_TOKEN, cookies.get('jwt'));
    cookies.remove('jwt', { path: '/', domain: domain_front });
  }

  return localStorage.getItem(KEY_JWT_TOKEN);
};

export const GOOGLE_ANALYTICS_KEY = googleAnalyticsKey;
export const GOOGLE_OPTIMIZE_KEY = googleOptimizeKey;
export const GOOGLE_TAG_MANAGER_KEY = googleTagManagerKey;
export const SPECIAL_MEMBERSHIP_ID = specialMembershipId.toString();
export const SPECIAL_MEMBERSHIP_B_ID = specialMembershipBId.toString();
export const STRIPE_PUBLIC_KEY = stripe_public_key;
export const VIMEO_TOKEN = '6b369d9a439fc52332ade078779bf7e6';
export const TIMEOUT_ERROR_NETWORK = timeoutErrorNetwork;
export const TIMEOUT_ERROR_NETWORK_FINAL = timeoutErrorNetworkFinal;
export const ELASTICSEARCH_CONFIG = elasticSearchConfig;
export const HOTJAR_CONFIG = hotjarConfig;
export const GA4_KEY = ga4Key;
