import React from 'react';
import { useHistory } from 'react-router-dom';

import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';
import { SearchProvider, SearchBox, WithSearch, ErrorBoundary } from '@elastic/react-search-ui';
import * as Sentry from '@sentry/browser';
import get from 'lodash/get';

import { checkElasticSearchBrowserCompatibility } from 'helpers/permissions';

import AutocompleteResultsMobile from './AutocompleteResultsMobile';

import {
  buildAutocompleteQueryConfig,
  buildFacetConfigFromConfig,
  buildSearchOptionsFromConfig,
  getConfig,
} from './config/config-helper';

import '@elastic/react-search-ui-views/lib/styles/styles.css';
import { CustomInput } from './CustomInput';
import './index.css';

const { hostIdentifier, searchKey, endpointBase, engineName } = getConfig();
const connector = new AppSearchAPIConnector({
  searchKey,
  engineName,
  hostIdentifier,
  endpointBase,
});
const config = {
  searchQuery: {
    facets: buildFacetConfigFromConfig(),
    ...buildSearchOptionsFromConfig(),
  },
  autocompleteQuery: buildAutocompleteQueryConfig(),
  apiConnector: connector,
  trackUrlState: false,
};

export const ElasticSearchBarMobile = () => {
  if (!checkElasticSearchBrowserCompatibility()) return null;

  return (
    <SearchProvider
      config={{
        autocompleteQuery: {
          results: {
            result_fields: {
              title: { raw: {}, snippet: { size: 100, fallback: true } },
              url: { raw: {} },
            },
          },
        },
        apiConnector: connector,
        trackUrlState: false,
      }}
    >
      <WithSearch mapContextToProps={() => ({})}>
        {() => {
          return (
            <div className="searchbar-container" id="topbar-searchbox-link">
              <div className="input-container">
                <ErrorBoundary
                  view={({ error, children }) => {
                    if (!error) return children;

                    if (process.env.REACT_APP_ENV === 'production') {
                      Sentry.captureException(error);
                    }
                    return null;
                  }}
                >
                  <SearchBox
                    inputView={({ getAutocomplete, getInputProps, getButtonProps }) => (
                      <CustomInput
                        getAutocomplete={getAutocomplete}
                        getInputProps={getInputProps}
                        getButtonProps={getButtonProps}
                      />
                    )}
                    shouldClearFilters
                    autocompleteMinimumCharacters={3}
                    autocompleteResults={{
                      titleField: 'title',
                      urlField: 'url',
                    }}
                    autocompleteView={({ autocompletedResults }) => {
                      return (
                        <AutocompleteResultsMobile autocompletedResults={autocompletedResults} />
                      );
                    }}
                  />
                </ErrorBoundary>
              </div>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
};

const ElasticSearchBar = () => {
  if (!checkElasticSearchBrowserCompatibility()) return null;

  const history = useHistory();

  const onEnterPressed = (e) => {
    const value = get(e, 'target.value', null);
    if (e.key === 'Enter' && value && get(history, 'push')) {
      history.push(`/search?s=${encodeURI(value)}`);
    }
  };

  return (
    <SearchProvider config={config}>
      <WithSearch mapContextToProps={() => ({})}>
        {() => {
          return (
            <div className="searchbar-container" id="topbar-searchbox-link">
              <div className="input-container">
                <ErrorBoundary
                  view={({ error, children }) => {
                    if (!error) return children;

                    if (process.env.REACT_APP_ENV === 'production') {
                      Sentry.captureException(error);
                    }
                    return null;
                  }}
                >
                  <SearchBox
                    inputView={({ getAutocomplete, getInputProps, getButtonProps }) => (
                      <CustomInput
                        getAutocomplete={getAutocomplete}
                        getInputProps={getInputProps}
                        getButtonProps={getButtonProps}
                        onEnterPressed={onEnterPressed}
                      />
                    )}
                    shouldClearFilters
                    autocompleteMinimumCharacters={3}
                    autocompleteResults={{
                      titleField: 'title',
                      urlField: 'url',
                    }}
                  />
                </ErrorBoundary>
              </div>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
};

export default ElasticSearchBar;
