import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import _ from 'lodash';

import { clearCache } from 'helpers/cache';
import { history, RGAEvent } from 'helpers/history';
import { removeTryParam, getAbsoluteRedirect } from 'helpers/url';
import { getTime } from 'helpers/date';
import { TIMEOUT_ERROR_NETWORK, TIMEOUT_ERROR_NETWORK_FINAL } from 'config';
import { Analytics } from 'facades/Analytics';

import Button from 'components/Button';
import FullWidth from 'layouts/FullWidth';
import LoadNotRespondingImg from 'resources/img/not-responding.svg';
import loadClockImg from 'resources/img/clock.svg';

import './error-network.css';

const ErrorNetwork = ({ location, final }) => {
  const [timer, setTimer] = useState(final ? TIMEOUT_ERROR_NETWORK_FINAL : TIMEOUT_ERROR_NETWORK);
  const [redirect, setRedirect] = useState('');
  const [tryParam, setTryParam] = useState(1);

  useEffect(() => {
    const search = _.get(location, 'search');
    if (location && search) {
      const tryAmount = new URLSearchParams(search).get('try') || 1;
      const getRedirect = removeTryParam(getAbsoluteRedirect(search));

      if (tryAmount > 3) {
        localStorage.removeItem('try-error');
        history.push(`error-network-final?redirect=${getRedirect}`);
      }

      setTryParam(tryAmount);
      setRedirect(getRedirect);
    }
  }, [location]);

  useEffect(() => {
    if (timer && timer > 0) setTimeout(() => setTimer(timer - 1), 1000);
  }, [timer]);

  const goToPage = (path) => {
    if (final) {
      localStorage.removeItem('try-error');
    } else {
      localStorage.setItem('try-error', parseInt(tryParam) + 1);
      RGAEvent('error-network', 'try again', `number of attemps: ${tryParam}`);
      Analytics.sendEvent('error-network', {
        action: 'try again',
        label: `number of attemps: ${tryParam}`,
      });
    }
    clearCache();
    window.location.replace(path);
  };

  const Status = () => {
    const options = {
      default: {
        imageAlt: 'clock',
        imageSrc: loadClockImg,
        subtitle: 'You can try again in',
        timerLabel: timer > 60 ? `${getTime(timer)} minutes` : `${timer} seconds`,
        title: tryParam === '2' ? 'Sorry, our server is still down' : 'Sorry, our server is down',
      },
      final: {
        imageAlt: 'not-responding',
        imageSrc: LoadNotRespondingImg,
        subtitle: 'Please try again in',
        timerLabel: timer > 60 ? `${getTime(timer)} minutes` : `${timer} seconds`,
        title: 'Sorry, our server is not responding',
      },
    };

    const { imageSrc, imageAlt, title, subtitle, timerLabel } = final
      ? options.final
      : options.default;

    return (
      <>
        <img src={imageSrc} alt={imageAlt} className="clock" />
        <div className="status-text"> {title}</div>
        <div className="try-text">
          {subtitle} <span className="timer">{timerLabel}</span>
        </div>
      </>
    );
  };

  return (
    <FullWidth topbar className="error-network full-height">
      <FullWidth.Content className="hero gradient bg-grey">
        <Grid className="container-max-width center-content" fluid>
          <Row center="xs">
            <Col xs={12} md={8} className="content-container">
              <div className={`content ${final ? 'final' : ''}`}>
                <div>
                  <Status />
                  {redirect ? (
                    <Button fill onClick={() => goToPage(redirect)} disabled={timer !== 0}>
                      Try Again
                    </Button>
                  ) : (
                    <Button fill to="/" disabled={timer !== 0}>
                      Go to the homepage
                    </Button>
                  )}
                </div>
                <div>
                  {final && (
                    <div className="support">
                      <div className="contact">
                        If you think is an error, please contact our support team at
                      </div>
                      <a
                        className="violet color-hover-darken1"
                        href="mailto:support@psychopharmacologyinstitute.com"
                      >
                        support@psychopharmacologyinstitute.com.
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Grid>
      </FullWidth.Content>
    </FullWidth>
  );
};

ErrorNetwork.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
  final: PropTypes.bool,
};

ErrorNetwork.defaultProps = {
  final: false,
};

export default ErrorNetwork;
