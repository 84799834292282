import React from 'react';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';

import './errorPage.css';
import Button from 'components/Button';
import FullWidth from 'layouts/FullWidth';
import { Analytics } from 'facades/Analytics';

const ErrorPage = () => {
  const sendGA4Event = () => {
    Analytics.sendEvent('search_page', {
      previous_page: '/activity-removed',
    });
  };
  return (
    <FullWidth topbar className="error-page full-height">
      <FullWidth.Content className="hero gradient">
        <Grid className="container-max-width" fluid>
          <Row center="xs">
            <Col xs={12} md={8} className="content">
              <h6>Publication removed by the editors </h6>
              <h2>
                This CME Activity <br /> Has Been Removed
              </h2>
              <div className="message">
                <p>Dear Member,</p>
                <p>
                  We at Psychopharmacology Institute strive to offer you the highest quality of
                  evidence-based education.
                </p>
                <p>
                  Unfortunately, after publishing this CME activity, independent reviewers noted
                  controversial claims that we believe don&#39;t represent evidence-based views. We
                  have decided to remove this publication from our website.
                </p>
                <p>
                  If you already completed this CME activity, you can find the associated
                  certificate on your{' '}
                  <a href="https://psychopharmacologyinstitute.com/dashboard/certificates">
                    Certificates
                  </a>{' '}
                  page.
                </p>
                <p>
                  We are very sorry for any inconvenience this may have caused you. In the meantime,
                  you can browse more evidence-based content in our library.
                </p>
                <p className="editors">
                  <b>The Editors</b>
                </p>
                <p className="policy">This policy is effective as of 1 October 2018.</p>
              </div>
              <Button onClick={sendGA4Event} fill to="/search">
                Browse the library
              </Button>
            </Col>
          </Row>
        </Grid>
      </FullWidth.Content>
    </FullWidth>
  );
};

export default ErrorPage;
