import { PpAPI } from 'api';

import { I18N_FETCHING, I18N_FETCHED } from 'reducers/i18n';

const fetchingTranslations = () => ({
  type: I18N_FETCHING,
});
export const fetchedTranslations = (data) => ({
  type: I18N_FETCHED,
  data,
});

export const fetchTranslations =
  (lang = 'en') =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(fetchingTranslations());

      PpAPI.get(`/api/i18n/${lang}`)
        .then((res) => {
          dispatch(fetchedTranslations(res.data));
          resolve(res.data);
        })
        .catch(reject);
    });
