import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const Segment = React.forwardRef((props, ref) => {
  const { size, color, className, id, scrollable, group } = props;

  let _className = [
    'segment',
    size,
    className,
    {
      scrollable: scrollable !== false,
      group: group !== false,
      [color]: color !== false,
    },
  ];

  _className = classNames(_className);
  return (
    <div ref={ref} id={id} className={_className}>
      {props.children}
    </div>
  );
});

Segment.propTypes = {
  size: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  scrollable: PropTypes.bool,
  group: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
};

Segment.defaultProps = {
  size: '',
  color: '',
  className: '',
  id: '',
  scrollable: false,
  group: false,
};

export default Segment;
