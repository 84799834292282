import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NavHashLink } from 'react-router-hash-link';
import classNames from 'classnames';

import Loading from 'components/Loading';

const Link = (props) => {
  const {
    ariaLabel,
    active,
    className,
    disabled,
    history,
    href,
    id,
    loading,
    navItem,
    onClick,
    preventDefault,
    rel,
    style,
    target,
    to,
    title,
  } = props;

  const _onClick = (e) => {
    if (disabled === true) {
      e.preventDefault();
      return;
    }

    if (_.isFunction(onClick)) {
      if (preventDefault && !href) {
        e.preventDefault();
      }
      if (onClick(e) === false) {
        e.preventDefault();
        return;
      }
    }

    if (!!to && !href) {
      history.push(to);
      e.preventDefault();
    }
  };

  const _className = classNames('link', className, {
    active: active !== false,
    'nav-item': navItem !== false,
    loading: loading !== false,
  });

  if (loading) {
    return (
      <div style={style} className={_className}>
        {props.children} <Loading active />
      </div>
    );
  }

  const _href = href || to;
  if (_href.search('#') === 0) {
    return (
      <NavHashLink
        title={title}
        style={style}
        id={id}
        smooth
        target={target}
        rel={rel}
        to={_href}
        href={_href}
        onClick={_onClick}
        className={`${_className} link`}
      >
        {props.children}
      </NavHashLink>
    );
  }

  return (
    <a
      title={title}
      style={style}
      id={id}
      target={target}
      rel={rel}
      to={to}
      href={href || to}
      onClick={_onClick}
      className={_className}
      aria-label={ariaLabel}
    >
      {props.children}
    </a>
  );
};

Link.propTypes = {
  ariaLabel: PropTypes.string,
  to: PropTypes.string,
  title: PropTypes.string,
  href: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
  id: PropTypes.string,
  className: PropTypes.string,
  target: PropTypes.oneOf(['_blank', '_self', null]),
  rel: PropTypes.string,
  active: PropTypes.bool,
  navItem: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  preventDefault: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

Link.defaultProps = {
  ariaLabel: '',
  to: '',
  title: '',
  href: '',
  style: {},
  onClick: null,
  id: '',
  className: '',
  target: null,
  rel: '',
  active: false,
  navItem: false,
  disabled: false,
  loading: false,
  preventDefault: true,
};

export default withRouter(Link);
