import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router-dom';
import { isMobile, isMobileOnly } from 'react-device-detect';
import map from 'lodash/map';
import get from 'lodash/get';
import find from 'lodash/find';
import Cookies from 'universal-cookie';

import { checkElasticSearchBrowserCompatibility } from 'helpers/permissions';
import { Analytics } from 'facades/Analytics';

import Button from 'components/Button';
import Link from 'components/Link';
import Icon from 'components/Icon';
import Avatar from 'components/Avatar';
import MenuButton from 'components/MenuButton';
import SearchBar from 'components/ElasticSearchBar';
import SearchIcon from 'resources/img/search.svg';
import { openGlobalSearch } from 'actions/search';
import { logoutUrl, signinUrl, RGAEvent } from 'helpers/history';
import { toggleSidebar as toggleSidebarAction } from 'actions/sidebar';
import { getInfoMembership, isFreeMembership } from 'helpers/membership';
import { openModals } from 'helpers/modals';
import Skeleton from './Skeleton';

import './topbar.css';

const cookies = new Cookies();

const routesWithEmptyTopBar = ['/newsletter', '/signin'];

const routesWithCustomTopbar = [
  {
    route: '/register',
    components: [
      {
        component: 'button',
        props: {
          basic: true,
          label: 'Sign in',
          to: '/signin',
          onClick: () => {
            RGAEvent('Signin', 'register-free-now', 'register-page');
            Analytics.sendEvent('signin', {
              action: 'register-free-now',
              label: 'register-page',
            });
          },
        },
      },
    ],
  },
];

const routesWithoutSearchBox = [
  'cme-program',
  'newsletter',
  'calendar',
  'checkout',
  'sign-in',
  'register',
];

const routesWithMenuAndTitleTopbar = ['/test', '/results'];

const Topbar = (topBarProps) => {
  const { auth, location, inSidebar, title, toggleSidebar, history } = topBarProps;

  const toggleSidebarOnClick = (eventAction) => {
    if (eventAction) {
      Analytics.sendEvent('navbar', {
        action: eventAction,
      });
    }

    if (inSidebar) {
      toggleSidebar();
    }
  };

  const getLogo = () => {
    if (!isMobile || inSidebar) {
      return (
        <Link onClick={toggleSidebarOnClick} to="/" className="psycho_logo" ariaLabel="go to home">
          {!isMobile && <Icon name="logo" color="aqua" />}
          <Icon name="logo_text" />
        </Link>
      );
    }
    return (
      <Link onClick={toggleSidebarOnClick} to="/" className="psycho_logo" ariaLabel="go to home">
        <Icon name="logo" color="aqua" />
      </Link>
    );
  };

  const getUserMenuButton = () => {
    const menuButtonItems = [
      {
        label: 'My account',
        color: 'black',
        to: '/my-account',
        onClick: () => {
          history.push('/myaccount');
        },
      },
      ...(isFreeMembership()
        ? [
            {
              label: `What's in My Dashboard?`,
              color: 'black',
              onClick: () => {
                cookies.remove('userIdsWithVisitedCoachmark');
                if (history.location.pathname.includes('/dashboard')) {
                  window.location.reload();
                } else {
                  history.push('/dashboard');
                }
              },
            },
          ]
        : []),
      ...(auth.user.hasMembership && auth.user.canGetCredits
        ? [
            {
              label: 'My transcripts',
              color: 'black',
              to: '/dashboard/my-transcripts',
              onClick: () => {
                history.push('/dashboard/my-transcripts');
              },
            },
          ]
        : []),
      {
        label: 'Log out',
        color: 'black',
        to: logoutUrl(),
        onClick: () => {
          history.push(logoutUrl());
        },
      },
    ];

    const membershipLabel = auth.user.hasMembership ? `${auth.user.membership.name} Member` : null;

    return (
      <MenuButton items={menuButtonItems}>
        <Avatar
          id="topbar-my-account-link"
          link={false}
          type={inSidebar ? 'block' : 'inline'}
          size="s"
          author={{
            fullNameAffix: `${auth.user.firstName} ${auth.user.lastName}`,
            institute: membershipLabel,
          }}
          inverted={!inSidebar}
          className="avatar-user"
        />
      </MenuButton>
    );
  };

  const getBtns = (withElementId) => {
    if (auth.isLogged === null) {
      return <Skeleton />;
    }
    if (auth.isLogged) {
      return (
        <nav className="nav">
          {(!isMobile || inSidebar) && (
            <>
              <Link
                onClick={toggleSidebarOnClick}
                to="/dashboard"
                id={withElementId && 'topbar-dashboard-link'}
                active={location.pathname === '/dashboard'}
                ariaLabel="go to dashboard"
              >
                Dashboard
              </Link>
              <Link
                id={withElementId && 'topbar-library-link'}
                onClick={() => toggleSidebarOnClick('search')}
                to="/search"
                active={location.pathname === '/search'}
                ariaLabel="go to library"
              >
                Library
              </Link>
              {!auth.user.hasMembership && (
                <>
                  <Link
                    onClick={() => toggleSidebarOnClick('cme_program')}
                    id={withElementId && 'topbar-cme-program-link'}
                    to="/cme-program"
                    active={location.pathname === '/cme-program'}
                    ariaLabel="go to cme program"
                  >
                    CME Program
                  </Link>
                </>
              )}
              {auth.user.hasMembership && auth.user.canGetCredits && (
                <Link
                  onClick={toggleSidebarOnClick}
                  to="/dashboard/certificates"
                  active={location.pathname === '/dashboard/certificates'}
                  ariaLabel="go to my certificates"
                >
                  My Certificates
                </Link>
              )}
              <Link
                onClick={() => toggleSidebarOnClick('calendar')}
                to="/calendar"
                active={location.pathname === '/calendar'}
                ariaLabel="go to calendar"
              >
                Updates
              </Link>
              <div className="divider" />
            </>
          )}
          {!inSidebar && getUserMenuButton()}
          {!inSidebar && getInfoMembership(topBarProps)}
        </nav>
      );
    }

    const locationHasSectionPricingScroll = location.search.includes('section=pricing');
    return (
      <nav className="nav">
        {(!isMobile || inSidebar) && (
          <>
            <Link
              onClick={() => toggleSidebarOnClick('home')}
              to="/"
              active={location.pathname === '/'}
              ariaLabel="go to home"
            >
              Home
            </Link>
            <Link
              onClick={() => toggleSidebarOnClick('search')}
              to="/search"
              active={location.pathname === '/search'}
              ariaLabel="go to library"
            >
              Library
            </Link>
            <Link
              onClick={() => toggleSidebarOnClick('cme_program')}
              to="/cme-program"
              active={location.pathname === '/cme-program' && !locationHasSectionPricingScroll}
              ariaLabel="go to cme program"
            >
              CME Program
            </Link>
            <Link
              onClick={() => toggleSidebarOnClick('pricing')}
              to="/cme-program?section=pricing"
              active={location.pathname === '/cme-program' && locationHasSectionPricingScroll}
              ariaLabel="go to pricing"
            >
              Pricing
            </Link>
            <Link
              onClick={() => toggleSidebarOnClick('calendar')}
              to="/calendar"
              active={location.pathname === '/calendar'}
              ariaLabel="go to updates"
            >
              Updates
            </Link>
            <div className="divider" />
          </>
        )}
        <Link
          onClick={() => toggleSidebarOnClick('sign_in')}
          to={signinUrl(true)}
          className="signin-topbar"
          ariaLabel="go to sign in"
        >
          Sign in
        </Link>
        {!inSidebar && <span className="color-white darken2">/</span>}
        <Link
          onClick={() => toggleSidebarOnClick('register')}
          to="/register"
          className="register"
          ariaLabel="go to register"
        >
          Register
        </Link>
      </nav>
    );
  };

  const WithMenuAndTitleTopbar = ({ menu }) => (
    <>
      {!isMobileOnly && title && <div className="col title">{title}</div>}
      {(menu || isMobileOnly) && (
        <div className="col">
          <Link onClick={toggleSidebar} className="menu" ariaLabel="go to menu">
            <Icon name="menu" />
          </Link>
        </div>
      )}
      <div className="col">{getLogo()}</div>
      <div className="col full-space" />
      {!auth.isLogged ? (
        <div className="col">
          <Link
            onClick={toggleSidebarOnClick}
            to="/pricing"
            className="become-a-member"
            ariaLabel="go to pricing"
          >
            Become a Member
          </Link>
        </div>
      ) : (
        <>
          <div className="col full-space" />
          <div className="col">{getUserMenuButton()}</div>
        </>
      )}
    </>
  );

  WithMenuAndTitleTopbar.propTypes = {
    menu: PropTypes.bool,
  };

  WithMenuAndTitleTopbar.defaultProps = {
    menu: true,
  };

  const DefautlTopbar = () => {
    const routesWithoutSearchBar = ['cme-program', 'calendar'];
    const showSearchBar =
      !isMobile && !routesWithoutSearchBar.some((route) => location.pathname.includes(route));

    return (
      <div className="default-nav">
        <div className="col">
          {isMobile && (
            <Link onClick={toggleSidebar} className="menu" ariaLabel="go to menu">
              <Icon name="menu" />
            </Link>
          )}
        </div>
        <div className="col">{getLogo()}</div>
        <div className="col full-space searchbar">{showSearchBar && <SearchBar />}</div>
        <div className="col">{getBtns(true)}</div>
      </div>
    );
  };

  const EmptyTopbar = () => (
    <div className="empty-nav">
      <div className="col">{getLogo()}</div>
    </div>
  );

  const CustomTopbar = ({ components } = {}) => {
    const responsiveButtonProps = isMobileOnly
      ? { fill: false, style: { padding: 0, marginRight: 32 } }
      : { color: 'violet', fill: true };
    const MapComponents = ({ component, props }) => {
      const Internal = () => {
        switch (component) {
          case 'button':
            return (
              <Button {...props} {...responsiveButtonProps}>
                {get(props, 'label', '')}
              </Button>
            );
          default:
            return <></>;
        }
      };

      return (
        <div className="internal-component">
          <Internal />
        </div>
      );
    };

    MapComponents.propTypes = {
      component: PropTypes.string,
      props: PropTypes.object,
    };

    MapComponents.defaultProps = {
      component: '',
      props: {},
    };

    return (
      <div className="custom-nav">
        <EmptyTopbar />
        <div className="wrapper-components">
          {map(components, (item, index) => (
            <MapComponents {...item} key={index} />
          ))}
        </div>
      </div>
    );
  };

  CustomTopbar.propTypes = {
    components: PropTypes.object.isRequired,
  };

  const getContentTopBar = () => {
    if (routesWithEmptyTopBar.some((route) => location.pathname.includes(route))) {
      return <EmptyTopbar />;
    }

    const customComponents = find(routesWithCustomTopbar, ({ route }) =>
      location.pathname.includes(route)
    );
    if (customComponents) {
      return <CustomTopbar components={get(customComponents, 'components', {})} />;
    }

    if (routesWithMenuAndTitleTopbar.some((route) => location.pathname.endsWith(route))) {
      return <WithMenuAndTitleTopbar />;
    }

    return <DefautlTopbar />;
  };

  if (inSidebar) {
    const hideSearchBar =
      routesWithoutSearchBox.some((route) => location.pathname.includes(route)) ||
      !checkElasticSearchBrowserCompatibility();
    const handleOnInputFocus = () => {
      toggleSidebarOnClick();
      openModals(null, 'searchMobile', null, false, false, null);
    };

    return (
      <div className="sidebarnav">
        <div className="col">{getLogo()}</div>
        {!hideSearchBar && (
          <div className="col input-container">
            <img src={SearchIcon} className="input-img" alt="search-icon" />
            <div onClick={handleOnInputFocus}>
              <input type="text" placeholder="Search psychopharmacology topics" readOnly />
            </div>
          </div>
        )}
        <div className="col">{getBtns()}</div>
      </div>
    );
  }

  return (
    <div id="topbar" className="topbar">
      {getContentTopBar()}
    </div>
  );
};

Topbar.propTypes = {
  auth: PropTypes.shape({
    isLogged: PropTypes.bool,
    user: PropTypes.shape({
      canGetCredits: PropTypes.bool,
      hasMembership: PropTypes.bool,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      membership: PropTypes.shape({
        name: PropTypes.string,
      }),
    }),
  }).isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

export default withRouter(
  connect(
    (store) => ({
      auth: store.auth,
      topbar: store.topbar,
      memberships: store.memberships,
    }),
    (dispatch) => ({
      openGlobalSearch: bindActionCreators(openGlobalSearch, dispatch),
      toggleSidebar: bindActionCreators(toggleSidebarAction, dispatch),
    })
  )(Topbar)
);
