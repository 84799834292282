export const interviewers = () => {
  return [
    {
      id: 1,
      avatar: '/flavio.jpeg',
      name: 'Nicolás Guglielmi',
      institute: 'Universidad Nacional de Cuyo',
      position: 'CTO Cicunc',
    },
    {
      id: 2,
      avatar: '/flavio.jpeg',
      name: 'Ezequiel Salatino',
      institute: 'Universidad Nacional de Cuyo',
      position: 'CTO Cicunc',
    },
    {
      id: 3,
      avatar: '/flavio.jpeg',
      name: 'Flavio Guzman',
      institute: 'Universidad Nacional de Cuyo',
      position: 'CTO Cicunc',
    },
  ];
};
