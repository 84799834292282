export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const TOGGLE_PUBLICATION_SIDEBAR = 'TOGGLE_PUBLICATION_SIDEBAR';

const sidebarInit = {
  mainSidebar: false,
  publicationSidebar: false,
};

export const sidebar = (sidebarInfo = sidebarInit, action) => {
  switch (action.type) {
    case TOGGLE_SIDEBAR:
      return {
        ...sidebarInfo,
        mainSidebar: !sidebarInfo.mainSidebar,
      };
    case TOGGLE_PUBLICATION_SIDEBAR:
      return {
        ...sidebarInfo,
        publicationSidebar: !sidebarInfo.publicationSidebar,
      };
    default:
      return sidebarInfo;
  }
};
