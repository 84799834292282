import axios from 'axios';
import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';

import { FRONT_ROOT, ZENDESK_API_URL } from 'config';
import Button from 'components/Button';
import Segment from 'components/Segment';
import FullWidth from 'layouts/FullWidth';
import Footer from 'components/Footer';

import './contactus.css';

const ContactUs = () => {
  const [state, setState] = useState({
    error: false,
    sent: false,
    sending: false,
  });

  useEffect(() => {
    const element = document.getElementById('contact-us');
    if (element) {
      element.scrollIntoView({ block: 'start' });
    }
  }, []);

  const sendMessage = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setState({
      error: false,
      sent: false,
      sending: true,
    });

    const attributes = _.reduce(
      e.target.elements,
      (red, el) => {
        if (!el.name) return red;
        return {
          ...red,
          [el.name]: el.value,
        };
      },
      {}
    );

    const data = {
      request: {
        requester: {
          name: attributes.fullname,
          email: attributes.email,
        },
        subject: `Contact from ${FRONT_ROOT}`,
        comment: { body: attributes.message },
      },
    };

    const request = axios.create({
      baseURL: ZENDESK_API_URL,
      timeout: 30000,
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
      },
    });

    request
      .post('/requests', data)
      .then(() => {
        setState((prevState) => ({
          ...prevState,
          sent: true,
          sending: false,
        }));
      })
      .catch(() => {
        setState((prevState) => ({
          ...prevState,
          error: true,
          sending: false,
        }));
      });

    return false;
  };
  const { sent, error, sending } = state;
  return (
    <FullWidth topbar id="contact-us">
      <FullWidth.Content className="bg-color-white bg-lighten1">
        <Grid className="container-max-width">
          <Row>
            <Col xs={12} md={6} mdOffset={1}>
              <Segment>
                <h1>Contact Us</h1>
                {error && <p>Error when sending the message</p>}
                {sent ? (
                  <>
                    <h3>Thank you for contact us!</h3>
                    <p>We will contact you very soon!</p>
                    <Button
                      link
                      color="violet"
                      onClick={() =>
                        setState((prevState) => ({
                          ...prevState,
                          sent: false,
                        }))
                      }
                    >
                      Send another message
                    </Button>
                  </>
                ) : (
                  <>
                    <p>
                      Please fill out the form and we will be in touch within 48 business hours.
                      <br />
                      Or email us at{' '}
                      <a href="mailto:support@psychopharmacologyinstitute.com">
                        support@psychopharmacologyinstitute.com
                      </a>
                    </p>
                    <Segment className="s padded-horizontal form">
                      <form onSubmit={sendMessage}>
                        <label>
                          First & Last name *<br />
                          <input
                            disabled={sending}
                            className="input"
                            name="fullname"
                            type="text"
                            required
                          />
                        </label>
                        <label>
                          Email *<br />
                          <input
                            disabled={sending}
                            className="input"
                            name="email"
                            type="email"
                            required
                          />
                        </label>
                        <label>
                          Message *<br />
                          <textarea disabled={sending} className="input" name="message" required />
                        </label>
                        <Button disabled={sending} loading={state.sending} fill>
                          Send
                        </Button>
                      </form>
                    </Segment>
                  </>
                )}
              </Segment>
            </Col>
          </Row>
        </Grid>
        <Footer />
      </FullWidth.Content>
    </FullWidth>
  );
};

export default ContactUs;
