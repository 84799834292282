import _ from 'lodash';

import { PpAPI } from 'api';
import { MEMBERSHIPS_FETCHED, MEMBERSHIPS_FETCHING, RESET_MEMBERSHIPS } from 'reducers/memberships';
import { deserializeGeneric, deserializeRelationship } from 'actions/relationships';

const deserializePermission = (item) => item.attributes.permission;

export const deserializeMembership = (item, included) => ({
  ...deserializeGeneric(item),
  permissions: deserializeRelationship(
    item.relationships.permissions,
    included,
    deserializePermission
  ),
});

const fetchedMemberships = (memberships) => ({
  type: MEMBERSHIPS_FETCHED,
  memberships,
});

const fetchingMemberships = () => ({
  type: MEMBERSHIPS_FETCHING,
});

export const resetMemberships = () => ({
  type: RESET_MEMBERSHIPS,
});

export const fetchMemberships =
  (withmine = false, referredEmail = null) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(fetchingMemberships());

      let url = `/api/memberships/${withmine ? 'withmine/' : ''}?include=permissions&page[size]=0`;

      if (referredEmail) {
        url += `&referred_email=${encodeURIComponent(referredEmail)}`;
      }

      PpAPI.get(url)
        .then((res) => {
          const memberships = _.map(res.data.data, (item) =>
            deserializeMembership(item, res.data.included)
          );
          resolve(memberships);
          dispatch(fetchedMemberships(memberships));
        })
        .catch((err) => {
          reject(err);
        });
    });

export const fetchMembershipsB =
  (withmine = false, referredEmail = null) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(fetchingMemberships());

      let url = `/api/memberships/${
        withmine ? 'withmine/' : ''
      }?include=permissions&page[size]=0&membershipsb=${true}`;

      if (referredEmail) {
        url += `&referred_email=${encodeURIComponent(referredEmail)}`;
      }

      PpAPI.get(url)
        .then((res) => {
          const memberships = _.map(res.data.data, (item) =>
            deserializeMembership(item, res.data.included)
          );
          resolve(memberships);
          dispatch(fetchedMemberships(memberships));
        })
        .catch((err) => {
          reject(err);
        });
    });
