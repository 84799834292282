export const CERTIFICATES_FETCHING = 'CERTIFICATES_FETCHING';
export const CERTIFICATES_FETCHED = 'CERTIFICATES_FETCHED';

const certificatesInit = {
  data: [],
  count: 0,
  isFetching: false,
};

export const certificates = (certificatesInfo = certificatesInit, action) => {
  switch (action.type) {
    case CERTIFICATES_FETCHED:
      return {
        data: action.certificates,
        count: action.count,
        isFetching: false,
      };

    case CERTIFICATES_FETCHING:
      return {
        data: [],
        count: 0,
        isFetching: true,
      };

    default:
      return certificatesInfo;
  }
};
