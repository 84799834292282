/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Control, Form, actions } from 'react-redux-form';
import classNames from 'classnames';

import Button from 'components/Button';
import FullWidth from 'layouts/FullWidth';
import Link from 'components/Link';
import Segment from 'components/Segment';

import { register as registerAction } from 'actions/auth';

import { validPasswordLength, validRepeatPassword, validEmail, errorMessages } from 'helpers/forms';
import { goToRedirectOrGoTo } from 'helpers/history';

import checkImg from 'resources/img/check.svg';

import './register.css';

const Register = (props) => {
  const { auth, forms, register } = props;
  const [loading, setLoading] = useState(false);
  const [formError, setFormError] = useState(false);
  const accountFeatures = [
    'Practical pearls, free of industry bias.',
    'Psychopharm tips, twice a week, straight to your email inbox.',
    'Free access to 4 sections of each Video Lecture and 2 Quick Take journal article reviews.',
    'Monthly psychopharmacology newsletter.',
    'Keep track of what you have seen in your private dashboard.',
  ];

  useEffect(() => {
    props.changeField.change('register.error', false);
    props.changeField.change('register.username', '');
    props.changeField.change('register.password', '');
    props.changeField.change('register.firstName', '');
    props.changeField.change('register.lastName', '');
    props.changeField.change('register.repeatPassword', '');
  }, []);

  useEffect(() => {
    if (formError) {
      setFormError(false);
    }
  }, [forms.register]);

  const validateForm = () => {
    return (
      validEmail(forms.register.username) &&
      validPasswordLength(forms.register.password) &&
      validRepeatPassword(forms.register.password, forms.register.repeatPassword) &&
      forms.register.firstName.length > 0 &&
      forms.register.lastName.length > 0
    );
  };

  const onSubmit = () => {
    if (!validateForm()) {
      setFormError(true);
    } else {
      setLoading(true);
      register(forms.register.username, forms.register.password, forms.register.repeatPassword, {
        firstName: forms.register.firstName,
        lastName: forms.register.lastName,
      })
        .then(() => {
          setLoading(false);
          goToRedirectOrGoTo();
        })
        .catch((err) => {
          setLoading(false);
          props.changeField.change(
            'register.error',
            get(err, 'response.data.errors[0].detail', 'Error')
          );
        });
    }
  };

  return (
    <FullWidth topbar id="register" className="full-height">
      <FullWidth.Content className="bg-color-white">
        <div id="left" className="equal-width">
          <Segment id="features">
            <h6>Prescriber? Sign Up for a Free Account.</h6>
            <h6 className="included-text">What’s included?</h6>
            <div className="account-features">
              {map(accountFeatures, (feature, index) => {
                return (
                  <div key={index} className="item">
                    <img src={checkImg} alt="check-img" className="check-img" />
                    <span>{feature}</span>
                  </div>
                );
              })}
            </div>
          </Segment>
        </div>
        <div id="right" className="bg-color-white bg-lighten1 equal-width">
          <Segment id="form">
            <h4>Create a free account</h4>
            <Form model="register">
              <Control.text
                model=".username"
                placeholder="Email"
                className={classNames({
                  error: formError && !validEmail(forms.register.username),
                })}
                validators={{
                  required: (val) => val && val.length,
                }}
              />
              {formError && !validEmail(forms.register.username) && (
                <div className="input-legend error">{errorMessages.validEmail}</div>
              )}
              <Control.text
                model=".password"
                placeholder="Password"
                type="password"
                className={classNames({
                  error: formError && !validPasswordLength(forms.register.password),
                })}
              />
              {formError && !validPasswordLength(forms.register.password) && (
                <div className="input-legend error">{errorMessages.validPasswordLength}</div>
              )}
              <Control.text
                model=".repeatPassword"
                placeholder="Repeat Password"
                type="password"
                className={classNames({
                  error:
                    formError &&
                    !validRepeatPassword(forms.register.password, forms.register.repeatPassword),
                })}
              />
              {formError &&
                !validRepeatPassword(forms.register.password, forms.register.repeatPassword) && (
                  <div className="input-legend error">{errorMessages.validRepeatPassword}</div>
                )}
              <div className="user_info">
                <Control.text
                  model=".firstName"
                  placeholder="First Name"
                  className={classNames({
                    error: formError && forms.register.firstName.length === 0,
                  })}
                  validators={{
                    required: (val) => val && val.length,
                  }}
                />
                {formError && forms.register.firstName.length === 0 && (
                  <div className="input-legend error">First Name is required.</div>
                )}
                <Control.text
                  model=".lastName"
                  placeholder="Last Name"
                  className={classNames({
                    error: formError && forms.register.lastName.length === 0,
                  })}
                  validators={{
                    required: (val) => val && val.length,
                  }}
                />
                {formError && forms.register.lastName.length === 0 && (
                  <div className="input-legend error">Last Name is required.</div>
                )}
              </div>
              {forms.register.error !== false && (
                <div className="form-legend error">{forms.register.error}</div>
              )}
              <Segment size="s" className="create-free-account">
                <Button
                  fill
                  color="violet"
                  disabled={auth.isFetching === true || loading}
                  loading={loading}
                  onClick={onSubmit}
                >
                  Create a free account
                </Button>
                <div className="learn-more">
                  Don&#39;t have a membership yet?{' '}
                  <Link color="violet" to="/cme-program" link main>
                    Learn more
                  </Link>
                </div>
              </Segment>
            </Form>
          </Segment>
        </div>
      </FullWidth.Content>
    </FullWidth>
  );
};

Register.propTypes = {
  auth: PropTypes.shape({
    isFetching: PropTypes.bool,
  }).isRequired,
  forms: PropTypes.shape({
    register: PropTypes.shape({
      username: PropTypes.string,
      password: PropTypes.string,
      repeatPassword: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      error: PropTypes.bool,
    }),
  }).isRequired,
  register: PropTypes.func.isRequired,
  changeField: PropTypes.object.isRequired,
};

export default withRouter(
  connect(
    (store) => ({
      auth: store.auth,
      forms: store.forms,
    }),
    (dispatch) => ({
      register: bindActionCreators(registerAction, dispatch),
      changeField: bindActionCreators(actions, dispatch),
    })
  )(Register)
);
