import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { openModals } from 'helpers/modals';
import Icon from 'components/Icon';
import Button from 'components/Button';

import './index.css';

const ReferralBar = ({ updateReferralsList, auth }) => {
  const [show, setShowBar] = useState(!localStorage.getItem('hideReferralBar'));

  const closeBar = () => {
    setShowBar(false);
    localStorage.setItem('hideReferralBar', 'true');
  };

  if (!auth.isLogged || !auth.user.isMembershipValid || !show) {
    return null;
  }
  return (
    <div id="referral-button-bar">
      <button
        type="button"
        className="open-bar-button"
        onClick={() =>
          openModals(
            null,
            'referModal',
            null,
            null,
            null,
            updateReferralsList ? { updateReferralsList: true } : false
          )
        }
      >
        Receive a $50 gift card
      </button>
      <span className="message">
        For each new referral who subscribes to a paid membership, you get a $50 gift card!
      </span>
      <Button className="close-button" color="white" onClick={closeBar}>
        <Icon name="delete" />
      </Button>
    </div>
  );
};

ReferralBar.propTypes = {
  updateReferralsList: PropTypes.bool,
  auth: PropTypes.shape({
    isLogged: PropTypes.bool,
    user: PropTypes.shape({
      isMembershipValid: PropTypes.bool,
    }),
  }).isRequired,
};

ReferralBar.defaultProps = {
  updateReferralsList: false,
};
export default ReferralBar;
