import _ from 'lodash';

import { TEST_RESULTS_FETCHED, TEST_RESULTS_SET_ANSWER } from 'reducers/testResults';

export const PUBLICATIONS_FETCHING = 'PUBLICATIONS_FETCHING';
export const PUBLICATIONS_FETCHED = 'PUBLICATIONS_FETCHED';
export const PUBLICATION_FETCHING = 'PUBLICATION_FETCHING';
export const PUBLICATION_FETCHED = 'PUBLICATION_FETCHED';
export const PODCASTSPLAYER_FETCHED = 'PODCASTSPLAYER_FETCHED';

export const PUBLICATION_COMPLETE = 'PUBLICATION_COMPLETE';
export const PUBLICATION_RETAKE_TEST = 'PUBLICATION_RETAKE_TEST';
export const PUBLICATION_RESET = 'PUBLICATION_RESET';

export const CME = 'CME';
export const SA = 'SA';

export const PUBLICATION_VIDEO = 'PUBLICATION_VIDEO';
export const PUBLICATION_INTERVIEW = 'PUBLICATION_INTERVIEW';
export const PUBLICATION_QUICK_TAKE = 'PUBLICATION_QUICK_TAKE';
export const PUBLICATION_CAP_ST = 'PUBLICATION_CAP_ST';

export const SELF_ASSESSMENT = 'SELF_ASSESSMENT';
export const OPEN_ACCESS = 'OPEN_ACCESS';
export const BRAIN_GUIDE = 'BRAIN_GUIDE';
export const PODCAST = 'PODCAST';
export const WEBINAR = 'WEBINAR';

export const PUBLICATION_TYPE_WITH_CREDITS = [
  'PUBLICATION_VIDEO',
  'PUBLICATION_INTERVIEW',
  'PUBLICATION_QUICK_TAKE',
  'SELF_ASSESSMENT',
  'BRAIN_GUIDE',
  'PUBLICATION_CAP_ST',
];

export const PUBLICATION_TYPE_LABELS_MAP = {
  [PUBLICATION_VIDEO]: 'Video Lecture',
  [PUBLICATION_INTERVIEW]: 'Expert Consultation',
  [PUBLICATION_QUICK_TAKE]: 'Quick Take',
  [PUBLICATION_CAP_ST]: 'CAP Smart Take',
  [SELF_ASSESSMENT]: 'Self Assessment',
  [OPEN_ACCESS]: 'Open Access',
  [BRAIN_GUIDE]: 'Brain Guide',
  [PODCAST]: 'Podcast',
};

export const PUBLICATION_TYPE_LABELS_MAP_PLURAL = {
  [PUBLICATION_VIDEO]: 'Video Lectures',
  [PUBLICATION_INTERVIEW]: 'Expert Consultations',
  [PUBLICATION_QUICK_TAKE]: 'Quick Takes',
  [PUBLICATION_CAP_ST]: 'CAP Smart Takes',
  [SELF_ASSESSMENT]: 'Self Assessment',
  [OPEN_ACCESS]: 'Open Access',
  [BRAIN_GUIDE]: 'Brain Guides',
  [PODCAST]: 'Podcast',
};

const publicationsInit = {
  data: [],
  podcastsPlayer: [],
  pagination: {
    page: 1,
    pages: 0,
    count: 0,
  },
  isFetching: false,
};

export const publications = (publicationsInfo = publicationsInit, action) => {
  switch (action.type) {
    case PUBLICATIONS_FETCHED:
      return {
        data: action.publications,
        pagination: action.pagination,
        isFetching: false,
      };

    case PODCASTSPLAYER_FETCHED:
      return {
        podcastsPlayer: action,
      };

    case PUBLICATIONS_FETCHING:
      return {
        ...publicationsInfo,
        isFetching: true,
        data: [],
      };

    case PUBLICATION_COMPLETE:
    case PUBLICATION_RETAKE_TEST:
      return {
        ...publicationsInfo,
        data: _.map(publicationsInfo.data, (publication) => {
          if (publication.id === action.pId) {
            return {
              ...publication,
              completed: action.type !== PUBLICATION_RETAKE_TEST,
            };
          }
          return publication;
        }),
      };

    default:
      return publicationsInfo;
  }
};

const publicationInit = {
  data: {},
  isFetching: false,
};

export const publication = (publicationInfo = publicationInit, action) => {
  switch (action.type) {
    case TEST_RESULTS_SET_ANSWER:
      return {
        ...publicationInfo,
        data: {
          ...publicationInfo.data,
          questions: _.map(publicationInfo.data.questions, (question) => {
            if (question.id === action.qId) {
              return {
                ...question,
                answerChecked: action.aId,
              };
            }
            return question;
          }),
        },
      };

    case TEST_RESULTS_FETCHED: {
      const last_results = _.find(action.testResults, { status: 'APPROVED' });

      let results = false;
      if (_.has(last_results, 'results')) {
        results = last_results.results;
      } else {
        return publicationInfo;
      }

      return {
        ...publicationInfo,
        data: {
          ...publicationInfo.data,
          questions: _.map(publicationInfo.data.questions, (question) => {
            const answerChecked = _.find(results, { question_id: question.id });
            if (answerChecked) {
              return {
                ...question,
                answerChecked: answerChecked.answer_id,
              };
            }
            return question;
          }),
        },
      };
    }

    case PUBLICATION_FETCHED:
      return {
        data: action.publication,
        isFetching: false,
      };

    case PUBLICATION_FETCHING:
      return {
        ...publicationInfo,
        isFetching: true,
      };

    case PUBLICATION_COMPLETE:
    case PUBLICATION_RETAKE_TEST:
      return {
        ...publicationInfo,
        data: {
          ...publicationInfo.data,
          completed: action.type !== PUBLICATION_RETAKE_TEST,
        },
      };
    case PUBLICATION_RESET:
      return publicationInit;

    default:
      return publicationInfo;
  }
};
