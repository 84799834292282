import store from 'store';

import { MODALS_CLOSE, MODALS_SHOW, MODALS_UPDATE, MODALS_RESTORE } from 'reducers/modals';

export const _closeModal = (modalId) => ({
  type: MODALS_CLOSE,
  modalId,
});

export const _showModal = (modal) => ({
  type: MODALS_SHOW,
  modal,
});

export const _updateModal = (id, valuesToUpdate) => ({
  type: MODALS_UPDATE,
  id,
  valuesToUpdate,
});

export const _restoreModal = () => ({
  type: MODALS_RESTORE,
});

export const showModal = (data) => store.dispatch(_showModal(data));

export const closeModal = (data) => store.dispatch(_closeModal(data));

export const updateModal = (data) => store.dispatch(_updateModal(data));

export const restoreModal = () => store.dispatch(_restoreModal());
