export const CHANGE_TOPBAR_PUBLICATION = 'CHANGE_TOPBAR_PUBLICATION';
export const DEFAULT_TOPBAR = 'DEFAULT_TOPBAR';

const topbarDefault = {
  publication: false,
};

export const topbar = (topbarInfo = topbarDefault, action) => {
  switch (action.type) {
    case CHANGE_TOPBAR_PUBLICATION:
      return {
        ...topbarInfo,
        publication: action.publication,
      };
    case DEFAULT_TOPBAR:
      return topbarDefault;
    default:
      return topbarInfo;
  }
};
