import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import subscribeNewslettersAction from 'actions/newsletter';
import { validEmail } from 'helpers/forms';
import Input from 'components/Input';
import Icon from 'components/Icon';
import Loading from 'components/Loading';

class NewsLettersForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      success: false,
      errorMessage: null,
    };
  }

  subscribe = (e) => {
    e.preventDefault();
    const {
      typeData: { id, type },
    } = this.props;
    if (validEmail(this.state.email)) {
      const { email } = this.state;
      this.setState({ email: '' });
      const data = {
        email,
        [`${type}_id`]: id,
      };
      this.props
        .subscribeNewsletters(data, true, this.props.label)
        .then((res) => {
          if (res.data && res.data.data) {
            this.setState({ success: true });
            this.props.onSuccess();
          }
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.errors) {
            const { message } = error.response.data.errors;
            this.setState({ errorMessage: message });
          }
        });
    }
  };

  render() {
    const {
      title,
      notModal,
      newsletter: { isSaving },
    } = this.props;
    const { email } = this.state;
    const isValidEmail = validEmail(email);
    let content = this.state.success ? (
      <div className="success-container">
        <Icon
          border
          size="30"
          color="white"
          name="check"
          style={{
            borderColor: '#01BA92',
            backgroundColor: '#01BA92',
            marginBottom: '0.5em',
          }}
        />
        <h4>Success!</h4>
        <p>Check your inbox, we sent you all the materials there.</p>
        {!notModal && (
          <button
            type="button"
            className="button default violet link main level-2"
            onClick={() => this.props.onClose()}
          >
            Continue in the website
          </button>
        )}
      </div>
    ) : (
      <div className="form-container">
        <div className="become-a-free-member-action">
          Free Files <span className="icon icon-format-mp4" />
          <span className="icon icon-format-pptx" />
          <span className="icon icon-format-mp3" />
        </div>
        <div className="head-title">Download PDF and other files</div>
        <span className="free-member-title">{title}</span>
        <form>
          <Input
            disabled={isSaving}
            className={classNames('bg-color-white bg-lighten1', {
              error: !isValidEmail && email.length > 0,
            })}
            type="text"
            onChange={(e) => this.setState({ email: e.target.value, errorMessage: null })}
            value={email}
            placeholder="Please enter your email"
            name="firstName"
          />
          <button
            onClick={this.subscribe}
            type="submit"
            className="button default violet fill loading level-2"
          >
            Download
          </button>
        </form>
        <div className="input-legend error">
          {email.length > 0 && !isValidEmail
            ? 'Enter a valid email address to download'
            : this.state.errorMessage}
        </div>
        <Loading centered active={isSaving} />
      </div>
    );

    if (notModal)
      content = <div className="bg-color-white darken1 without-modal-form">{content}</div>;

    return content;
  }
}

NewsLettersForm.propTypes = {
  typeData: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func.isRequired,
  subscribeNewsletters: PropTypes.func.isRequired,
  notModal: PropTypes.bool,
  newsletter: PropTypes.shape({
    isSaving: PropTypes.bool,
  }).isRequired,
  label: PropTypes.string,
};

NewsLettersForm.defaultProps = {
  notModal: false,
  onClose: () => {},
  title: '',
  label: '',
};

export default connect(
  (store) => ({
    auth: store.auth,
    newsletter: store.newsletter,
  }),
  (dispatch) => ({
    subscribeNewsletters: bindActionCreators(subscribeNewslettersAction, dispatch),
  })
)(NewsLettersForm);
