import React from 'react';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NavHashLink } from 'react-router-hash-link';
import classNames from 'classnames';

import Loading from 'components/Loading';

const Button = (props) => {
  const {
    active,
    basic,
    className,
    color,
    disabled,
    fill,
    fluid,
    group,
    history,
    href,
    id,
    level,
    link,
    loading,
    main,
    navItem,
    negative,
    onClick,
    preventDefault,
    rel,
    size,
    style,
    target,
    to,
    title,
    type,
    ariaLabel,
  } = props;

  const _onClick = (e) => {
    if (disabled === true) {
      e.preventDefault();
      return;
    }

    if (_.isFunction(onClick)) {
      if (preventDefault && !href) {
        e.preventDefault();
      }
      if (onClick(e) === false) {
        e.preventDefault();
        return;
      }
    }

    if (to && to !== '') {
      history.push(to);
    }
  };

  const _className = classNames(
    'button',
    size,
    color,
    className,
    type,
    {
      secondary: basic !== false,
      group: group !== false,
      fill: fill !== false,
      negative: negative !== false,
      fluid: fluid !== false,
      active: active !== false,
      link: link !== false,
      main: main !== false,
      'nav-item': navItem !== false,
      disabled: disabled !== false,
      loading: loading !== false,
    },
    `level-${level}`
  );

  if (loading) {
    return (
      <div style={style} className={_className}>
        {props.children} <Loading active />
      </div>
    );
  }

  const _href = href || to;

  if (_href) {
    if (_href.search('#') === 0) {
      return (
        <NavHashLink
          title={title}
          style={style}
          id={id}
          smooth
          target={target}
          rel={rel}
          to={_href}
          onClick={_onClick}
          className={`${_className} link`}
        >
          {props.children}
        </NavHashLink>
      );
    }
    if (_href.search('http') === 0) {
      return (
        <a
          title={title}
          style={style}
          id={id}
          target={target}
          rel={rel}
          href={_href}
          onClick={_onClick}
          className={_className}
        >
          {props.children}
        </a>
      );
    }
  }
  return (
    <button
      title={title}
      style={style}
      id={id}
      disabled={disabled}
      target={target}
      rel={rel}
      type={type}
      to={_href}
      onClick={_onClick}
      className={_className}
      aria-label={ariaLabel}
    >
      {props.children}
    </button>
  );
};

Button.propTypes = {
  to: PropTypes.string,
  title: PropTypes.string,
  href: PropTypes.string,
  style: PropTypes.object,
  size: PropTypes.oneOf(['xsmall', 'small', 'default', 'big', 'xbig']),
  group: PropTypes.bool,
  color: PropTypes.oneOf(['default', 'violet', 'red', 'blue', 'white', 'black', 'aqua', 'green']),
  basic: PropTypes.bool,
  onClick: PropTypes.func,
  id: PropTypes.string,
  className: PropTypes.string,
  target: PropTypes.oneOf(['_blank', '_self', null]),
  type: PropTypes.string,
  rel: PropTypes.string,
  negative: PropTypes.bool,
  fill: PropTypes.bool,
  fluid: PropTypes.bool,
  active: PropTypes.bool,
  level: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  navItem: PropTypes.bool,
  link: PropTypes.bool,
  main: PropTypes.bool,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  preventDefault: PropTypes.bool,
  history: PropTypes.object.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]).isRequired,
  ariaLabel: PropTypes.string,
};

Button.defaultProps = {
  to: '',
  title: '',
  href: '',
  style: {},
  size: 'default',
  group: false,
  color: 'default',
  basic: false,
  onClick: null,
  id: '',
  className: '',
  target: null,
  type: '',
  rel: '',
  negative: false,
  fill: false,
  fluid: false,
  active: false,
  level: '2',
  navItem: false,
  link: false,
  main: false,
  disabled: false,
  loading: false,
  preventDefault: true,
  ariaLabel: '',
};

export default withRouter(Button);
