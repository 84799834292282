import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import Icon from 'components/Icon';
import Tooltip from 'components/Tooltip';
import { ReactComponent as HelpIcon } from 'resources/img/help.svg';

export const Item = ({ permission, tooltip, children }) => {
  return (
    <div className={classNames('permission', { unavailable: !permission })}>
      <Icon name={permission ? 'check' : 'cross'} className="right-icon" />
      <div className={classNames('content', { unavailable: !permission })}>{children}</div>
      {permission && tooltip && (
        <Tooltip key={tooltip} overlay={tooltip}>
          <span className="help-icon">
            <HelpIcon />
          </span>
        </Tooltip>
      )}
    </div>
  );
};

Item.propTypes = {
  children: PropTypes.node,
  permission: PropTypes.bool,
  tooltip: PropTypes.string,
};

Item.defaultProps = {
  children: <></>,
  permission: true,
  tooltip: '',
};

export default Item;
