import Cookies from 'universal-cookie';
import _ from 'lodash';
import qs from 'qs';

const cookies = new Cookies();

const getAndSaveUtms = () => {
  const queryParameters = qs.parse(window.location.search.replace('?', ''));
  const validatedUtmObject = _.transform(queryParameters, (acc, val, key) => {
    if (_.includes(key, 'utm_')) {
      acc[key] = val;
    }
    return acc;
  });

  const utmsArray = cookies.get('utmParameters') || [];

  const newUtm = {
    type: 'Utm',
    attributes: {
      created: new Date(),
      data: validatedUtmObject,
    },
  };
  if (!_.isEmpty(validatedUtmObject)) {
    utmsArray.push(newUtm);
    cookies.set('utmParameters', utmsArray);
  }
};

export default getAndSaveUtms;
