export const GIFT_CARDS_FETCHING = 'GIFT_CARDS_FETCHING';
export const GIFT_CARDS_FETCHED = 'GIFT_CARDS_FETCHED';

const giftCardsInit = {
  count: null,
  page: null,
  data: [],
  isFetching: false,
};

export const giftCards = (giftCardsInfo = giftCardsInit, action) => {
  switch (action.type) {
    case GIFT_CARDS_FETCHED:
      return {
        data: giftCardsInfo.data.concat(action.giftCards),
        count: action.count,
        page: action.page,
        isFetching: false,
      };

    case GIFT_CARDS_FETCHING:
      return {
        ...giftCardsInfo,
        isFetching: true,
      };

    default:
      return giftCardsInfo;
  }
};
