import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import './dock-modal.css';

const DockModal = ({ children }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    setShowModal(true);
  }, []);

  return (
    <div className={`dock-modal ${showModal ? 'show' : ''}`}>
      <div className="dock-modal-content">{children}</div>
    </div>
  );
};

DockModal.propTypes = {
  children: PropTypes.object.isRequired,
};

export default DockModal;
