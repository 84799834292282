import { PpAPI } from 'api';
import { RGAEvent } from 'helpers/history';
import { openModals } from 'helpers/modals';

import { Analytics } from 'facades/Analytics';

import { KEY_JWT_TOKEN } from 'config';
import { NEWSLETTER_FETCHING, NEWSLETTER_REGISTERED, NEWSLETTER_ERROR } from 'reducers/newsletter';
import { getDataUser, _setAuthUser } from 'actions/auth';

const subscribeNewsletters =
  (body, isSettingSession = false, label = '') =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: NEWSLETTER_FETCHING });
      PpAPI.post('/api/newsletter', body)
        .then((res) => {
          RGAEvent('Newsletter', 'Subscription', label);
          Analytics.sendEvent('Newsletter', {
            action: 'Subscription',
            label,
          });
          const { id, token } = res?.data?.data?.data ? res.data.data.data : {};
          if (isSettingSession && id && token) {
            localStorage.setItem(KEY_JWT_TOKEN, token);
            getDataUser(id).then((user) => {
              dispatch(_setAuthUser(user, token));
              setTimeout(() => openModals(null, 'updateUser', null, null, null), 5000);
            });
          }
          resolve(res);
          dispatch({ type: NEWSLETTER_REGISTERED });
        })
        .catch((err) => {
          reject(err);
          dispatch({ type: NEWSLETTER_ERROR });
        });
    });

export default subscribeNewsletters;
