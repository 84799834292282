import React from 'react';
import { PropTypes } from 'prop-types';
import get from 'lodash/get';
import map from 'lodash/map';
import classNames from 'classnames';
import { connect } from 'react-redux';

import Button from 'components/Button';
import Segment from 'components/Segment';
import { PUBLICATION_TYPE_LABELS_MAP_PLURAL } from 'reducers/publications';
import { TOOLTIP_INFO } from '../../scenes/Pricing/membershipsInfo';

import './membershipcard.css';

import { Item } from './Item';

const MembershipCard = ({ membership, className }) => {
  const {
    bestValue,
    name,
    price,
    rebillDaysLabel,
    cmeCredits,
    newCmeCredits,
    totalSa,
    defaultPermissions,
    id,
  } = membership;
  return (
    <Segment color="white" className={classNames('membership-card', className)}>
      <div className="membership-card-header">
        {bestValue && <div className="best-value">BEST VALUE</div>}
        <div className="name-and-price">
          <h4>
            {name} <br />
            Membership
          </h4>
          <div>
            <span>{`$${price}`}</span>
            <span>(USD) /{rebillDaysLabel}</span>
          </div>
        </div>
        <Button
          to={`/checkout?membership=${id}&from=pricing`}
          basic={!bestValue && name !== 'Silver'}
          fill
          className="cta-button"
          disabled={!id}
        >
          {`Get ${name}`}
        </Button>
      </div>
      <div className="membership-card-content">
        <div className="membership-credits">
          <Item>
            Up to&nbsp;
            <b>{`${cmeCredits + newCmeCredits} CMEs${totalSa ? ` + ${totalSa} SAs` : ''}`}</b>
          </Item>
          <Item>
            <b>{`${newCmeCredits} new CMEs`}</b> &nbsp;per year
          </Item>
          <Item permission={get(defaultPermissions, ['Unlimited downloads'], false)}>
            Unlimited downloads
          </Item>
        </div>
        <div className="divider" />
        <div className="membership-permissions">
          {map(defaultPermissions, (permission, value) => {
            if (value === 'Unlimited downloads') return null;
            return (
              <Item
                key={`permission_&${value}`}
                permission={permission}
                tooltip={get(TOOLTIP_INFO, [value], '')}
              >
                {get(PUBLICATION_TYPE_LABELS_MAP_PLURAL, [value], value)}
              </Item>
            );
          })}
        </div>
      </div>
    </Segment>
  );
};

MembershipCard.propTypes = {
  membership: PropTypes.object.isRequired,
  className: PropTypes.string,
  config: PropTypes.shape({
    data: PropTypes.object,
  }).isRequired,
};
MembershipCard.defaultProps = {
  className: '',
};

export default connect((store) => ({
  config: store.config,
}))(MembershipCard);
