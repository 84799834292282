import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';
import { ReactComponent as CapStIcon } from 'components/Icon/icons/cap-st.svg';
import { ReactComponent as BrainGuideIcon } from 'components/Icon/icons/brainguide.svg';

import { PUBLICATION_CAP_ST, BRAIN_GUIDE } from 'reducers/publications';

const PublicationIcon = ({ publicationType }) => {
  switch (publicationType) {
    case PUBLICATION_CAP_ST:
      return <CapStIcon />;
    case BRAIN_GUIDE:
      return <BrainGuideIcon />;
    default:
      return <Icon name={publicationType} />;
  }
};

PublicationIcon.propTypes = {
  publicationType: PropTypes.string.isRequired,
};

export default PublicationIcon;
