/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import { PropTypes } from 'prop-types';
import { Row, Col } from 'react-styled-flexboxgrid';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { toast } from 'react-toastify';

import Input from 'components/Input';
import SelectWrapper from 'components/Select';
import Icon from 'components/Icon';
import Button from 'components/Button';
import { updateUserInformation, updateAddressInformation } from 'actions/auth';

export const options = [
  { value: 'Physician', label: 'Physician' },
  { value: 'Physician Assistant', label: 'Physician Assistant' },
  { value: 'Nursing professional', label: 'Nursing professional' },
  { value: 'Pharmacist', label: 'Pharmacist' },
  { value: 'Therapist', label: 'Therapist' },
  { value: 'Another healthcare professional', label: 'Another healthcare professional' },
];

class UpdateUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      updatingInfo: false,
      profession: null,
      specialization: '',
    };
  }

  updateUserInformation = (e) => {
    const { profession, specialization } = this.state;
    e.preventDefault(e);
    this.setState({
      updatingInfo: true,
    });

    this.props
      .updateUserInformation(
        this.props.auth.user.id,
        { profession, specialization },
        this.props.type
      )
      .then(() => {
        this.setState({
          updatingInfo: false,
          success: true,
        });
      })
      .catch((err) => {
        toast.error(err);
        this.setState({
          updatingInfo: false,
        });
      });
  };

  onChangeSelect = (e) => {
    this.setState({ profession: e.value });
  };

  render() {
    const { specialization, updatingInfo, success, profession } = this.state;
    return success ? (
      <div className="update-user-success success-container">
        <Icon
          border
          size="30"
          color="white"
          name="check"
          style={{
            borderColor: '#01BA92',
            backgroundColor: '#01BA92',
            marginBottom: '0.5em',
          }}
        />
        <h4>Thank you!</h4>
        <p>We are working hard to improve every day and your help is appreciated.</p>
        <Button
          className="button default main level-2"
          onClick={() => this.props.onClose()}
          color="violet"
          link
        >
          Continue in the website
        </Button>
      </div>
    ) : (
      <div className="update-user-form">
        <h4>We&#39;d like to know you better</h4>
        <p>
          To offer you the best possible experience, we&#39;d like to know a bit more about you.
          Please answer these two questions to improve your future experience in the website:
        </p>
        <form>
          <Row>
            <Col md={6} xs={12}>
              <label className="update-user-label">Profession</label>
              <SelectWrapper
                options={options}
                value={this.value}
                placeholder="Select your profession"
                handleOnchange={(e) => this.onChangeSelect(e)}
              />
            </Col>
            <Col md={6} xs={12}>
              <label className="update-user-label">Specialty</label>
              <Input
                disabled={updatingInfo}
                className="speciality"
                type="text"
                onChange={(e) => this.setState({ specialization: e.target.value })}
                value={specialization}
                placeholder="Please enter your specialty"
              />
            </Col>
          </Row>
          <Row>
            <Button
              onClick={this.updateUserInformation}
              type="submit"
              className="button default fill level-2 pointer"
              color="violet"
              loading={updatingInfo}
              disabled={updatingInfo || !profession}
            >
              Send
            </Button>
          </Row>
        </form>
      </div>
    );
  }
}

export default connect(
  (store) => ({
    auth: store.auth,
  }),
  (dispatch) => ({
    updateUserInformation: bindActionCreators(updateUserInformation, dispatch),
    updateAddressInformation: bindActionCreators(updateAddressInformation, dispatch),
  })
)(UpdateUserForm);

UpdateUserForm.propTypes = {
  typeData: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  updateUserInformation: PropTypes.func.isRequired,
  auth: PropTypes.shape({
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
  type: PropTypes.string,
};

UpdateUserForm.defaultProps = {
  type: '',
};
