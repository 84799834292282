import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import classNames from 'classnames';

import './loading.scss';

const Loading = (props) => {
  const { active, centered, className } = props;

  if (active) {
    return (
      <span className={classNames('loading', className, { centered })}>
        <Icon name="loading" /> {props.children}
      </span>
    );
  }

  return null;
};

Loading.propTypes = {
  active: PropTypes.bool,
  centered: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
  className: PropTypes.string,
};

Loading.defaultProps = {
  active: false,
  centered: false,
  children: null,
  className: '',
};

export default Loading;
