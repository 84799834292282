import _ from 'lodash';

export const deserializeGeneric = (item) => {
  return {
    id: item.id,
    ...item.attributes,
    relationships: item.relationships,
  };
};

export const deserializeRelationship = (
  relationship,
  included,
  f = deserializeGeneric,
  ret_default = null
) => {
  if (!_.isFunction(f) || !_.get(relationship, 'data', false) || !included) {
    return ret_default;
  }

  if (_.isArray(relationship.data)) {
    return _.map(relationship.data, (el) => {
      const e = _.find(included, {
        id: el.id,
        type: el.type,
      });
      return e ? f(e, included) : {};
    });
  }
  const el = _.find(included, {
    id: _.get(relationship, 'data.id', false),
    type: _.get(relationship, 'data.type', false),
  });
  return el ? f(el, included) : {};
};
