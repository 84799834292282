export const HOMEBLOCKS_FETCHING = 'HOMEBLOCKS_FETCHING';
export const HOMEBLOCKS_FETCHED = 'HOMEBLOCKS_FETCHED';

const homeBlocksDefault = {
  data: [],
  isFetching: false,
};

export const homeBlocks = (homeBlocksInfo = homeBlocksDefault, action) => {
  switch (action.type) {
    case HOMEBLOCKS_FETCHING:
      return {
        ...homeBlocksInfo,
        isFetching: true,
      };
    case HOMEBLOCKS_FETCHED:
      return {
        data: action.homeBlocks,
        isFetching: false,
      };
    default:
      return homeBlocksInfo;
  }
};
