import React from 'react';
import PropTypes from 'prop-types';
import map from 'lodash/map';

import Segment from 'components/Segment/';
import Image from 'components/Image';

import apple from 'resources/img/podcast-badges/apple.png';
import google from 'resources/img/podcast-badges/google.png';
import rss from 'resources/img/podcast-badges/rss.png';
import spotify from 'resources/img/podcast-badges/spotify.png';
import stitcher from 'resources/img/podcast-badges/stitcher.png';

import './referrer.scss';

const Referrer = ({ className }) => {
  const items = [
    {
      href: 'https://itunes.apple.com/us/podcast/psychopharmacology-and-psychiatry-updates/id1425185370?mt=2&',
      src: apple,
      alt: 'Apple Podcast',
    },
    {
      href: 'https://open.spotify.com/show/7lzv8HKEzNPOAQVpUdrzMv?si=43it42SgRHy210qPOUNrdg',
      src: spotify,
      alt: 'Spotify Podcast',
    },
    {
      href: 'https://www.stitcher.com/s?fid=215561&',
      src: stitcher,
      alt: 'Stitcher Podcast',
    },
    {
      href: 'https://www.google.com/podcasts?feed=aHR0cDovL3BzeWNob3BoYXJtYWNvbG9neS5saWJzeW4uY29tL3BvZGNhc3Q%3D',
      src: google,
      alt: 'Google Podcast',
    },
    {
      href: 'https://psychopharmacology.libsyn.com/podcast',
      src: rss,
      alt: 'RSS Podcast',
    },
  ];
  return (
    <Segment size="s" className={`referrer ${className}`}>
      {map(items, ({ src, alt, href }, index) => {
        return (
          <div key={index}>
            <a target="_blank" rel="noopener noreferrer" href={href}>
              <Image src={src} alt={alt} />
            </a>
          </div>
        );
      })}
    </Segment>
  );
};

Referrer.propTypes = {
  className: PropTypes.string,
};

Referrer.defaultProps = { className: '' };

export default Referrer;
