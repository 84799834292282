import _ from 'lodash';

export const TEST_RESULTS_SET_ANSWER = 'TEST_RESULTS_SET_ANSWER';
export const RESET_TEST_RESULTS_ANSWER = 'RESET_TEST_RESULTS_ANSWER';
export const TEST_RESULTS_FETCHED = 'TEST_RESULTS_FETCHED';

const testResultsLocalInit = [];

export const testResultsLocal = (testResultsLocalInfo = testResultsLocalInit, action) => {
  switch (action.type) {
    case TEST_RESULTS_SET_ANSWER: {
      const testResult = _.find(testResultsLocalInfo, {
        pId: action.pId,
        qId: action.qId,
      });

      if (testResult) {
        return _.map(testResultsLocalInfo, (tr) => {
          if (tr.qId === action.qId && tr.pId === action.pId) {
            return {
              ...tr,
              aId: action.aId,
            };
          }
          return tr;
        });
      }

      return [
        ...testResultsLocalInfo,
        {
          pId: action.pId,
          qId: action.qId,
          aId: action.aId,
        },
      ];
    }
    case RESET_TEST_RESULTS_ANSWER:
      return testResultsLocalInit;
    default:
      return testResultsLocalInfo;
  }
};
