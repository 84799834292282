import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Anchor = (props) => {
  const { url, children, ...restOfProps } = props;
  const content = url.includes('http') ? (
    <a href={url} {...restOfProps}>
      {children}
    </a>
  ) : (
    <Link to={url} {...restOfProps}>
      {children}
    </Link>
  );

  return content;
};

Anchor.propTypes = {
  url: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
};

export default Anchor;
