import React from 'react';
import PropTypes from 'prop-types';

export const CustomInput = ({ getAutocomplete, getInputProps, getButtonProps, onEnterPressed }) => {
  const inputProps = getInputProps({
    placeholder: 'Search psychopharmacology topics',
  });
  return (
    <>
      <div className="sui-search-box__wrapper">
        <input {...inputProps} onKeyDown={onEnterPressed} />
        <div className="icon-wrapper">
          <span
            onClick={() => onEnterPressed({ key: 'Enter', target: { value: inputProps.value } })}
            className="icon icon-search"
          />
        </div>
        {getAutocomplete()}
      </div>
      <input
        {...getButtonProps({
          'data-custom-attr': 'some value',
        })}
      />
    </>
  );
};

CustomInput.propTypes = {
  getAutocomplete: PropTypes.func.isRequired,
  getInputProps: PropTypes.func.isRequired,
  getButtonProps: PropTypes.func.isRequired,
  onEnterPressed: PropTypes.func,
};

CustomInput.defaultProps = {
  onEnterPressed: () => {},
};
