import _ from 'lodash';

import { PpAPI } from 'api';
import get from 'lodash/get';
import { KEY_JWT_TOKEN, getJwtToken } from 'config';
import { RGA } from 'helpers/history';
import { deserializeRelationship } from 'actions/relationships';
import { deserializeMembership } from 'actions/memberships';
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_LOGGED,
  AUTH_UPDATING,
  AUTH_REGISTERING,
  AUTH_REGISTERED,
} from 'reducers/auth';

import { checkUpdateUserModalValidation } from 'helpers/modals';

export const _setAuthUser = (user, token) => ({
  type: AUTH_LOGGED,
  user,
  token,
});

export const _logout = () => ({
  type: AUTH_LOGOUT,
});

export const _userUpdating = () => ({
  type: AUTH_UPDATING,
});

export const getDataUser = (userId = false) => {
  return new Promise((resolve, reject) => {
    userId = userId || 'me';
    PpAPI.get(`/api/users/${userId}/?include=membership.permissions,addresses`)
      .then((response) => {
        const user = {
          id: response.data.data.id,
          ...response.data.data.attributes,
          membership: deserializeRelationship(
            response.data.data.relationships.membership,
            response.data.included,
            deserializeMembership
          ),
          addresses: deserializeRelationship(
            response.data.data.relationships.addresses,
            response.data.included
          ),
        };
        RGA.set({ userId: `${response.data.data.id}` });
        RGA.set({ dimension1: get(user, 'hasMembership', false) ? 'Membership' : 'Free account' });
        RGA.set({ dimension2: get(user, 'membership.name', 'null') });
        RGA.set({ dimension3: get(user, 'profession', 'null') });
        RGA.set({ dimension4: get(user, 'specialization', 'null') });
        resolve(user);
      })
      .catch(reject);
  });
};

export const signin = (username, password) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: AUTH_LOGIN });

    const data = {
      data: {
        attributes: {
          username,
          password,
        },
        type: 'ApiLoginView',
      },
    };
    PpAPI.post('/api/login/', data)
      .then((res) => {
        if (res.status === 200) {
          localStorage.setItem(KEY_JWT_TOKEN, res.data.data.attributes.token);
          getDataUser(res.data.data.id).then((user) => {
            dispatch(_setAuthUser(user, res.data.data.attributes.token));
            checkUpdateUserModalValidation(user);
            resolve(res);
          });
        } else {
          localStorage.removeItem(KEY_JWT_TOKEN);
          dispatch(_logout());
          reject(res.data);
        }
      })
      .catch((err) => {
        localStorage.removeItem(KEY_JWT_TOKEN);
        dispatch(_logout());
        reject(err);
      });
  });

export const sendPasswordReset = (email) =>
  new Promise((resolve, reject) => {
    const data = {
      data: {
        attributes: {
          email,
        },
        type: 'PasswordResetView',
      },
    };
    PpAPI.post('/rest-auth/password/reset/', data).then(resolve).catch(reject);
  });

export const createNewPassword = (password, repeatPassword, uid, token) =>
  new Promise((resolve, reject) => {
    const data = {
      data: {
        attributes: {
          token,
          uid,
          new_password1: password,
          new_password2: repeatPassword,
        },
        type: 'PasswordResetConfirmView',
      },
    };
    PpAPI.post('/rest-auth/password/reset/confirm/', data).then(resolve).catch(reject);
  });

export const logout = () => localStorage.removeItem(KEY_JWT_TOKEN);

export const existsUsername = (username) =>
  new Promise((resolve, reject) => {
    const data = {
      params: {
        username,
      },
    };

    PpAPI.get('/api/registration/verify-email/', data)
      .then((res) => {
        if (res.status === 200) {
          resolve(res.data.data.exists);
          return;
        }
        resolve(false);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const register =
  (username, password1, password2, moreInfo = {}) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({ type: AUTH_REGISTERING });

      const data = {
        data: {
          attributes: {
            ...moreInfo,
            username,
            password1,
            password2,
            email: username,
          },
          type: 'ApiRegisterView',
        },
      };

      PpAPI.post('/api/registration/', data)
        .then((res) => {
          if (res.status >= 200 && res.status < 300) {
            dispatch({ type: AUTH_REGISTERED, userId: res.data.data.id });
            localStorage.setItem(KEY_JWT_TOKEN, res.data.data.attributes.token);
            getDataUser(res.data.data.id).then((user) => {
              dispatch(_setAuthUser(user, res.data.data.attributes.token));
              checkUpdateUserModalValidation(user);
              resolve(res);
            });
          } else {
            logout();
            dispatch(_logout());
            reject(res.data);
          }
        })
        .catch((err) => {
          logout();
          dispatch(_logout());
          reject(err);
        });
    });

export const checkToken = (token) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: AUTH_LOGIN });
    if (token) {
      PpAPI.get('/rest-auth/user/', {
        headers: {
          Authorization: `JWT ${token}`,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            const userId = get(res, 'data.data.id');
            if (userId) {
              getDataUser(userId).then((user) => {
                dispatch(_setAuthUser(user, token));
                checkUpdateUserModalValidation(user);
                resolve(res);
              });
            }
            resolve(res);
          } else {
            logout();
            dispatch(_logout());
            reject(res.data);
          }
        })
        .catch((err) => {
          logout();
          dispatch(_logout());
          reject(err);
        });
    } else {
      logout();
      dispatch(_logout());
    }
  });

export const refreshToken = (token) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch({ type: AUTH_LOGIN });

    PpAPI.post('/api/token-refresh/', {
      token,
    })
      .then((res) => {
        if (res.status === 200) {
          getDataUser(res.data.data.id).then((user) => {
            dispatch(_setAuthUser(user, localStorage.getItem(KEY_JWT_TOKEN)));
            resolve(res);
          });
          resolve(res);
        } else {
          logout();
          dispatch(_logout());
          reject(res.data);
        }
      })
      .catch((err) => {
        logout();
        dispatch(_logout());
        reject(err);
      });
  });

export const updateUserInformation = (userId, partialUpdate) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(_userUpdating());

    const data = {
      attributes: _.reduce(
        partialUpdate,
        (red, item, key) => {
          if (item === null) {
            return red;
          }
          return {
            ...red,
            [key]: item,
          };
        },
        {}
      ),
      id: userId,
      type: 'User',
    };
    const changeEmail = _.has(partialUpdate, 'email');

    PpAPI.patch(`/api/users/${userId}/`, { data })
      .then((res) => {
        if (changeEmail) {
          logout();
          dispatch(_logout());
          resolve(res);
        } else {
          getDataUser().then((user) => {
            dispatch(_setAuthUser(user, getJwtToken()));
            resolve(res);
          });
        }
      })
      .catch((err) => {
        reject(err);
      });
  });

export const updateAddressInformation = (addressId, partialUpdate) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(_userUpdating());

    let data = {
      type: 'Address',
      attributes: {
        address1: partialUpdate.street,
        address2: partialUpdate.number,
        city: partialUpdate.city,
        postalCode: partialUpdate.zip,
        state: partialUpdate.address_state,
        country: partialUpdate.country,
      },
    };

    let q;

    if (addressId) {
      data = {
        ...data,
        id: addressId,
      };

      q = PpAPI.patch(`/api/users/addresses/${addressId}/`, { data });
    } else {
      q = PpAPI.post(`/api/users/addresses/`, { data });
    }

    q.then((res) => {
      getDataUser().then((user) => {
        dispatch(_setAuthUser(user, getJwtToken()));
        resolve(res);
      });
    }).catch((err) => {
      reject(err);
    });
  });

export const updatePasswordInformation = (partialUpdate) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(_userUpdating());
    const data = {
      data: {
        attributes: {
          old_password: partialUpdate.currentPassword,
          new_password1: partialUpdate.newPassword,
          new_password2: partialUpdate.repeatNewPassword,
        },
        type: 'PasswordChangeView',
      },
    };
    PpAPI.post('rest-auth/password/change/', data).then(resolve).catch(reject);
  });
