import React from 'react';

import Button from 'components/Button';
import Segment from 'components/Segment';
import SimplePage from 'layouts/SimplePage';

const ReferralProgramTermsConditions = () => {
  return (
    <SimplePage
      gridClassname="container-max-width"
      id="terms_conditions"
      title="Referral Program: Terms and Conditions"
    >
      <Segment group>
        <p>
          Psychopharmacology Institute Referral Program Terms and Conditions. Last updated: August
          9, 2021.
        </p>
        <p>
          The Psychopharmacology Institute Referral Program (“The Program”) allows paying members to
          earn{' '}
          <Button link color="blue" href="https://Amazon.com">
            Amazon.com
          </Button>{' '}
          gift cards (&#34;Gift Cards&#34;) by referring colleagues to become new paying members of
          the Psychopharmacology Institute.
        </p>
        <p>
          To participate, Members must agree to these terms, which become part of the
          Psychopharmacology Institute Terms of Service.
        </p>
        <p>
          Referring members can earn Gift Cards when a referred friend clicks on their referral link
          and completes a valid purchase for a Psychopharmacology Institute membership. To receive
          the Gift Card, the referred person must buy one of the memberships we offer on our website
          and also cannot cancel before the satisfaction guarantee (30 days). If the referred member
          cancels the membership before 30 days, they will receive their money back, but we will not
          be able to fund the gift card. It is for this reason that we will only send the Gift Card
          30 days after the referred member has completed the purchase, and only if the referred
          member maintains their membership.
        </p>
      </Segment>
      <Segment group>
        <h5>How to Earn Gift Cards</h5>
        <ol>
          <li> Enter your friend&#39;s email address and send an invitation.</li>
          <li>Wait until your friend becomes a paying member.</li>
          <li>You can check the status of your referrals in your account settings.</li>
        </ol>
      </Segment>
      <Segment group>
        <h5>Sharing Referral Links</h5>
        <p>
          Referrals should only be used for personal and non-commercial purposes. Referral links
          should not be published or distributed on commercial websites (such as coupon websites,
          Reddit, or Wikipedia) or on blogs. Members are prohibited from “spamming” anyone with
          referral invitations. This includes mass emailing, texting or messaging people you do not
          know or using automated systems or bots through any channel to distribute your referral
          link. Members are prohibited from paying to advertise their referral links.
        </p>
      </Segment>
    </SimplePage>
  );
};

export default ReferralProgramTermsConditions;
