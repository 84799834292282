export const RECEIPTS_FETCHING = 'RECEIPTS_FETCHING';
export const RECEIPTS_FETCHED = 'RECEIPTS_FETCHED';

const receiptsInit = {
  count: null,
  page: null,
  data: [],
  isFetching: false,
};

export const receipts = (receiptsInfo = receiptsInit, action) => {
  switch (action.type) {
    case RECEIPTS_FETCHED:
      return {
        count: action.count,
        page: action.page,
        data: receiptsInfo.data.concat(action.receipts),
        isFetching: false,
      };

    case RECEIPTS_FETCHING:
      return {
        ...receiptsInit,
        isFetching: true,
      };

    default:
      return receiptsInfo;
  }
};
