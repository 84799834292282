import get from 'lodash/get';
import map from 'lodash/map';
import flatten from 'lodash/flatten';
import forEach from 'lodash/forEach';
import split from 'lodash/split';

function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, 'g'), replace);
}

export const getFormatedResultTitle = (result) => {
  const title = get(result, 'title.snippet') || get(result, 'title.raw', '');
  return replaceAll(replaceAll(title, '<em>', '<b>'), '</em>', '</b>');
};

export const getNameOfSelectedCategory = (categories, categorySlug) => {
  if (!categories || !categorySlug) return '';

  const allCategories = flatten(
    map(categories, ({ category }) => {
      return get(category, 'childs', []);
    })
  );
  const selectedCategory = (
    allCategories.find(({ slug }) => `${slug}` === `${categorySlug}`) || { name: '' }
  ).name;

  return selectedCategory;
};

export const getCategoriesStatus = (categories) => {
  const result = {};
  forEach(categories, (category) => {
    const id = get(category, 'category.id', null);
    if (id) {
      result[id] = false;
    }
  });
  return result;
};

export const addOrRemoveFilterParam = (params = '', value = '') => {
  if (!params) return value;
  if (!value) return params;

  const arrayParams = split(params, ',');
  const pos = arrayParams.indexOf(value);

  const result = pos === -1 ? [...arrayParams, value] : arrayParams.filter((e) => e !== value);
  return result.toString();
};

export const addOrRemoveFilterParamFromUrl = (params, filterName, value) => {
  if (!params || !filterName || !value) return '';

  const currentParams = { ...params, page: 1 };
  const currentFilterValue = currentParams[filterName];
  const newFilterValue = addOrRemoveFilterParam(currentFilterValue, value);
  if (newFilterValue) {
    currentParams[filterName] = newFilterValue;
  } else {
    delete currentParams[filterName];
  }
  return new URLSearchParams(currentParams).toString();
};

export const removeAllParamsWithExceptions = (params, excludeParams = ['s']) => {
  if (!params || excludeParams.length === 0) return '';

  const newParams = Object.keys(params)
    .filter((key) => excludeParams.includes(key))
    .reduce((obj, key) => {
      obj[key] = params[key];
      return obj;
    }, {});
  return new URLSearchParams(newParams).toString();
};

export const getAuthorsListNames = (authors) => {
  /**
   * TODO: pass this parameter as endpoint field
   */
  const formattedNames = [];
  authors.forEach(function (author) {
    const fullName = author.fullNameAffix;
    if (fullName !== undefined) {
      formattedNames.push(fullName);
    }
  });
  return formattedNames.join(', ');
};
