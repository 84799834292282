import React from 'react';
import { hydrate, render } from 'react-dom';
import { Provider } from 'react-redux';
import App from 'App';
import store from 'store';
import { registerWebVitalsEvent } from 'helpers/history';
import { Analytics } from 'facades/Analytics';
import { unregister } from './registerServiceWorker';
import reportWebVitals from './reportWebVitals';

import './index.css';
import 'video-react/dist/video-react.css';

const rootElement = document.getElementById('root');
Analytics.initialize();

if (rootElement.hasChildNodes()) {
  hydrate(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    rootElement
  );
  unregister();
}

reportWebVitals(registerWebVitalsEvent);
