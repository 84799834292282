import React from 'react';

import './index.scss';

const UpgradeBrowser = () => {
  return (
    <div className="upgrade-browser">
      <b>This website does not support this browser (Internet Explorer 11).</b>
      <div>
        We recommend you use the latest version of{' '}
        <a href="https://www.google.com/chrome/">Google Chrome</a>,{' '}
        <a href="https://www.microsoft.com/en-us/edge">Microsoft Edge</a>, or{' '}
        <a href="https://www.mozilla.org/en-US/firefox/new/">Mozilla Firefox. </a>
      </div>
    </div>
  );
};

export default UpgradeBrowser;
