import ReactGA from 'react-ga';
import _ from 'lodash';
import qs from 'qs';
import { createBrowserHistory } from 'history';
import store from 'store';
import Cookies from 'universal-cookie';
import { isMobile } from 'react-device-detect';

import { Analytics } from 'facades/Analytics';
import { sendUtm } from 'actions/utm';
import { restoreModal } from 'actions/modals';
import { GOOGLE_ANALYTICS_KEY } from 'config';

ReactGA.initialize(GOOGLE_ANALYTICS_KEY, { testMode: process.env.NODE_ENV === 'test' });

export const RGA = ReactGA;

RGA.pageview(window.location.pathname + window.location.search);

const cookies = new Cookies();

export const history = createBrowserHistory();
history.listen((location) => {
  if (isMobile) {
    window.scrollTo({ top: 0 });
  }
  restoreModal();
  const state = store.getState();
  if (state.auth.isLogged && cookies.get('utmParameters')) {
    sendUtm(cookies.get('utmParameters'));
  }

  if (GOOGLE_ANALYTICS_KEY !== '') {
    setTimeout(() => {
      if (_.get(state.auth.user, 'id', false)) {
        RGA.set({
          userId: state.auth.user.id,
          email: state.auth.user.email,
        });
      }

      RGA.set({ page: location.pathname + location.search });
      RGA.pageview(location.pathname + location.search);

      const els = document.getElementsByClassName('autoTop');
      _.each(els, (item) => {
        item.scrollTop = 0;
      });
    }, 0);
  }
});

export default history;

export const getRedirectParam = (to = '/dashboard') => {
  const { redirect } = qs.parse(history.location.search.replace('?', ''));
  return redirect || to;
};

export const goToRedirectOrGoTo = (to = '/dashboard') => {
  const { redirect } = qs.parse(history.location.search.replace('?', ''));
  if (redirect) {
    history.push(redirect);
    return;
  }
  history.push(to);
};

export const generateUrl = (path = '/', redirect = false) => {
  if (redirect) {
    return `/${path}?redirect=${encodeURI(redirect)}`;
  }
  return `/${path}`;
};

export const logoutUrl = (autoredirect = false) => {
  if (autoredirect === true) {
    autoredirect = `${history.location.pathname}${history.location.search}`;
  }
  if (_.isString(autoredirect)) {
    return generateUrl('logout', autoredirect);
  }
  return generateUrl('logout');
};

export const signinUrl = (autoredirect = false) => {
  if (autoredirect === true) {
    autoredirect = `${history.location.pathname}${history.location.search}`;
  }
  if (_.isString(autoredirect)) {
    return generateUrl('signin', autoredirect);
  }
  return generateUrl('signin');
};

export const RGAEvent = (GACategory, GAAction, GALabel = 'Other', value = null) => {
  const category = GACategory && GACategory !== '' ? GACategory : null;
  const action = GAAction && GAAction !== '' ? GAAction : null;
  const label = GALabel;

  if (category && action) {
    RGA.event({
      category,
      action,
      label,
      ...(value !== null ? { value } : {}),
    });
  }
};

export const registerWebVitalsEvent = (entries) => {
  let { pathname } = window.location;

  const dinamicRoutes = ['/test/results', '/test', '/publication', '/section'];

  const filteredRoutes = _.filter(dinamicRoutes, (includePath) =>
    _.includes(pathname, includePath)
  );

  pathname = _.get(filteredRoutes, '[0]', pathname);
  // replace RGAEvent by console.log to test on local
  RGAEvent('web-vitals', pathname, entries.name, entries.value);
  Analytics.sendEvent('web-vitals', {
    action: pathname,
    label: entries.name,
    value: entries.value,
  });
};
