/* React & Redux */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

/* Components */
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import AudioPlayer from 'react-player/soundcloud';
import Loading from 'components/Loading';
import _ from 'lodash';

/* Style */
import './podcastPlayer.scss';

const PodcastPlayer = ({ podcasts }) => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [podcastsList, setPodcastsList] = useState([]);
  const [urlEpisode, setUrlEpisode] = useState('');
  const [countPlaying, setCountPlaying] = useState(0);

  const onSelectEpisode = (item) => {
    setTitle(`${item.id} - ${item.title}`);
    setDescription(item.excerpt);
    setUrlEpisode(item.attachmentUrl);
    setCountPlaying(countPlaying + 1);
  };

  useEffect(() => {
    if (podcasts && podcasts.length) {
      const currentPodcast = podcasts[0];
      onSelectEpisode(currentPodcast);
      setPodcastsList(podcasts);
    }
  }, [podcasts]);

  const formatDescription = (value) =>
    value !== '' && value.toString().length > 300 ? `${value.substring(0, 300)} ... ` : value;

  const EpisodeItem = (value) => {
    const { item } = value;
    return (
      <Grid className="grid-episode-item" onClick={() => onSelectEpisode(item)}>
        <Row className="row-episode-item">
          <Col xs={2}>{item.id}</Col>
          <Col xs={10}>{item.title}</Col>
        </Row>
        <hr />
      </Grid>
    );
  };

  const onSearch = (value) => {
    const newList = _.filter(
      podcasts,
      (item) => item.title.toLowerCase().indexOf(value.toLowerCase()) > -1
    );
    setPodcastsList(newList);
  };

  return (
    <div id="player">
      {podcasts && podcasts.length > 0 ? (
        <Grid className="container">
          <Row className="row">
            <Col xs={12} md={7}>
              <Row className="mt-14">
                <AudioPlayer
                  url={urlEpisode}
                  height="154px"
                  className="player"
                  control="true"
                  config={{
                    soundcloud: {
                      options: {
                        show_artwork: true,
                        auto_play: countPlaying > 1,
                        download: true,
                        single_active: true,
                        show_user: true,
                        sharing: true,
                      },
                    },
                  }}
                />
              </Row>
              <Row className="title-container">
                <Col xs={false} md={2}>
                  <img
                    src="https://i1.sndcdn.com/artworks-bqjRaikoHo3qjPb6-Fn7yhw-t500x500.jpg"
                    alt="https://psychopharmacologyinstitute.com/"
                    className="img"
                  />
                </Col>
                <Col xs={12} md={10}>
                  <Row>
                    <span className="title">{title}</span>
                  </Row>
                </Col>
              </Row>
              <Row className="description-container">
                <span className="description">{formatDescription(description)}</span>
              </Row>
            </Col>
            <Col xs={12} md={5} className="container-2nd-column">
              <Row className="container-search">
                <span className="icon icon-search" />
                <input
                  placeholder="Buscar"
                  className="input-search"
                  onChange={(e) => onSearch(e.target.value)}
                />
              </Row>
              <Row className="list">
                {podcastsList.length > 0 &&
                  _.map(podcastsList, (item, index) => (
                    <EpisodeItem item={item} key={index.toString()} />
                  ))}
              </Row>
            </Col>
          </Row>
        </Grid>
      ) : (
        <Loading centered active={!podcasts}>
          Loading
        </Loading>
      )}
    </div>
  );
};

PodcastPlayer.propTypes = {
  podcasts: PropTypes.array,
};

PodcastPlayer.defaultProps = {
  podcasts: [],
};

export default PodcastPlayer;
