import {
  PUBLICATION_VIDEO,
  PUBLICATION_INTERVIEW,
  PUBLICATION_QUICK_TAKE,
  PUBLICATION_CAP_ST,
  SELF_ASSESSMENT,
  BRAIN_GUIDE,
} from 'reducers/publications';

export const TOOLTIP_INFO = {
  [PUBLICATION_VIDEO]: 'Treatment updates, as bite-sized videos.',
  [PUBLICATION_INTERVIEW]: 'Clinical pearls from interviews with experts.',
  [PUBLICATION_QUICK_TAKE]: 'Research summaries in adult psychiatry.',
  [PUBLICATION_CAP_ST]: 'Research summaries in child and adolescent psychiatry.',
  'Audio Feed': 'Listen to our website content on your podcasting app.',
  [SELF_ASSESSMENT]: 'U.S. psychiatrists need 24 SAs for MOC. We offer 40 SAs.',
  'Unlimited downloads': 'Download all files for offline use',
  [BRAIN_GUIDE]: 'Practical guides for decision-making in psychopharmacology.',
};

export default [
  {
    name: 'Bronze',
    cmeCredits: 35,
    newCmeCredits: 12,
    totalSa: 0,
    defaultPermissions: {
      [PUBLICATION_VIDEO]: true,
      [PUBLICATION_INTERVIEW]: false,
      [PUBLICATION_QUICK_TAKE]: false,
      [PUBLICATION_CAP_ST]: false,
      [BRAIN_GUIDE]: false,
      'Audio Feed': false,
      [SELF_ASSESSMENT]: false,
      'Unlimited downloads': false,
    },
    rebillDays: 365,
    bestValue: false,
  },
  {
    name: 'Silver',
    cmeCredits: 85,
    newCmeCredits: 30,
    totalSa: 0,
    defaultPermissions: {
      [PUBLICATION_VIDEO]: true,
      [PUBLICATION_INTERVIEW]: true,
      [PUBLICATION_QUICK_TAKE]: true,
      [PUBLICATION_CAP_ST]: true,
      [BRAIN_GUIDE]: true,
      'Audio Feed': true,
      [SELF_ASSESSMENT]: false,
      'Unlimited downloads': true,
    },
    rebillDays: 365,
    bestValue: true,
  },
  {
    name: 'Gold',
    cmeCredits: 117,
    newCmeCredits: 38,
    totalSa: 40,
    defaultPermissions: {
      [PUBLICATION_VIDEO]: true,
      [PUBLICATION_INTERVIEW]: true,
      [PUBLICATION_QUICK_TAKE]: true,
      [PUBLICATION_CAP_ST]: true,
      [BRAIN_GUIDE]: true,
      'Audio Feed': true,
      [SELF_ASSESSMENT]: true,
      'Unlimited downloads': true,
    },
    rebillDays: 365,
    bestValue: false,
  },
  {
    name: 'Bronze',
    cmeCredits: 35,
    newCmeCredits: 12,
    totalSa: 0,
    defaultPermissions: {
      [PUBLICATION_VIDEO]: true,
      [PUBLICATION_INTERVIEW]: false,
      [PUBLICATION_QUICK_TAKE]: false,
      [PUBLICATION_CAP_ST]: false,
      [BRAIN_GUIDE]: false,
      'Audio Feed': false,
      [SELF_ASSESSMENT]: false,
      'Unlimited downloads': false,
    },
    rebillDays: 1825,
    bestValue: false,
  },
  {
    name: 'Silver',
    cmeCredits: 85,
    newCmeCredits: 30,
    totalSa: 0,
    defaultPermissions: {
      [PUBLICATION_VIDEO]: true,
      [PUBLICATION_INTERVIEW]: true,
      [PUBLICATION_QUICK_TAKE]: true,
      [PUBLICATION_CAP_ST]: true,
      [BRAIN_GUIDE]: true,
      'Audio Feed': true,
      [SELF_ASSESSMENT]: false,
      'Unlimited downloads': true,
    },
    rebillDays: 1825,
    bestValue: false,
  },
  {
    name: 'Gold',
    price: 497,
    cmeCredits: 117,
    newCmeCredits: 38,
    totalSa: 40,
    defaultPermissions: {
      [PUBLICATION_VIDEO]: true,
      [PUBLICATION_INTERVIEW]: true,
      [PUBLICATION_QUICK_TAKE]: true,
      [PUBLICATION_CAP_ST]: true,
      [BRAIN_GUIDE]: true,
      'Audio Feed': true,
      [SELF_ASSESSMENT]: true,
      'Unlimited downloads': true,
    },
    rebillDays: 1825,
    bestValue: false,
  },
];
