import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Control, Form, actions } from 'react-redux-form';
import classNames from 'classnames';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Segment from 'components/Segment';
import Tooltip from 'components/Tooltip';
import subscribeNewslettersAction from 'actions/newsletter';
import { RGAEvent } from 'helpers/history';
import { validEmail, errorMessages } from 'helpers/forms';

import { Analytics } from 'facades/Analytics';

import './newsletterbar.css';

const ContronText = Control.text;

const NewsLetterBar = ({ auth, forms, newsletter, subscribeNewsletters, changeField }) => {
  const [success, setSuccess] = useState(false);
  const [hideNewsletterBar, setHideNewsletterBar] = useState(false);

  useEffect(() => {
    if (!auth.isLogged && !auth.isFetching) {
      RGAEvent('Newsletter', 'Shown', 'Bar');
      Analytics.sendEvent('Newsletter', {
        action: 'Shown',
        label: 'Bar',
      });
    }
    if (auth.isLogged) {
      setHideNewsletterBar(true);
    }
  }, [auth]);

  const onSubmit = () => {
    const { email } = forms.newsletter;

    subscribeNewsletters({ email }, false, 'Bar')
      .then(() => {
        setSuccess(true);
      })
      .catch((err) => {
        changeField.change(
          'newsletter.error',
          _.get(err, 'response.data.errors[0].detail', 'Error')
        );
      });
  };
  const emailLength = forms.newsletter.email && forms.newsletter.email.length > 0;
  const validateForm = () => validEmail(forms.newsletter.email);
  const emailError = forms.newsletter.error
    ? forms.newsletter.error
    : !validateForm() && emailLength
    ? errorMessages.validEmail
    : null;

  return !hideNewsletterBar ? (
    <Segment size="xxs" className="home-newsletter">
      <span className="color-white lighten1 message">
        Receive psychopharmacology updates in your inbox
      </span>
      <Tooltip
        placement="bottomLeft"
        getTooltipContainer={() => document.querySelector('.newsletter-form')}
        {...(emailError ? { visible: true } : {})}
        animation="zoom"
        overlayClassName={!emailError && emailLength && 'hide-tooltip'}
        overlay={<span>{emailError || 'Enter your email'}</span>}
      >
        <Form model="newsletter" className="newsletter-form">
          {!success && (
            <ContronText
              onChange={() =>
                forms.newsletter.error ? changeField.change('newsletter.error', false) : null
              }
              model=".email"
              placeholder="Please, enter your email"
              className={classNames('email-field', {
                error: emailLength && !validateForm(),
              })}
              validators={{
                required: (val) => val && val.length,
              }}
            />
          )}
          <Button
            className={classNames('subscribe-button', { success })}
            fill
            color={success ? 'green' : 'violet'}
            disabled={!validateForm() || newsletter.isSaving}
            onClick={onSubmit}
            loading={newsletter.isSaving}
          >
            {!success ? (
              'Subscribe'
            ) : (
              <span className="success-text">
                <Icon name="check" className="right-icon" />
                Thanks!
              </span>
            )}
          </Button>
        </Form>
      </Tooltip>
    </Segment>
  ) : (
    <></>
  );
};

NewsLetterBar.propTypes = {
  auth: PropTypes.object.isRequired,
  changeField: PropTypes.object.isRequired,
  forms: PropTypes.object.isRequired,
  newsletter: PropTypes.object.isRequired,
  subscribeNewsletters: PropTypes.func.isRequired,
};

export default connect(
  (store) => ({
    auth: store.auth,
    forms: store.forms,
    newsletter: store.newsletter,
  }),
  (dispatch) => ({
    changeField: bindActionCreators(actions, dispatch),
    subscribeNewsletters: bindActionCreators(subscribeNewslettersAction, dispatch),
  })
)(NewsLetterBar);
