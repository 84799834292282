import reduce from 'lodash/reduce';
import get from 'lodash/get';

import { PpAPI } from 'api';
import { CONFIG_FETCHED, CONFIG_FETCHING } from 'reducers/config';

import { fetchedTranslations } from './translations';

const fetchedConfig = (config) => ({
  type: CONFIG_FETCHED,
  config,
});

const fetchingConfig = () => ({
  type: CONFIG_FETCHING,
});

export const fetchConfig = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(fetchingConfig());

    PpAPI.get('/api/config/?page[size]=100')
      .then((res) => {
        const config = reduce(
          res.data.data,
          (red, item) => ({ ...red, [item.attributes.key]: item.attributes.value }),
          {}
        );
        resolve(config);
        dispatch(fetchedConfig(config));
      })
      .catch((err) => {
        reject(err);
      });
  });

export const fetchInitialConfig = () => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(fetchingConfig());

    PpAPI.get('/api/data/')
      .then((res) => {
        const configs = get(res, 'data.data.configs');
        const i18n = get(res, 'data.data.i18n');
        if (configs && i18n) {
          const config = reduce(
            configs,
            (red, item) => ({ ...red, [item.attributes.key]: item.attributes.value }),
            {}
          );
          dispatch(fetchedTranslations(i18n));
          dispatch(fetchedConfig(config));
        }

        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
