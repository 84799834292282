import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Link from 'components/Link';
import Segment from 'components/Segment';
import Input from 'components/Input';

import subscribeNewsletters from 'actions/newsletter';
import { RGAEvent } from 'helpers/history';
import { validEmail, errorMessages } from 'helpers/forms';
import { AuthContext } from 'reducers/auth';

import { Analytics } from 'facades/Analytics';

import './index.css';

const topCommonButtons = (
  <>
    <li>
      <Button main to="/contact-us" aria ariaLabel="Contact Us" negative>
        Contact Us
      </Button>
    </li>
    <li>
      <Button main to="/terms-and-conditions" ariaLabel="Terms & Conditions" negative>
        Terms & Conditions
      </Button>
    </li>
    <li>
      <Button
        main
        href="https://jobs.wrkhq.com/psychcampus"
        target="_blank"
        ariaLabel="Work With Us"
        negative
      >
        Work With Us
      </Button>
    </li>
  </>
);

const bottomCommonButtons = (
  <>
    <li>
      <Button main to="/podcasts" ariaLabel="Podcasts" negative>
        Podcasts
      </Button>
    </li>
    <li>
      <Button main to="/privacy-policy" ariaLabel="Privacy Policy" negative>
        Privacy Policy
      </Button>
    </li>
    <li>
      <Button main to="/help-faqs" ariaLabel="Help / FAQs" negative>
        Help / FAQs
      </Button>
    </li>
    <li>
      <Button main to="/about-us" ariaLabel="About Us" negative>
        About Us
      </Button>
    </li>
  </>
);

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
    };
  }

  componentDidMount() {
    if (!this.context.isLogged && !_.isNull(this.context.isLogged)) {
      RGAEvent('Newsletter', 'Shown', 'Footer');
      Analytics.sendEvent('Newsletter', {
        action: 'Shown',
        label: 'Footer',
      });
    }
  }

  subscribe = () => {
    const { email } = this.state;
    this.setState({ email: '' });
    this.props
      .subscribeNewsletters({ email })
      .then((res) => {
        if (res.data && res.data.data) {
          toast.success(res.data.data.message);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data.errors) {
          const { message } = error.response.data.errors;
          toast.error(message);
        }
      });
  };

  render() {
    const inputContent = (
      <>
        <h6>Subscribe to our newsletter for monthly updates!</h6>
        <div id="footer-input-subscribe">
          <form>
            <Input
              onChange={(e) => this.setState({ email: e.target.value })}
              value={this.state.email}
              type="email"
              name="email"
              className="input violet fill negative small group"
              ariaLabel="subscribe to newsletter"
            />
            <Button
              disabled={!validEmail(this.state.email)}
              onClick={this.subscribe}
              type="submit"
              className="button violet fill level-0 negative small group"
              ariaLabel="subscribe to newsletter"
            >
              <Icon name="arrow_right" />
            </Button>
          </form>
        </div>
        {this.state.email.length > 0 && !validEmail(this.state.email) && (
          <div className="input-legend error">{errorMessages.validEmail}</div>
        )}
      </>
    );
    return (
      <footer>
        <Segment color="black" className="gradient footer">
          <Grid className="container-max-width color-white darken2" fluid>
            <Row>
              <Col xs>
                <Icon name="logo" />
              </Col>
              <Col xs={false} md={3}>
                <ul className="nav no-bullet">
                  {topCommonButtons}
                  <li>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.facebook.com/psychopharmacologyinstitute/"
                    >
                      <Icon color="white" name="facebook" />
                    </a>{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://twitter.com/psychopharminst"
                    >
                      <Icon color="white" name="twitter" />
                    </a>{' '}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://www.linkedin.com/company/psychopharmacology-institute/"
                    >
                      <Icon color="white" name="linkedin" />
                    </a>{' '}
                  </li>
                </ul>
              </Col>
              <Col xs={false} md={3}>
                <ul className="nav no-bullet">{bottomCommonButtons}</ul>
              </Col>
              <Col xs={12} md={false}>
                <ul className="nav no-bullet">
                  {topCommonButtons}
                  {bottomCommonButtons}
                  <li>
                    <Icon color="white" name="facebook" /> <Icon color="white" name="instagram" />{' '}
                    <Icon color="white" name="twitter" /> <Icon color="white" name="linkedin" />{' '}
                  </li>
                </ul>
              </Col>
              {!this.context.isLogged ? (
                <>
                  <Col xs={false} md={5} className="newsletter text-centered">
                    {inputContent}
                  </Col>
                  <Col xs={12} md={false} className="newsletter text-centered">
                    {inputContent}
                  </Col>
                </>
              ) : (
                <Col xs={12} md={5} />
              )}
            </Row>
            <Row>
              <Col xs mdOffset={1}>
                <div className="line">
                  <p className="s">
                    <img
                      className="lazyload"
                      data-src="https://cdn.psychopharmacologyinstitute.com/assets/pa_logo.svg"
                      alt="pa-logo"
                      width={31}
                      height={25}
                    />
                    Visit Psychotherapy Academy.{' '}
                    <Link className="color-black lighten2" href="https://psychotherapyacademy.org">
                      psychotherapyacademy.org
                    </Link>
                  </p>
                </div>
              </Col>
            </Row>
          </Grid>
        </Segment>
      </footer>
    );
  }
}

Footer.contextType = AuthContext;

Footer.propTypes = {
  subscribeNewsletters: PropTypes.func.isRequired,
};

export default connect(
  (store) => ({
    auth: store.auth,
    newsletter: store.newsletter,
  }),
  (dispatch) => ({
    subscribeNewsletters: bindActionCreators(subscribeNewsletters, dispatch),
  })
)(Footer);
