import React from 'react';
import { connect } from 'react-redux';
import store from 'store';
import _ from 'lodash';

import { API_ROOT } from 'config';

import './i18n.css';

const I18n = connect((storeInfo) => ({
  i18n: storeInfo.i18n,
  auth: storeInfo.auth,
}))((props) => {
  const { caseType, attrKey } = props;

  let translate = _.get(props.i18n, `data[${props.translate}][${attrKey}]`, '');
  const id = _.get(props.i18n, `data[${props.translate}].id`, '');

  if (_.isFunction(caseType)) {
    translate = caseType(translate);
  }

  if (!props.auth.user.isSuperuser) {
    return <span data-key={props.translate} dangerouslySetInnerHTML={{ __html: translate }} />;
  }

  return (
    <span className="link-edit-translation-wrapper">
      <a
        className="link-edit-translation"
        href={`${API_ROOT}/admin/translation/translationkey/${id}/change/#/tab/inline_0/`}
        target="_blank"
        rel="noopener noreferrer"
      >
        edit: {props.translate}
      </a>
      <span data-key={props.translate} dangerouslySetInnerHTML={{ __html: translate }} />
    </span>
  );
});

const _t = (labelKey, placheholder = false, caseType = null, replace = {}, attrKey = 'text') => {
  if (placheholder) {
    const translations = store.getState().i18n;
    let retTranslation = false;

    if (_.isFunction(caseType))
      retTranslation = caseType(_.get(translations, `data[${labelKey}][${attrKey}]`, labelKey));
    else retTranslation = _.get(translations, `data[${labelKey}][${attrKey}]`, labelKey);

    if (replace !== {}) {
      retTranslation = _.reduce(
        replace,
        (red, e, key) => red.replace(`{${key}}`, e),
        retTranslation
      );
    }

    return retTranslation;
  }

  return <I18n translate={labelKey} caseType={caseType} attrKey={attrKey} />;
};

const _tr = (labelKey, replace = {}) => {
  return _t(labelKey, true, null, replace);
};

export { _t as default, _tr };
