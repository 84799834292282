import _ from 'lodash';

export const getRedirect = (pathname) => {
  const slug = _.chain(pathname).split('-');
  const isSection = pathname.includes('/section');
  const slugParts = slug.takeRight(isSection ? 2 : 1).value();

  const isPublicationOrSection =
    _.includes(pathname, '/section') || _.includes(pathname, '/publication');

  // eslint-disable-next-line no-restricted-globals
  if (isPublicationOrSection && slug.value().length >= 2 && _.some(slugParts, isNaN)) {
    return null;
  }

  return false;
};
