import React from 'react';
import PropTypes from 'prop-types';

import './full-modal.css';

const FullModal = ({ children }) => {
  return <div className="full-modal">{children}</div>;
};

FullModal.propTypes = {
  children: PropTypes.object.isRequired,
};

export default FullModal;
