import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import _ from 'lodash';

import { cleanErrorPathname } from 'helpers/url';

import Button from 'components/Button';
import FullWidth from 'layouts/FullWidth';
import ErrorNetwork from './ErrorNetwork';

import './errorPage.css';

const messages = {
  403: 'You are not allowed to see this content',
  404: 'Sorry, we can’t find this page.',
  500: 'Internal Server Error',
  502: 'Bad gateway',
  default: 'Oops! Something went wrong. Please contact the administrator.',
};

const ErrorPage = ({ code, message, location }) => {
  const apiMessage = _.get(location, 'state.message', null);

  const codeToShow = code >= 300 ? code : '';

  const SwitchErrorPage = () => {
    const pathname = cleanErrorPathname(_.get(location, 'pathname', ''));
    switch (pathname) {
      case 'error-network':
        return <ErrorNetwork location={location} />;
      case 'error-network-final':
        return <ErrorNetwork location={location} final />;
      default:
        return (
          <FullWidth topbar className="error-page full-height">
            <FullWidth.Content className="hero gradient">
              <Grid className="container-max-width" fluid>
                <Row center="xs">
                  <Col xs={12} md={8}>
                    <h6>Error {codeToShow}</h6>
                    <h2>{message || apiMessage || messages[code] || messages.default}</h2>
                    <Button fill to="/" basic>
                      Go to the homepage
                    </Button>
                  </Col>
                </Row>
              </Grid>
            </FullWidth.Content>
          </FullWidth>
        );
    }
  };

  return <SwitchErrorPage />;
};

ErrorPage.propTypes = {
  code: PropTypes.string.isRequired,
  message: PropTypes.string,
  location: PropTypes.shape({
    state: PropTypes.object,
  }).isRequired,
};

ErrorPage.defaultProps = {
  message: null,
};

export default ErrorPage;
