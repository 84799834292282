import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { actions } from 'react-redux-form';
import { isMobileOnly } from 'react-device-detect';
import _ from 'lodash';
import qs from 'qs';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';

import store from 'store';

import Icon from 'components/Icon';
import Segment from 'components/Segment';
import FullWidth from 'layouts/FullWidth';
import ReferralBar from 'helpers/ReferralBar';
import Button from 'components/Button';
import PodcastPlayer from 'components/PodcastPlayer';
import * as a from 'actions/publications';
import NewsletterBar from '../Home/NewsletterBar';
import Referrer from './Referrer';
import PodcastList from './PodcastList';

import './podcast.css';

const maxCount = 12;
let timeout = null;

const Podcast = ({
  changeField,
  location,
  history,
  fetchPublications: getPublications,
  fetchPodcastsPlayer: getPodcastsPlayer,
  auth,
  publications,
  config,
}) => {
  const [podcastsPlayer, setPodcastsPlayer] = useState(null);
  const CMEtotal = _.get(config, 'CME_SA_TOTAL', null);
  const SAtotal = _.get(config, 'SA_TOTAL', null);

  const getPage = (page, filters = false) => {
    const { forms: currentForms } = store.getState();
    const time = filters ? 1000 : 0;

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      getPublications(page.selected + 1, maxCount, {
        ...currentForms.libraryFilters,
        types: ['PODCAST'],
      });

      const filtersUrl = qs.stringify({
        ...currentForms.libraryFilters,
        page: page.selected + 1,
      });

      history.replace(`/podcasts?${filtersUrl}`);
    }, time);
  };

  useEffect(() => {
    const filters = qs.parse(location.search.replace('?', ''));
    let page = 0;

    if (filters) {
      _.each(filters, (value, name) => {
        if (name === 'page') {
          page = _.parseInt(value, 10) - 1;
          return;
        }
        changeField.change(`libraryFilters.${name}`, value);
      });
    }

    const { forms: currentForms } = store.getState();

    getPodcastsPlayer(1, 100, {
      ...currentForms.libraryFilters,
      types: ['PODCAST'],
    }).then((res) => {
      setPodcastsPlayer(res);
      getPage({ selected: page }, false);
    });
  }, []);

  return (
    <FullWidth id="podcast" topbar>
      <Segment className="hero gradient gradient-brand" color="black">
        <Grid className="container-max-width" fluid>
          <Row>
            <Col xs={12} md={8}>
              <h5 className="color-white subtitle">
                <Icon name="PODCAST" />
                The Psychopharmacology Institute Podcast
              </h5>
              <h1 className="color-white title">Psychiatry and Psychopharmacology Podcast</h1>
            </Col>
          </Row>
        </Grid>
        {/* <img
          className=" ls-is-cached lazyloaded"
          data-src="https://cdn.psychopharmacologyinstitute.com/assets/icons/tablet.png"
          alt="Psychiatry &amp; Psychopharmacology CME "
          src="https://cdn.psychopharmacologyinstitute.com/assets/icons/tablet.png"
        /> */}
      </Segment>
      {auth && !auth.isLogged && !auth.isFetching ? (
        <Segment className="container-promotion">
          <Grid className="container-max-width" fluid>
            <Row>
              <Col xs={12} md={6} className="promotion">
                <p>
                  {' '}
                  Visit us at{' '}
                  <a href="https://psychopharmacologyinstitute.com/">
                    {' '}
                    psychopharmacologyinstitute.com/{' '}
                  </a>{' '}
                  where you can find more podcasts and some lectures absolutely free
                </p>
                {CMEtotal && SAtotal && (
                  <p>
                    You can also become a Silver or Gold member to get full access, and{' '}
                    <b>earn up to {config.CME_SA_TOTAL} CMEs</b> , including {config.SA_TOTAL} SAs.
                  </p>
                )}
                <Button fill color="green" to="/checkout">
                  Get instant access
                </Button>
              </Col>
            </Row>
          </Grid>
        </Segment>
      ) : null}
      <PodcastPlayer podcasts={podcastsPlayer} />
      <Referrer />
      <NewsletterBar />
      <ReferralBar auth={auth} />
      <PodcastList
        publications={publications}
        maxCount={maxCount}
        isMobileOnly={isMobileOnly}
        getPage={getPage}
      />
    </FullWidth>
  );
};

Podcast.propTypes = {
  changeField: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  fetchPublications: PropTypes.func.isRequired,
  fetchPodcastsPlayer: PropTypes.func.isRequired,
  publications: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  config: PropTypes.object.isRequired,
};

export default connect(
  (reduxStore) => ({
    forms: reduxStore.forms,
    publications: reduxStore.publications,
    auth: reduxStore.auth,
    config: reduxStore.config.data,
  }),
  (dispatch) => ({
    changeField: bindActionCreators(actions, dispatch),
    fetchPublications: bindActionCreators(a.fetchPublications, dispatch),
    fetchPodcastsPlayer: bindActionCreators(a.fetchPodcastsPlayer, dispatch),
  })
)(Podcast);
