import toLower from 'lodash/toLower';
import { browserName, browserVersion } from 'react-device-detect';

/**
 * ElasticSearchBar browser compatibility check
 * IE - not supported
 * Chrome - supported versions >= 42
 * Firefox - supported versions >= 40
 * Safari - supported versions >= 10.1
 * Safari Mobile - supported versions >= 10
 * Edge - supported versions >= 14
 */
export const checkElasticSearchBrowserCompatibility = () => {
  const browser = toLower(browserName);
  const version = parseFloat(browserVersion);
  if (!browser || !version) return false;

  if (browser === 'ie') {
    return false;
  }

  if (browser === 'chrome') {
    return version >= 42;
  }

  if (browser === 'firefox') {
    return version >= 40;
  }

  if (browser === 'safari') {
    return version >= 10.1;
  }

  if (browser === 'mobile safari') {
    return version >= 10;
  }

  if (browser === 'edge') {
    return version >= 14;
  }

  return true;
};
