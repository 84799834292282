import React from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const Image = ({ src, alt, className, ...restOfProps }) => {
  return <LazyLoadImage wrapperClassName={className} alt={alt} src={src} {...restOfProps} />;
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
};

Image.defaultProps = {
  alt: '',
  className: '',
};
export default Image;
