import React from 'react';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

import 'react-loading-skeleton/dist/skeleton.css';

const config = {
  duration: 1.5,
  width: '100%',
  baseColor: '#f5f5fa87',
  highlightColor: '#F5F5FA',
};

const SkeletonElement = (props) => {
  const { duration, width, baseColor, highlightColor } = config;
  return (
    <SkeletonTheme
      duration={duration}
      width={width}
      baseColor={baseColor}
      highlightColor={highlightColor}
    >
      <Skeleton {...props} />
    </SkeletonTheme>
  );
};

export default SkeletonElement;
