import axios from 'axios';
import qs from 'qs';
import _ from 'lodash';
import history, { getRedirectParam } from 'helpers/history';

import { API_ROOT, getJwtToken, KEY_JWT_TOKEN } from 'config';

export const PpAPI = axios.create({
  baseURL: API_ROOT,
  timeout: 30000,
  headers: {
    Accept: 'application/vnd.api+json',
    'Content-Type': 'application/vnd.api+json',
  },
});

PpAPI.interceptors.request.use((config) => {
  const search = qs.parse(window.location.search.replace('?', ''));
  let token = getJwtToken();
  // [TODO] Check if token is valid
  // [TODO] Refresh token if it is needed
  const dataInfo = config.data ? config.data.data : null;

  if (dataInfo && _.isArray(dataInfo) && config.method === 'post') {
    config.headers['Content-type'] = 'application/vnd.api+json;ext=bulk';
  }

  token = token === 'undefined' || !token ? _.get(search, 'token', false) : token;

  if (token) {
    localStorage.setItem(KEY_JWT_TOKEN, token);
    config.headers.common.Authorization = `JWT ${token}`;
    config.headers.common['Jwt-Authorization'] = `${token}`;
  }
  return config;
});

PpAPI.interceptors.response.use(
  (response) => {
    if (response.headers['jwt-authorization']) {
      localStorage.setItem(KEY_JWT_TOKEN, response.headers['jwt-authorization']);
      if (_.get(response, 'data.data.type', false) !== 'OrderPayment') {
        window.location = getRedirectParam();
      }
    }
    return response;
  },
  (error) => {
    const status = _.get(error, 'response.status', null);
    const message = _.get(error, 'response.data.errors[0].detail', null);

    if (status && status >= 500) {
      history.push({
        pathname: `/error-${status}`,
        state: { message },
      });
    }
    return Promise.reject(error);
  }
);
