import { PpAPI } from 'api';

import { GIFT_CARDS_FETCHING, GIFT_CARDS_FETCHED } from 'reducers/giftcards';

export const _setGiftCards = (data) => ({
  type: GIFT_CARDS_FETCHED,
  data,
});

const fetchedGiftCards = ({ giftCards, count, page }) => ({
  type: GIFT_CARDS_FETCHED,
  giftCards,
  count,
  page,
});

const fetchingGiftCards = () => ({
  type: GIFT_CARDS_FETCHING,
});

export const fetchGiftCards =
  (size = 20, page = 1) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(fetchingGiftCards());

      const params = {
        'page[size]': size,
        'page[number]': page,
      };

      PpAPI.get(`/api/giftcards/`, {
        params,
      })
        .then((res) => {
          const result = {
            giftCards: res.data.data,
            count: res.data.meta.pagination.count,
            page,
          };
          resolve(result);
          dispatch(fetchedGiftCards(result));
        })
        .catch((err) => {
          reject(err);
        });
    });
