import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import _t from 'i18n';
import _ from 'lodash';
import { connect } from 'react-redux';

import Segment from 'components/Segment';
import Loading from 'components/Loading';

import './index.css';

const CmeInformation = ({ scrollId, i18n }) => {
  const [activeStatements, setActiveStatements] = useState({});
  const [statements, setStatements] = useState([]);

  useEffect(() => {
    if (!_.isEmpty(i18n.data) && !i18n.isFetching && !statements.length) {
      let c = 1;
      const newStatements = [];
      while (_t(`CME_INFORMATION_VOL_${c}`, true) !== `CME_INFORMATION_VOL_${c}`) {
        newStatements.push({
          volume: c,
          content: `CME_INFORMATION_VOL_${c}`,
        });
        c++;
      }
      setStatements(newStatements);
    }
  }, [i18n]);

  return (
    <Segment size="s" className="bg-lighten-1 cme-information">
      <Grid className="container-max-width" fluid>
        <Row className="toggle-title">
          <Col xs={12}>
            <h3 id="accreditation">CME Information</h3>
          </Col>
        </Row>
        <Row center="xs" className="toggle-content">
          {i18n.isFetching ? (
            <Loading centered active />
          ) : (
            _.map(statements, (statement) => (
              <Col xs={12} key={statement.volume}>
                <div
                  role="button"
                  tabIndex={-1}
                  onKeyDown={null}
                  className="toggle-title color-black lighten1"
                  onClick={() =>
                    setActiveStatements((prevActiveStatements) => ({
                      ...prevActiveStatements,
                      [statement.volume]: !prevActiveStatements[statement.volume],
                    }))
                  }
                >
                  <h6>{_t(statement.content, false, null, {}, 'description')}</h6>
                  <span
                    className={`icon ${
                      activeStatements[statement.volume] ? 'icon-chevron_up' : 'icon-chevron_down'
                    }`}
                  />
                </div>
                {activeStatements[statement.volume] && (
                  <div className="statements-content">{_t(statement.content)}</div>
                )}
              </Col>
            ))
          )}
        </Row>
        {scrollId && <div id={scrollId} />}
      </Grid>
    </Segment>
  );
};

CmeInformation.propTypes = {
  scrollId: PropTypes.string,
  i18n: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.object,
  }).isRequired,
};
CmeInformation.defaultProps = { scrollId: null };

export default connect(
  (store) => ({
    i18n: store.i18n,
  }),
  () => ({})
)(CmeInformation);
