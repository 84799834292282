export const I18N_FETCHING = 'I18N_FETCHING';
export const I18N_FETCHED = 'I18N_FETCHED';

const i18nInit = {
  isFetching: false,
  data: {},
};

export const i18n = (i18nInfo = i18nInit, action) => {
  switch (action.type) {
    case I18N_FETCHED:
      return {
        ...i18nInfo,
        data: action.data,
        isFetching: false,
      };

    case I18N_FETCHING:
      return {
        ...i18nInfo,
        isFetching: true,
      };

    default:
      return i18nInfo;
  }
};
