/* eslint-disable max-classes-per-file */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import classNames from 'classnames';

import Button from 'components/Button';
import Link from 'components/Link';
import Icon from 'components/Icon';
import Segment from 'components/Segment';
import FullWidth from 'layouts/FullWidth';
import { validEmail, validPasswordLength, validRepeatPassword, errorMessages } from 'helpers/forms';
import { sendPasswordReset, createNewPassword } from 'actions/auth';
import { toast } from 'react-toastify';

import './signin.css';

export class ChangePassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      repeatPassword: '',
      error: false,
      loading: false,
    };

    this.validateForm = this.validateForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();

    this.setState({
      loading: true,
    });

    createNewPassword(
      this.state.password,
      this.state.repeatPassword,
      this.props.match.params.uid,
      this.props.match.params.token
    )
      .then((response) => {
        toast.success(_.get(response, 'data.data.detail'));
        this.props.history.push('/signin');
      })
      .catch((err) => {
        this.setState({
          error: _.get(err, 'response.data.errors[0].detail', 'Error'),
          loading: false,
        });
      });

    return false;
  }

  validateForm() {
    return (
      this.state.password.length > 0 &&
      this.state.repeatPassword.length > 0 &&
      validPasswordLength(this.state.password) &&
      validRepeatPassword(this.state.password, this.state.repeatPassword)
    );
  }

  render() {
    return (
      <FullWidth id="signin" className="full-height">
        <FullWidth.Content className="bg-color-white">
          <div id="left" className="bg-color-white bg-lighten1">
            <Link to="/" className="psycho_logo">
              <Icon name="logo" color="aqua" />
              <Icon name="logo_text" />
            </Link>
            <Segment id="form">
              <h4>Create a new password</h4>
              <form onSubmit={this.onSubmit}>
                <input
                  type="password"
                  onChange={(e) => this.setState({ password: e.target.value })}
                  value={this.state.password}
                  placeholder="Password"
                  className={classNames('input', {
                    error:
                      this.state.password.length > 0 && !validPasswordLength(this.state.password),
                  })}
                />
                {this.state.password.length > 0 && !validPasswordLength(this.state.password) && (
                  <div className="input-legend error">{errorMessages.validPasswordLength}</div>
                )}
                <input
                  type="password"
                  onChange={(e) => this.setState({ repeatPassword: e.target.value })}
                  value={this.state.repeatPassword}
                  placeholder="Repeat password"
                  className={classNames('input', {
                    error:
                      this.state.repeatPassword.length > 0 &&
                      !validRepeatPassword(this.state.password, this.state.repeatPassword),
                  })}
                />
                {this.state.repeatPassword.length > 0 &&
                  !validRepeatPassword(this.state.password, this.state.repeatPassword) && (
                    <div className="input-legend error">{errorMessages.validRepeatPassword}</div>
                  )}
                {this.state.error !== false && (
                  <div className="form-legend error">
                    <Icon name="cross" /> {this.state.error}
                  </div>
                )}

                <Segment size="s">
                  <Button
                    fill
                    color="violet"
                    disabled={!this.validateForm() || this.state.loading}
                    loading={this.state.loading}
                  >
                    Create Password
                  </Button>
                </Segment>
              </form>
            </Segment>
          </div>
          <div id="right">
            <Segment>
              <h4>Not have an account yet?</h4>
              <p>
                Register for free in just a minute and recive notifications when content is
                published, email updates, keep track of your activities and more.
              </p>

              <Button link main color="violet" to="/register">
                Register for free now
              </Button>
            </Segment>
          </div>
        </FullWidth.Content>
      </FullWidth>
    );
  }
}

ChangePassword.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      uid: PropTypes.string,
      token: PropTypes.string,
    }),
  }).isRequired,
};

class RecoverPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      error: false,
      loading: false,
      step: 1,
    };

    this.validateForm = this.validateForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    sendPasswordReset(this.state.email)
      .then(() => {
        this.setState({
          step: 2,
        });
      })
      .catch((err) => {
        this.setState({
          error: _.get(err, 'response.data.errors[0].detail', 'Error'),
          loading: false,
        });
      });

    return false;
  }

  validateForm() {
    return validEmail(this.state.email);
  }

  render() {
    return (
      <FullWidth id="signin" className="full-height">
        <FullWidth.Content className="bg-color-white">
          <div id="left" className="bg-color-white bg-lighten1">
            <Link to="/" className="psycho_logo">
              <Icon name="logo" color="aqua" />
              <Icon name="logo_text" />
            </Link>
            <Segment id="form">
              {this.state.step === 1 ? (
                <>
                  <h4>Forgot your password?</h4>
                  <p>
                    If you do not remember your password, enter your email and we send you a link
                    where you can create a new one.
                  </p>
                  <form onSubmit={this.onSubmit}>
                    <input
                      type="text"
                      onChange={(e) => this.setState({ email: e.target.value, error: false })}
                      value={this.state.email}
                      placeholder="Email"
                      className={classNames('input', {
                        error: this.state.email.length > 0 && !this.validateForm(),
                      })}
                    />
                    {this.state.email.length > 0 && !this.validateForm() && (
                      <div className="input-legend error">{errorMessages.validEmail}</div>
                    )}
                    {this.state.error !== false && (
                      <div className="form-legend error">
                        <Icon name="cross" /> {this.state.error}
                      </div>
                    )}

                    <Segment size="s">
                      <Button
                        fill
                        color="violet"
                        disabled={!this.validateForm() || this.state.loading}
                        loading={this.state.loading}
                      >
                        Send
                      </Button>
                      <Button link main to="/" color="violet">
                        Cancel
                      </Button>
                    </Segment>
                  </form>
                </>
              ) : (
                <>
                  <h4>Check your email</h4>
                  <p>We&#39;ve just emailed you instructions on how to reset your password.</p>
                </>
              )}
            </Segment>
          </div>
          <div id="right">
            <Segment>
              <h4>Not have an account yet?</h4>
              <p>
                Register for free in just a minute and recive notifications when content is
                published, email updates, keep track of your activities and more.
              </p>

              <Button link main color="violet" to="/register">
                Register for free now
              </Button>
            </Segment>
          </div>
        </FullWidth.Content>
      </FullWidth>
    );
  }
}

export default RecoverPassword;
