export const REFERRALS_FETCHING = 'REFERRALS_FETCHING';
export const REFERRALS_FETCHED = 'REFERRALS_FETCHED';

const referralsInit = {
  count: 0,
  page: null,
  data: [],
  isFetching: false,
};

export const referrals = (referralsInfo = referralsInit, action) => {
  switch (action.type) {
    case REFERRALS_FETCHED:
      if (action.isFromReferUser) {
        return {
          data: action.referrals.concat(referralsInfo.data),
          count: referralsInfo.count + 1,
          page: referralsInfo.page,
          isFetching: false,
        };
      }
      return {
        data: referralsInfo.data.concat(action.referrals),
        count: action.count,
        page: action.page,
        isFetching: false,
      };

    case REFERRALS_FETCHING:
      return {
        ...referralsInfo,
        isFetching: true,
      };

    default:
      return referralsInfo;
  }
};
