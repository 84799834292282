import React from 'react';
import _ from 'lodash';
import { Grid, Row, Col } from 'react-styled-flexboxgrid';
import PropTypes from 'prop-types';
import ReactPaginate from 'react-paginate';

import Card from 'components/Card';
import FullWidth from 'layouts/FullWidth';
import Footer from 'components/Footer';
import Icon from 'components/Icon';
import Loading from 'components/Loading';
import Segment from 'components/Segment';

const PodcastList = ({ publications, isMobileOnly, maxCount, getPage }) => {
  return (
    <FullWidth.Content className="bg-color-white">
      <Segment>
        <Grid className="container-max-width" fluid>
          <Row>
            <Col xs={12} md={6}>
              <div className="container-download-text">
                <h4>
                  Download the episodes or get the transcript and PDF version from our website:
                </h4>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs>
              <Loading
                centered
                active={publications?.data?.length === 0 || publications.isFetching}
              >
                Loading
              </Loading>
              <Card.Group className="publications">
                {_.map(publications.data, (publication) => (
                  <Card
                    key={`publication_${publication.id}`}
                    size="small"
                    publication={publication}
                    author={publication.author}
                    to={`/publication/${publication.slug}`}
                  />
                ))}
              </Card.Group>
              <ReactPaginate
                containerClassName="pagination"
                pageCount={Math.ceil(publications.pagination.count / maxCount)}
                pageRangeDisplayed={isMobileOnly ? 3 : 5}
                marginPagesDisplayed={0}
                forcePage={publications.pagination.page - 1}
                onPageChange={getPage}
                previousLabel={<Icon name="chevron_left" />}
                nextLabel={<Icon name="chevron_right" />}
              />
            </Col>
          </Row>
        </Grid>
      </Segment>
      <Footer />
    </FullWidth.Content>
  );
};

PodcastList.propTypes = {
  publications: PropTypes.object.isRequired,
  maxCount: PropTypes.number.isRequired,
  isMobileOnly: PropTypes.bool.isRequired,
  getPage: PropTypes.func.isRequired,
};

export default PodcastList;
