import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';

import './style.scss';

const Icon = (props) => {
  const { active, border, className, color, inverted, link, name, onClick, rotate, size, style } =
    props;

  const _className = classNames('icon', `icon-${name}`, size, className, {
    [`color-${color}`]: color,
    [`rotate-${rotate}`]: rotate,
    active,
    inverted,
  });

  if (onClick || border) {
    return (
      <span
        style={style}
        onClick={onClick}
        onKeyDown={() => {}}
        role="button"
        tabIndex={-1}
        className={classNames('icon-wrapper', { border, 'icon-link': link })}
      >
        <span className={_className}>{props.children}</span>
      </span>
    );
  }

  return (
    <span style={style} className={_className}>
      {props.children}
    </span>
  );
};

Icon.propTypes = {
  name: PropTypes.oneOf([
    'format-wv',
    'format-wma',
    'format-webm',
    'format-wav',
    'format-vox',
    'format-tta',
    'format-sln',
    'format-raw',
    'format-ra',
    'format-opus',
    'format-ogg',
    'format-nsf',
    'format-nmf',
    'format-msv',
    'format-mpc',
    'format-mp3',
    'format-mmf',
    'format-m4p',
    'format-m4b',
    'format-m4a',
    'format-ivs',
    'format-iklax',
    'format-gsm',
    'format-flac',
    'format-dvf',
    'format-dss',
    'format-dct',
    'format-awb',
    'format-au',
    'format-ape',
    'format-amr',
    'format-aiff',
    'format-act',
    'format-aax',
    'format-aac',
    'format-aa',
    'format-8svx',
    'format-3gp',
    'format-3gp',
    'format-3g2',
    'format-asf',
    'format-amv',
    'format-avi',
    'format-drc',
    'format-flv',
    'format-flv',
    'format-flv',
    'format-gif',
    'format-m4v',
    'format-mxf',
    'format-mkv',
    'format-mts',
    'format-mpg',
    'format-mpg',
    'format-mp4',
    'format-mng',
    'format-nsv',
    'format-ogv',
    'format-mov',
    'format-yuv',
    'format-rm',
    'format-rmvb',
    'format-roq',
    'format-svi',
    'format-gifv',
    'format-vob',
    'format-webm',
    'format-wmv',
    'format-pdf',
    'format-docs',
    'format-docx',
    'format-docm',
    'format-xml',
    'format-dotx',
    'format-dotm',
    'format-ppt',
    'format-pptx',
    'format-pptm',
    'format-ppt',
    'format-ppt',
    'information',
    'account',
    'amex',
    'arrow_down',
    'arrow_left',
    'arrow_right',
    'arrow_up',
    'calendar',
    'category',
    'certification',
    'check',
    'chevron_down',
    'chevron_left',
    'chevron_right',
    'chevron_up',
    'completed',
    'cross',
    'delete',
    'download',
    'help',
    'info',
    'PUBLICATION_INTERVIEW',
    'lock',
    'logo_full',
    'logo_text',
    'logo',
    'mastercard',
    'menu',
    'SELF_ASSESSMENT',
    'notification',
    'OPEN_ACCESS',
    'paypal',
    'PODCAST',
    'PUBLICATION_QUICK_TAKE',
    'PUBLICATION_CAP_ST',
    'save',
    'saved',
    'search',
    'sidebar',
    'stripe',
    'time',
    'PUBLICATION_VIDEO',
    'visa',
    // [TODO] add social network icons to typography
    'facebook',
    'instagram',
    'twitter',
    'linkedin',
    'loading',
  ]).isRequired,
  active: PropTypes.bool,
  border: PropTypes.bool,
  className: PropTypes.string,
  color: PropTypes.string,
  inverted: PropTypes.bool,
  link: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClick: PropTypes.func,
  rotate: PropTypes.number,
  size: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array, PropTypes.string]),
};

Icon.defaultProps = {
  size: '',
  color: '',
  className: '',
  rotate: 0,
  active: false,
  inverted: false,
  border: false,
  link: undefined,
  onClick: undefined,
  style: {},
  children: null,
};

export default Icon;
