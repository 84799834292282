import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'rc-tooltip';
import get from 'lodash/get';

import 'rc-tooltip/assets/bootstrap.css';
import './tooltip.css';

const TooltipWrapper = (props) => {
  const config = {
    placement: 'top',
    animation: 'zoom',
  };
  return (
    <Tooltip
      {...props}
      placement={config.placement}
      animation={config.animation}
      overlay={get(props, 'overlay', '')}
    >
      {props.children}
    </Tooltip>
  );
};

TooltipWrapper.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
};

export default TooltipWrapper;
