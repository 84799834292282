export const CONFIG_FETCHING = 'CONFIG_FETCHING';
export const CONFIG_FETCHED = 'CONFIG_FETCHED';

const configInit = {
  data: {},
  isFetching: false,
};

export const config = (configInfo = configInit, action) => {
  switch (action.type) {
    case CONFIG_FETCHED:
      return {
        data: action.config,
        isFetching: false,
      };

    case CONFIG_FETCHING:
      return {
        ...configInfo,
        isFetching: true,
      };

    default:
      return configInfo;
  }
};
