export const MEMBERSHIPS_FETCHING = 'MEMBERSHIPS_FETCHING';
export const MEMBERSHIPS_FETCHED = 'MEMBERSHIPS_FETCHED';
export const RESET_MEMBERSHIPS = 'RESET_MEMBERSHIPS';

const membershipsInit = {
  data: [],
  isFetching: false,
};

export const memberships = (membershipsInfo = membershipsInit, action) => {
  switch (action.type) {
    case MEMBERSHIPS_FETCHED:
      return {
        data: action.memberships,
        isFetching: false,
      };

    case MEMBERSHIPS_FETCHING:
      return {
        ...membershipsInfo,
        isFetching: true,
      };

    case RESET_MEMBERSHIPS:
      return membershipsInit;

    default:
      return membershipsInfo;
  }
};
