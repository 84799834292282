import React, { Fragment } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import moment from 'moment';

import Button from 'components/Button';
import PublicationIcon from 'components/PublicationIcon';

import Loading from 'components/Loading';
import { membershipsToPublicationTypeStringInCalendar } from 'helpers/membership';
import './calendaritem.css';

const _CalendarItem = (props) => {
  const { publication, className } = props;

  const _className = classNames('calendar-item', 'bg-color-white', 'bg-lighten1', className);
  const membershipType = membershipsToPublicationTypeStringInCalendar(publication.type);
  return (
    <div className={_className}>
      <div>
        <div className="icon-wrapper">
          <PublicationIcon publicationType={publication.type} />
        </div>
        <div className="title-author-content">
          <h6 className="color-black lighten1">{publication.title}</h6>
          {publication.author && (
            <span className="color-black lighten2">{`${publication.author.fullNameAffix} ${publication.author.institute}`}</span>
          )}
        </div>
      </div>
      <div>
        <Button className="membershipLabel" to="/cme-program?section=pricing">
          {membershipType}
        </Button>
      </div>
    </div>
  );
};

_CalendarItem.propTypes = {
  className: PropTypes.string,
  publication: PropTypes.shape({
    type: PropTypes.string,
    title: PropTypes.string,
    author: PropTypes.shape({
      fullNameAffix: PropTypes.string,
      institute: PropTypes.string,
    }),
  }).isRequired,
};

_CalendarItem.defaultProps = {
  className: '',
};

export const CalendarItem = _CalendarItem;

export const CalendarList = (props) => {
  const { publications } = props;

  let lastPublicationDate = null;
  return (
    <>
      {_.map(publications.data, (publication) => {
        const publicationDate = moment(publication.publicationDate);

        const ret = (
          <Fragment key={publication.id}>
            {publicationDate.isValid() &&
              (lastPublicationDate === null ||
                publicationDate.month() !== lastPublicationDate.month() ||
                publicationDate.year() !== lastPublicationDate.year()) && (
                <div className="bg-color-white darken1 month">
                  <h6 className="color-black lighten2">{publicationDate.format('MMMM YYYY')}</h6>
                </div>
              )}
            <CalendarItem publication={publication} />
          </Fragment>
        );
        lastPublicationDate = publicationDate;

        return ret;
      })}
      <Loading centered active={publications.isFetching} />
    </>
  );
};

CalendarList.propTypes = {
  publications: PropTypes.shape({
    isFetching: PropTypes.bool,
    data: PropTypes.array,
  }).isRequired,
};
