import Cookies from 'universal-cookie';
import { PpAPI } from 'api';

const cookies = new Cookies();

export const sendUtm = (utmParameters) =>
  new Promise((resolve, reject) => {
    const data = { data: utmParameters };
    PpAPI.post('/api/utms/', data)
      .then((res) => {
        cookies.remove('utmParameters');
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
