import _ from 'lodash';

import { TEST_RESULTS_SET_ANSWER } from 'reducers/testResults';

import { deserializeRelationship } from 'actions/relationships';

const deserializeAnswer = (answer) => {
  return {
    id: answer.id,
    ...answer.attributes,
  };
};

export const deserializeQuestion = (question, included = null) => {
  const answers = deserializeRelationship(
    question.relationships.answers,
    included,
    deserializeAnswer
  );
  const testResults = _.find(included, { type: 'testResults' });
  let answerChecked = false;

  if (testResults) {
    answerChecked = _.chain(testResults.results)
      .find({ question_id: question.id })
      .get('answer_id', false)
      .value();
  }

  return {
    id: question.id,
    ...question.attributes,
    answers,
    answerChecked,
  };
};

export const setAnswer = (pId, qId, aId) => ({
  type: TEST_RESULTS_SET_ANSWER,
  pId,
  qId,
  aId,
});
