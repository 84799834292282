import React from 'react';
import { PropTypes } from 'prop-types';

import Topbar from 'components/Topbar';
import Loading from 'components/Loading';
import './full-width.css';

const Content = (props) => {
  const { id, className } = props;

  return (
    <div id={id} className={`${className} layout-full-width_content`}>
      {props.children}
    </div>
  );
};

Content.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired,
};

Content.defaultProps = {
  className: '',
  id: '',
};

const FullWidth = (props) => {
  const { id, className, center, topbar, loading } = props;

  return (
    <div
      id={id || ''}
      className={`${className || ''} layout-full-width_container ${center && 'center'}`}
    >
      {topbar && (
        <div className="layout-full-width_topbar">
          <Topbar {...topbar} />
        </div>
      )}

      <div className={`layout-full-width_content_container ${loading && 'loading'}`}>
        {loading === true ? <Loading centered active /> : props.children}
      </div>
    </div>
  );
};

FullWidth.Content = Content;

FullWidth.propTypes = {
  center: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  topbar: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
};

FullWidth.defaultProps = {
  center: false,
  loading: false,
  className: '',
  id: '',
  topbar: null,
  children: null,
};

export default FullWidth;
