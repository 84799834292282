/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Control, Form, actions } from 'react-redux-form';
import classNames from 'classnames';

import Button from 'components/Button';
import Icon from 'components/Icon';
import Segment from 'components/Segment';
import FullWidth from 'layouts/FullWidth';
import { signin as siginAction } from 'actions/auth';
import { validEmail, validPasswordLength, errorMessages } from 'helpers/forms';
import { goToRedirectOrGoTo } from 'helpers/history';

import './signin.css';

class Signin extends React.Component {
  componentDidMount() {
    const { auth } = this.props;
    if (auth.isLogged) {
      goToRedirectOrGoTo();
    }
  }

  componentDidUpdate() {
    const { auth } = this.props;
    if (auth.isLogged) {
      goToRedirectOrGoTo();
    }
  }

  onSubmit = () => {
    const { forms, signin } = this.props;

    signin(forms.signin.username, forms.signin.password).catch((err) => {
      this.props.changeField.change(
        'signin.error',
        _.map(
          _.get(err, 'response.data.errors', [{ detail: 'Error' }]),
          (item) => item.detail
        ).join(',')
      );
    });
  };

  validateForm = () => {
    const { forms } = this.props;
    return validEmail(forms.signin.username) && validPasswordLength(forms.signin.password);
  };

  render() {
    const { auth, forms } = this.props;

    return (
      <FullWidth topbar id="signin" className="full-height">
        <FullWidth.Content className="bg-color-white">
          <div id="left" className="bg-color-white bg-lighten1">
            <Segment id="form">
              <h4>Welcome back!</h4>
              <Form model="signin">
                <Control.text
                  className={classNames({
                    error: forms.signin.username.length > 0 && !validEmail(forms.signin.username),
                  })}
                  model=".username"
                  placeholder="Email"
                />
                {forms.signin.username.length > 0 && !validEmail(forms.signin.username) && (
                  <div className="input-legend error">{errorMessages.validEmail}</div>
                )}
                <Control.text
                  className={classNames({
                    error:
                      forms.signin.password.length > 0 &&
                      !validPasswordLength(forms.signin.password),
                  })}
                  model=".password"
                  placeholder="Password"
                  type="password"
                />
                {forms.signin.error !== false && (
                  <div className="form-legend error">
                    <Icon name="cross" /> {forms.signin.error}
                  </div>
                )}
                <Segment size="s">
                  <Button
                    fill
                    color="violet"
                    disabled={auth.isFetching || !this.validateForm()}
                    loading={auth.isFetching}
                    onClick={this.onSubmit}
                  >
                    Sign In
                  </Button>
                  <Button link main to="/recover-password" color="violet">
                    Forgot your password?
                  </Button>
                </Segment>
              </Form>
            </Segment>
          </div>
          <div id="right">
            <Segment>
              <h4>Not a member yet?</h4>
              <p>Register for free in less than a minute and get exclusive updates.</p>

              <Button link main color="violet" to="/register">
                Register for free now
              </Button>
            </Segment>
          </div>
        </FullWidth.Content>
      </FullWidth>
    );
  }
}

Signin.propTypes = {
  auth: PropTypes.shape({
    user: PropTypes.shape({
      canGetCredits: PropTypes.bool,
    }),
    isFetching: PropTypes.bool,
    isLogged: PropTypes.bool,
  }).isRequired,
  changeField: PropTypes.object.isRequired,
  forms: PropTypes.shape({
    signin: PropTypes.shape({
      username: PropTypes.string,
      password: PropTypes.string,
      error: PropTypes.bool,
    }),
  }).isRequired,
  signin: PropTypes.func.isRequired,
};

export default withRouter(
  connect(
    (store) => ({
      auth: store.auth,
      forms: store.forms,
    }),
    (dispatch) => ({
      signin: bindActionCreators(siginAction, dispatch),
      changeField: bindActionCreators(actions, dispatch),
    })
  )(Signin)
);
