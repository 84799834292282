import _ from 'lodash';

import { PpAPI } from 'api';
import {
  AUTHORS_FETCHING,
  AUTHORS_FETCHED,
  AUTHOR_FETCHING,
  AUTHOR_FETCHED,
} from 'reducers/authors';
import { deserializeRelationship } from 'actions/relationships';
import { deserializeFile } from 'actions/files';

export const fetchedAuthors = (authors) => ({
  type: AUTHORS_FETCHED,
  authors,
});

export const fetchingAuthors = () => ({
  type: AUTHORS_FETCHING,
});

export const fetchedAuthor = (author) => ({
  type: AUTHOR_FETCHED,
  author,
});

export const fetchingAuthor = () => ({
  type: AUTHOR_FETCHING,
});

export const deserializeAuthor = (user, included = null) => {
  return {
    id: user.id,
    ...user.attributes,
    image: deserializeRelationship(user.relationships.image, included, deserializeFile),
  };
};

export const fetchAuthors =
  (page = 1, pageSize = 10, filters = {}) =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch(fetchingAuthors());
      PpAPI.get('/api/authors/', {
        params: {
          ...filters,
          'page[size]': pageSize,
          'page[number]': page,
          include: 'image',
        },
      })
        .then((res) => {
          const authors = _.map(res.data.data, (author) =>
            deserializeAuthor(author, res.data.included)
          );
          dispatch(fetchedAuthors(authors));
          resolve(authors);
        })
        .catch(reject);
    });

export const fetchAuthor = (cId) => (dispatch) =>
  new Promise((resolve, reject) => {
    dispatch(fetchingAuthor());
    PpAPI.get(`/api/authors/${cId}/?include=image`)
      .then((res) => {
        const author = deserializeAuthor(res.data.data, res.data.included);

        dispatch(fetchedAuthor(author));
        resolve(author);
      })
      .catch(reject);
  });
