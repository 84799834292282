import React from 'react';

import { ElasticSearchBarMobile } from 'components/ElasticSearchBar';

import { closeModal } from 'actions/modals';

import './search-modal.css';

const Search = () => {
  const handleGoBack = () => closeModal('searchMobile-modals');

  return (
    <div className="search-container">
      <div className="content">
        <div className="searchbar">
          <ElasticSearchBarMobile />
        </div>
        <div className="cancel-button" onClick={handleGoBack}>
          Cancel
        </div>
      </div>
    </div>
  );
};

export default Search;
