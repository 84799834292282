export const CATEGORIES_FETCHING = 'CATEGORIES_FETCHING';
export const CATEGORIES_FETCHED = 'CATEGORIES_FETCHED';
export const CATEGORIES_FEATURED_FETCHING = 'CATEGORIES_FEATURED_FETCHING';
export const CATEGORIES_FEATURED_FETCHED = 'CATEGORIES_FEATURED_FETCHED';
export const CATEGORY_FETCHED = 'CATEGORY_FETCHED';
export const CATEGORY_FETCHING = 'CATEGORY_FETCHING';

const categoriesDefault = {
  data: [],
  isFetching: false,
};

export const categories = (categoriesInfo = categoriesDefault, action) => {
  switch (action.type) {
    case CATEGORIES_FETCHING:
      return {
        ...categoriesInfo,
        isFetching: true,
      };
    case CATEGORIES_FETCHED:
      return {
        data: action.categories,
        isFetching: false,
      };
    default:
      return categoriesInfo;
  }
};

const featuredCategoriesDefault = {
  data: [],
  isFetching: false,
};

export const featuredCategories = (categoriesInfo = featuredCategoriesDefault, action) => {
  switch (action.type) {
    case CATEGORIES_FEATURED_FETCHING:
      return {
        ...categoriesInfo,
        isFetching: true,
      };
    case CATEGORIES_FEATURED_FETCHED:
      return {
        data: action.categories,
        isFetching: false,
      };
    default:
      return categoriesInfo;
  }
};

const categoryDefault = {
  data: {},
  isFetching: false,
};

export const category = (categoryInfo = categoryDefault, action) => {
  switch (action.type) {
    case CATEGORY_FETCHING:
      return {
        ...categoryInfo,
        isFetching: true,
      };
    case CATEGORY_FETCHED:
      return {
        data: action.category,
        isFetching: false,
      };
    default:
      return categoryInfo;
  }
};
