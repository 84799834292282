import React, { useState, useEffect } from 'react';
import get from 'lodash/get';
import { isMobileOnly } from 'react-device-detect';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import VisibilitySensor from 'react-visibility-sensor';

import Button from '../../Button';
import Icon from '../../Icon';

import './index.css';

const CTA = ({ placeBottom, closeButtonPosition, title, subtitle, buttonTitle, to }) => {
  const [showCta, setShowCta] = useState(false);
  const [isClosed, setIsClosed] = useState(true);
  const [isVisibleSnippet, setIsVisibleSnippet] = useState(false);

  const changeVisibleBreakpoint = (isVisible) => {
    setIsVisibleSnippet(!isVisible);
  };

  const moveZendeskWidget = (offset) => {
    const zendeskWidget = get(window, '$zopim.livechat.button');

    if (!zendeskWidget) {
      setTimeout(() => {
        moveZendeskWidget(offset);
      }, 5000);
    } else {
      zendeskWidget.setOffsetVertical(offset);
    }
  };

  useEffect(() => {
    if (!isClosed) setShowCta(isVisibleSnippet);

    if (placeBottom && isVisibleSnippet) {
      moveZendeskWidget(104);
    }
  }, [isVisibleSnippet]);

  useEffect(() => {
    if (placeBottom && isClosed) {
      moveZendeskWidget(0);
    }
  }, [isClosed]);

  useEffect(() => {
    setIsClosed(false);
  }, []);

  const handleClose = () => {
    setShowCta(false);
    setIsClosed(true);
  };

  if (isClosed) return null;

  return (
    <>
      <VisibilitySensor onChange={changeVisibleBreakpoint}>
        <div style={{ opacity: '0.0001', height: 1 }}>breakPoint</div>
      </VisibilitySensor>
      <div
        className={classNames('cta', {
          show: showCta,
          bottom: placeBottom,
          [closeButtonPosition]: !!closeButtonPosition,
        })}
      >
        <div id="cta-close-button">
          <Icon name="cross" onClick={handleClose} />
        </div>
        <div className="content-wrapper">
          <div className="cta-copy">
            <span className="cta-copy-title">{title}</span>
            {subtitle && <span className="cta-copy-subtitle">{subtitle}</span>}
          </div>
          <Button to={to} fill={!isMobileOnly} color="violet" className="center">
            {buttonTitle}
          </Button>
        </div>
      </div>
    </>
  );
};

CTA.propTypes = {
  placeBottom: PropTypes.bool,
  closeButtonPosition: PropTypes.oneOf(['left', 'right']),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  buttonTitle: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

CTA.defaultProps = { placeBottom: false, closeButtonPosition: 'right' };

export default CTA;
